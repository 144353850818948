import React, { Component } from "react";
import turbina from "../../../img/turb.png";

export class Bateria extends Component {
  render() {
    const { data, icon, tipo } = this.props;
    var bateria = parseFloat(data.replace(",", "."));

    var clase;
    // 13 = 100%
    // 10.5 = 5%
    // 10.5 rojo 11.5 medio

    // 2.8 de diferencia = 100 aprox, porcentaje es 100-diferencia

    var diferencia = 13 - bateria;
    var relleno = 100 - (diferencia * 100) / 2.8;
    if (relleno > 100) {
      relleno = 100;
    }
    if (bateria === 0 || relleno <= 0) {
      relleno = 0;
    }

    var niveles = "0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95"
      .split(", ")
      .reverse();

    for (var i = 0; i < niveles.length; i++) {
      if (relleno >= parseInt(niveles[i])) {
        clase = niveles[i];
        break;
      }
    }

    return (
      <div
        className="bateria"
        style={{ textAlign: icon === "fas fa-plug" ? "center" : null }}
        title={tipo}
      >
        <div className={`bat color-${clase}`}>
          <div className="relleno" style={{ height: `${relleno}%` }} />
          {tipo !== "Turbina" ? <i className={icon} /> : null}
          {tipo === "Turbina" ? (
            <img className="turbImg" src={turbina} />
          ) : null}
          <div className="lines">
            <span />
            <span />
          </div>
        </div>
        {icon !== "fas fa-plug" ? (
          <div className="voltaje">{data} V</div>
        ) : null}
      </div>
    );
  }
}

export default Bateria;
