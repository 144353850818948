import React, { Component } from "react";
import axios from "axios";
import "../../css/detalle.css";
import { connect } from "react-redux";
import { getListado, changeStore } from "../../actions/actionsListado";
import Load from "../Load";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import caudalimetro from "../../img/caudalimetro.png";
import error from "../../img/error.png";
import punto_medio from "../../img/punto_medio.png";
import reguladora from "../../img/reguladora.png";
import tanque from "../../img/tanque.png";
import bomba from "../../img/bomba-agua-icon.png";
import def from "../../img/blue-dot.png";
import box from "../../img/box.png";
import { formatDate, formatNumber } from "../Funciones";

export class DetalleEquipo extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.bounds = null;
    this.marcador = null;
    this.infowindow = null;
    this.timeoutDatos = null;

    this.state = {
      datos: {},
      charged: false,
      sectores: {},
      sectoresPertenecientes: []
    };
  }

  setData() {
    var pkmonitoreo = this.props.match.params.id;
    const { region } = this.props;

    axios
      .get(region)
      .then(res => {
        if (res.data.equipos[pkmonitoreo]) {
          // reviso a que sectores pertenece el equipo
          var sectores = res.data.sectores;
          var keys = Object.keys(sectores);
          var sectoresPertenecientes = [];
          keys.map((key, index) => {
            sectores[key].equipos.forEach(equipo => {
              if (equipo.pkmonitoreo === pkmonitoreo) {
                var tipo = "";
                var map_ico = "";
                sectores[key].equipos.forEach(eqp => {
                  if (eqp.pkmonitoreo === pkmonitoreo) {
                    tipo = eqp.tipo;
                    map_ico = eqp.map_ico;
                  }
                });
                var sector = false;
                sectoresPertenecientes.forEach((item) => {
                  if (item.pkmonitoreo === pkmonitoreo) {
                    sector = true;
                  }

                })
                if (!sector) {
                  sectoresPertenecientes.push({
                    nombre: sectores[key].nombre,
                    pkmonitoreo,
                    tipo,
                    index,
                    map_ico
                  });

                }
              }
            });
          });
          // guardo los datos en el state
          this.setState(
            {
              datos: res.data.equipos[pkmonitoreo],
              charged: true,
              sectores,
              sectoresPertenecientes
            },
            () => {
              this.chargeMap();
              this.timeoutDatos = setTimeout(() => {
                this.setData();
              }, 1000 * 60);
            }
          );
        } else {
          this.props.history.push("/");
        }
      })
      .catch(err => {
        this.props.history.push("/");
      });
  }

  componentDidMount() {
    this.setData();
    this.props.getListado(this.props.region);
  }

  chargeMap() {
    if (!window.google) {
      this.timeoutMapa = setTimeout(() => {
        this.chargeMap();
      }, 300);
      return null;
    }

    // creo el mapa
    this.map = new window.google.maps.Map(document.getElementById("mapcont"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      maxZoom: 18
    });

    // elimino el marcador que pudieran existir e inicializo el centrado
    this.bounds = new window.google.maps.LatLngBounds();
    if (this.marcador) {
      this.marcador.setMap(null);
    }

    // inicializo las variables con los datos
    const { ubicacion, latitud, longitud, dlgid } = this.state.datos;
    const { map_ico } = this.state.sectoresPertenecientes[0];
    this.marcador = null;

    // agrego el marcador
    var img;
    switch (map_ico) {
      case "caudalimetro":
        img = caudalimetro;
        break;
      case "reguladora":
        img = reguladora;
        break;
      case "punto_medio":
        img = punto_medio;
        break;
      case "tanque":
        img = tanque;
        break;
      case "tanque_auto":
        img = tanque;
        break;
      case "bomba":
        img = bomba;
        break;
      case "cloro_auto":
        img = bomba;
        break;
      case "bomba_auto":
        img = bomba;
        break;
      case "error":
        img = error;
        break;
      default:
        img = def;
        break;
    }

    if (
      ubicacion.substring(0, 8) === "SPYMOVIL" ||
      ubicacion.substring(0, 3) === "OSE"
    ) {
      img = box;
    }

    this.marcador = new window.google.maps.Marker({
      position: { lat: latitud, lng: longitud },
      map: this.map,
      title: ubicacion,
      icon: img
    });

    // agrego el punto al centrado del mapa
    var loc = new window.google.maps.LatLng(
      this.marcador.position.lat(),
      this.marcador.position.lng()
    );
    this.bounds.extend(loc);

    // creo el cuadro
    const crearCuadro = () => {
      if (this.InfoWindow) {
        return null;
      }
      var contentString = `<div>
                <h4>${dlgid}</h4>
                <p>${ubicacion}</h4>
            </div>`;

      this.infowindow = new window.google.maps.InfoWindow({
        content: contentString
      });
      this.infowindow.open(this.map, this.marcador);
      this.map.panTo({ lat: latitud, lng: longitud });
      this.map.setZoom(17);
    };

    crearCuadro();

    var bindearCuadro = crearCuadro.bind(this);

    this.infowindow.addListener("closeclick", () => {
      this.infowindow = null;
      this.marcador.addListener("click", bindearCuadro);
    });

    //centro el mapa
    this.map.fitBounds(this.bounds);
    this.map.panToBounds(this.bounds);
  }

  componentWillUnmount() {
    if (this.timeoutMapa) {
      clearTimeout(this.timeoutMapa);
      this.timeoutMapa = null;
    }
    if (this.timeoutDatos) {
      clearTimeout(this.timeoutDatos);
      this.timeoutDatos = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setData();
    }
  }

  irAlListado(i) {
    const { equipos, sectores } = this.props;

    if (equipos.length > 0) {
      var coincidencias = [];
      equipos.forEach(equipo => {
        if (sectores[i].indexOf(equipo.id) !== -1) {
          coincidencias.push(equipo);
        }
      });
      this.props.changeStore({ coincidencias, filtro: i });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    const { datos, charged, sectoresPertenecientes } = this.state;

    if (!charged) {
      return <Load />;
    }

    const informacion = () => {
      return (
        <table>
          <thead>
            <tr>
              <th colSpan="2">Información del equipo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ubicación</td>
              <td>{datos.ubicacion}</td>
            </tr>
            <tr>
              <td>Latitud</td>
              <td>{datos.latitud}</td>
            </tr>
            <tr>
              <td>Longitud</td>
              <td>{datos.longitud}</td>
            </tr>

            <tr>
              <td>DLGID</td>
              <td>{datos.dlgid}</td>
            </tr>
          </tbody>
        </table>
      );
    };

    const datosEquipo = () => {
      var {
        q0,
        CAUDAL_ACUMULADO,
        altura,
        altura_max,
        pA,
        pB,
        pZA,
        pZB,
        porcentaje,
        q0_state,
        altura_state,
        pA_state,
        pB_state,
        bt,
        // Cloro
        CL_MAX_ALARM,
        CL_MIN_ALARM,
        CL_ALARM_CONTROL,
        CL_ALARM_STATE,
        CL_LIBRE,
        // TanqueA
        H_MAX_TQ,
        H_TQ,
        // bombaA
        P_PRESSURE,
        FALLA_ELECTRICA,
        FALLA_TERMICA,
        GABINETE_ABIERTO,
        CAUDAL_IMP,
        TX_ERROR,
        // Perforaciones
        CAUDAL,
        CAUDAL_state,
        PRESION_ALTA,
        PRESION_ALTA_state,
        tx_state,
        PUMP_PERF_STATE,
        PUMP_PERF_STATE_state,
        DEPOSITO_DE_CLORO,
        DEPOSITO_DE_CLORO_state
      } = datos;
     
      if (CL_LIBRE !== undefined) {
        CL_LIBRE = parseFloat(CL_LIBRE);
      }
      if (CL_MAX_ALARM !== undefined) {
        CL_MAX_ALARM = parseFloat(CL_MAX_ALARM);
      }
      if (CL_MIN_ALARM !== undefined) {
        CL_MIN_ALARM = parseFloat(CL_MIN_ALARM);
      }

      var CL_ALARM = "OK";
      if (CL_LIBRE < CL_MIN_ALARM) {
        CL_ALARM = "WARNING";
      }
      if (CL_LIBRE > CL_MAX_ALARM) {
        CL_ALARM = "WARNING";
      }


      if (P_PRESSURE !== undefined) {
        P_PRESSURE = parseFloat(P_PRESSURE)
      }
      if (CAUDAL_IMP !== undefined) {
        CAUDAL_IMP = parseFloat(CAUDAL_IMP)
      }

      if (H_MAX_TQ !== undefined) {
        H_MAX_TQ = parseFloat(H_MAX_TQ)
      }
      if (H_TQ !== undefined) {
        H_TQ = parseFloat(H_TQ)
      }
      if (H_MAX_TQ !== undefined && H_TQ !== undefined) {
        var porcentaje = (H_TQ / H_MAX_TQ) * 100;
      }

      if (CL_ALARM_STATE === "SI") {
        CL_ALARM_STATE = "ERROR";
      } else if (CL_ALARM_STATE === "NO") {
        CL_ALARM_STATE = "OK";
      }


      // formateo la fecha
      var fechadata = formatDate(datos.fechadata);



      return (
        <table className="datos">
          <thead>
            <tr>
              <th colSpan="2">Datos del equipo</th>
            </tr>
          </thead>
          <tbody>
            {tx_state !== undefined && tx_state !== "OK" ? (
              <tr className={tx_state}>
                <th colSpan="2">Error de transmisión</th>

              </tr>
            ) : null}

            {/* Perforaciones Con Auto */}

            {FALLA_ELECTRICA !== undefined && FALLA_ELECTRICA !== "NO" ? (
              <tr className="OK">
                <td>Falla eléctrica</td>
                <td>
                  {FALLA_ELECTRICA}
                </td>
              </tr>
            ) : null}

            {FALLA_TERMICA !== undefined && FALLA_TERMICA !== "NO" ? (
              <tr className="OK">
                <td>Falla eléctrica</td>
                <td>
                  {FALLA_TERMICA}
                </td>
              </tr>
            ) : null}

            {GABINETE_ABIERTO !== undefined && GABINETE_ABIERTO !== "NO" ? (
              <tr className="OK">
                <td>Gabinete abierto</td>
                <td>
                  {GABINETE_ABIERTO}
                </td>
              </tr>
            ) : null}

            {CAUDAL_IMP !== undefined && CAUDAL_IMP !== -1 ? (
              <tr className="OK">
                <td>Caudal</td>
                <td>
                  {formatNumber.new(CAUDAL_IMP.toFixed(2))}
                  <b>
                    {" "}
                    mt<sup>3</sup>h
                  </b>
                </td>
              </tr>
            ) : null}

            {P_PRESSURE !== undefined && P_PRESSURE !== -1 ? (
              <tr className="OK">
                <td>Presión</td>
                <td>
                  {formatNumber.new(P_PRESSURE.toFixed(2))}
                  <b>
                    {" "}
                    bar
                  </b>
                </td>
              </tr>
            ) : null}


            {/* Cloro */}
            {CL_LIBRE !== undefined && CL_LIBRE !== -1 ? (
              <tr className={CL_ALARM}>
                <td>Cloro</td>
                <td>
                  {formatNumber.new(CL_LIBRE.toFixed(2))}
                  <b>
                    {" "}
                    ppm
                  </b>
                </td>
              </tr>
            ) : null}

            {CL_ALARM_CONTROL !== undefined && CL_ALARM_CONTROL !== -1 ? (
              <tr className="OK">
                <td>Control de alarma</td>
                <td>
                  {CL_ALARM_CONTROL === "NO"
                    ? "Desactivado"
                    : "Activo"
                  }
                </td>
              </tr>
            ) : null}
            {/* Perforaciones */}

            {CAUDAL !== undefined && CAUDAL !== -1 ? (
              <tr className={CAUDAL_state}>
                <td>Caudal</td>
                <td>
                  {formatNumber.new(CAUDAL.toFixed(2))}
                  <b>
                    {" "}
                    mt<sup>3</sup>h
                  </b>
                </td>
              </tr>
            ) : null}

            {DEPOSITO_DE_CLORO !== undefined && DEPOSITO_DE_CLORO !== -1 ? (
              <tr className={DEPOSITO_DE_CLORO_state}>
                <td>Depósito de cloro</td>
                <td>
                  {formatNumber.new(DEPOSITO_DE_CLORO)}
                  <b>
                    {" "}
                    %
                  </b>
                </td>
              </tr>
            ) : null}

            {PRESION_ALTA !== undefined && PRESION_ALTA !== -1 ? (
              <tr className={PRESION_ALTA_state}>
                <td>Presión</td>
                <td>

                  {formatNumber.new(PRESION_ALTA.toFixed(2))}
                  <b>
                    {" "}
                    bar
                  </b>
                </td>
              </tr>
            ) : null}

            {/* TanqueA */}

            {H_TQ !== undefined && H_TQ !== -1 ? (
              <tr className="OK">
                <td>Altura</td>
                <td>
                  {formatNumber.new(H_TQ.toFixed(2))}
                  <b> m</b>
                </td>
              </tr>
            ) : null}
            {H_MAX_TQ !== undefined && H_MAX_TQ !== -1 ? (
              <tr>
                <td>Altura máxima</td>
                <td>
                  {formatNumber.new(H_MAX_TQ.toFixed(2))}
                  <b> m</b>
                </td>
              </tr>
            ) : null}

            {/* Perforaciones */}

            {q0 !== undefined && q0 !== -1 ? (
              <tr className={q0_state}>
                <td>Caudal</td>
                <td>
                  {formatNumber.new(q0.toFixed(2))}
                  <b>
                    {" "}
                    mt<sup>3</sup>h
                  </b>
                </td>
              </tr>
            ) : null}
            {CAUDAL_ACUMULADO !== undefined && CAUDAL_ACUMULADO !== -1 ? (
              <tr>
                <td>Acumulado</td>
                <td>
                  {formatNumber.new(CAUDAL_ACUMULADO.toFixed(2))}
                  <b>
                    {" "}
                    m<sup>3</sup>
                  </b>
                </td>
              </tr>
            ) : null}
            {altura !== undefined && altura !== -1 ? (
              <tr className={altura_state}>
                <td>Altura</td>
                <td>
                  {formatNumber.new(altura.toFixed(2))}
                  <b> m</b>
                </td>
              </tr>
            ) : null}
            {altura_max !== undefined && altura_max !== -1 ? (
              <tr>
                <td>Altura máxima</td>
                <td>
                  {formatNumber.new(altura_max.toFixed(2))}
                  <b> m</b>
                </td>
              </tr>
            ) : null}
            {porcentaje !== undefined && porcentaje !== -1 ? (
              <tr>
                <td>Llenado</td>
                <td>
                  {formatNumber.new(porcentaje.toFixed(2))}
                  <b> %</b>
                </td>
              </tr>
            ) : null}
            {pA !== undefined && pA !== -1 ? (
              <tr className={pA_state}>
                <td>pA</td>
                <td>
                  {formatNumber.new(pA.toFixed(2))}
                  <b> mca</b>
                </td>
              </tr>
            ) : null}
            {pB !== undefined && pB !== -1 ? (
              <tr className={pB_state}>
                <td>pB</td>
                <td>
                  {formatNumber.new(pB.toFixed(2))}
                  <b> mca</b>
                </td>
              </tr>
            ) : null}
            {pZA !== undefined && pZA !== -1 ? (
              <tr>
                <td>pZA</td>
                <td>
                  {formatNumber.new(pZA.toFixed(2))}
                  <b> mca</b>
                </td>
              </tr>
            ) : null}
            {pZB !== undefined && pZB !== -1 ? (
              <tr>
                <td>pZB</td>
                <td>
                  {formatNumber.new(pZB.toFixed(2))}
                  <b> mca</b>
                </td>
              </tr>
            ) : null}
            {bt !== undefined && bt !== -1 ? (
              <tr>
                <td>Bateria</td>
                <td>
                  {formatNumber.new(bt.toFixed(2))}
                  <b> v</b>
                </td>
              </tr>
            ) : null}

            <tr>
              <td>Fecha</td>
              <td>{fechadata}</td>
            </tr>

            <tr className="cabecera">
              <td>Sector</td>
              <td>Tipo</td>
            </tr>
            {sectoresPertenecientes.map((sector, key) => {
              var tipo = sector.tipo.toLowerCase();

              return (
                <tr key={key}>
                  <td>
                    <Link
                      to="/online"
                      onClick={this.irAlListado.bind(this, sector.index)}
                    >
                      {sector.nombre}
                    </Link>
                  </td>
                  <td>{tipo.charAt(0).toUpperCase() + tipo.slice(1)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };

    return (
      <div className="detalleContainer">
        <div className="detalleEquipo">
          <div className="container">
            <h2>{datos.dlgid}</h2>
            <div className="contenido">
              <div className="row">
                <div className="col-lg-6">
                  <div id="mapcont" />
                </div>
                <div className="col-lg-6">{informacion()}</div>
                <div className="col-lg-6">{datosEquipo()}</div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  equipos: state.listado.equipos,
  sectores: state.listado.sectores
});
export default connect(
  mapStateToProps,
  { getListado, changeStore }
)(DetalleEquipo);
