import React, { Component } from "react";

export class Load extends Component {
  render() {
    const error = () => {
      return (
        <div className="error inicial">
          <h2>Hubo un error al conectarse al servidor</h2>
          <p>Reintentando...</p>
        </div>
      );
    };
    return (
      <div className="load">
        {this.props.error ? (
          error()
        ) : (
          <div className="carga">
            <div className="loadAnim" />
            <h4>Cargando...</h4>
          </div>
        )}
      </div>
    );
  }
}

export default Load;
