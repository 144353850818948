import React, { Component } from "react";
import "../css/impresion.css";
import { formatNumber, formatDate } from "./Funciones";

export class TablaImpresion extends Component {
  componentDidMount() {
    if (!window.columns || !window.coincidencias) {
      window.close();
    }
    if (document.querySelector(".navbar")) {
      document.querySelector(".navbar").style.display = "none";
    }

    document.title = "Información de equipos";

    window.print();
  }

  cerrar() {
    window.close();
  }

  render() {
    const { columns, coincidencias } = window;

    if (!columns || !coincidencias) {
      return null;
    }

    const cabecera = () => {
      return (
        <tr>
          {columns.dlgid ? <th>Dlgid</th> : null}
          {columns.ubicacion ? <th>Ubicacion</th> : null}
          {columns.q0 ? <th>Caudal</th> : null}
          {columns.CAUDAL_ACUMULADO ? <th>Acumulado</th> : null}
          {columns.cota ? <th>Cota</th> : null}
          {columns.pA ? <th>pA</th> : null}
          {columns.pZA ? <th>pZA</th> : null}
          {columns.pB ? <th>pB</th> : null}
          {columns.pZB ? <th>pZB</th> : null}
          {columns.altura ? <th>Altura</th> : null}
          {columns.porcentaje ? <th>Porcentaje</th> : null}
          {columns.bt ? <th>Batería</th> : null}
          {columns.sqe ? <th>Señal</th> : null}
          {columns.fechadata ? <th>Fecha</th> : null}
        </tr>
      );
    };

    return (
      <div className="impresion">
        <button onClick={this.cerrar.bind(this)}>Cerrar ventana</button>
        <table>
          <thead>{cabecera()}</thead>
          <tbody>
            {coincidencias.map((coincidencia, i) => {
              return (
                <tr key={i}>
                  {columns.dlgid ? <td>{coincidencia.dlgid}</td> : null}
                  {columns.ubicacion ? (
                    <td className="ubi">{coincidencia.ubicacion}</td>
                  ) : null}
                  {columns.q0 ? (
                    <td>
                      {coincidencia.q0 !== -1
                        ? formatNumber.new(coincidencia.q0.toFixed(2)) +
                          "  mt3/h"
                        : null}
                    </td>
                  ) : null}

                  {columns.CAUDAL_ACUMULADO ? (
                    <td>
                      {coincidencia.CAUDAL_ACUMULADO !== -1
                        ? formatNumber.new(
                            coincidencia.CAUDAL_ACUMULADO.toFixed(2)
                          )
                        : null}
                    </td>
                  ) : null}

                  {columns.cota ? (
                    <td>
                      {formatNumber.new(coincidencia.cota.toFixed(2)) + "  mts"}
                    </td>
                  ) : null}

                  {columns.pA ? (
                    <td>
                      {coincidencia.pA != -1
                        ? formatNumber.new(coincidencia.pA.toFixed(2)) + " mca"
                        : null}{" "}
                    </td>
                  ) : null}
                  {columns.pZA ? (
                    <td>
                      {coincidencia.pZA != -1
                        ? formatNumber.new(coincidencia.pZA.toFixed(2)) + " mca"
                        : null}{" "}
                    </td>
                  ) : null}
                  {columns.pB ? (
                    <td>
                      {coincidencia.pB != -1
                        ? formatNumber.new(
                            parseFloat(coincidencia.pB).toFixed(2)
                          ) + " mca"
                        : null}
                    </td>
                  ) : null}

                  {columns.pZB ? (
                    <td>
                      {coincidencia.pZB != -1
                        ? formatNumber.new(coincidencia.pZB.toFixed(2)) + " mca"
                        : null}
                    </td>
                  ) : null}

                  {columns.altura ? (
                    <td>
                      {coincidencia.altura != -1
                        ? formatNumber.new(coincidencia.altura.toFixed(2)) +
                          " mts"
                        : null}
                    </td>
                  ) : null}

                  {columns.porcentaje ? (
                    <td>
                      {coincidencia.porcentaje != -1
                        ? formatNumber.new(coincidencia.porcentaje.toFixed(2)) +
                          " %"
                        : null}
                    </td>
                  ) : null}

                  {columns.bt ? <td>{coincidencia.bt} v</td> : null}
                  {columns.sqe ? (
                    <td>{coincidencia.sqe !== -1 ? coincidencia.sqe : ""}</td>
                  ) : null}

                  {columns.fechadata ? (
                    <td>{formatDate(coincidencia.fechadata)}</td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TablaImpresion;
