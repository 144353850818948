import React, { Component } from "react";
import { formatNumber } from "../Funciones";

export class SectorDataElevada extends Component {
  render() {
    const { mensual } = this.props.datos;

    return (
      <div className="data-block">
        <div className="data-title">Agua elevada</div>
        <div className="data-container">
          <div className={"data-subcontainer full OK"}>
            <div className="data-name">Mensual:</div>
            <div className="data-content">
              <div className="data-value">
                {formatNumber.new(mensual.toFixed(2))}{" "}
                <div className="data-unit">
                  mt<sup>3</sup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectorDataElevada;
