export const formatNumber = {
  separador: ".", // separador para los miles
  sepDecimal: ",", // separador para los decimales
  formatear: function(num) {
    num += "";
    var splitStr = num.split(".");
    var splitLeft = splitStr[0];
    var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : "";
    var regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
      splitLeft = splitLeft.replace(regx, "$1" + this.separador + "$2");
    }
    return this.simbol + splitLeft + splitRight;
  },
  new: function(num, simbol) {
    this.simbol = simbol || "";
    return this.formatear(num);
  }
};

export const formatDate = fechadata => {
  if (typeof fechadata === "string") {
    var fechaFormateada = fechadata.split("T")[0].split("-");

    if (typeof fechaFormateada !== "string" && fechaFormateada.length > 1) {
      fechadata = `${fechaFormateada[2]}/${fechaFormateada[1]}/${
        fechaFormateada[0]
      } ${fechadata.split("T")[1]}`;
    }
  }
  return fechadata;
};

export const checkGrey = ubicacion => {
  var sinInstalar = false;
  var poseedor = "";
  if (!ubicacion) {
    return {
      sinInstalar,
      poseedor,
      ubicacion: ""
    };
  }

  var checkSpy = ubicacion.substring(0, 8);
  var checkOse = ubicacion.substring(0, 3);

  if (checkSpy === "SPYMOVIL") {
    sinInstalar = true;
    poseedor = "SPYMOVIL";
    ubicacion = "Sin ubicacion";
  }
  if (checkOse === "OSE") {
    sinInstalar = true;
    poseedor = "OSE";
    ubicacion = ubicacion.substring(3);
  }

  return {
    sinInstalar,
    poseedor,
    ubicacion
  };
};

export const formatedDate = () => {
  var date = new Date();

  var formateada = `${date.getDate()}/${date.getMonth() +
    1}/${date.getFullYear()} ${
    date.getHours() < 10 ? 0 : ""
  }${date.getHours()}:${date.getMinutes() < 10 ? 0 : ""}${date.getMinutes()}:${
    date.getSeconds() < 10 ? 0 : ""
  }${date.getSeconds()}`;

  return { formateada };
};

export const estilosmapa = () => {
  var estilosmapa = `
<Style id="icon-1899-0F9D58-normal">
  <IconStyle>
    <color>ff589d0f</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>0</scale>
  </LabelStyle>
</Style>
<Style id="icon-1899-0F9D58-highlight">
  <IconStyle>
    <color>ff589d0f</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>1</scale>
  </LabelStyle>
</Style>
<StyleMap id="icon-1899-0F9D58">
  <Pair>
    <key>normal</key>
    <styleUrl>#icon-1899-0F9D58-normal</styleUrl>
  </Pair>
  <Pair>
    <key>highlight</key>
    <styleUrl>#icon-1899-0F9D58-highlight</styleUrl>
  </Pair>
</StyleMap>
<Style id="icon-1899-424242-normal">
  <IconStyle>
    <color>ff424242</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>0</scale>
  </LabelStyle>
</Style>
<Style id="icon-1899-424242-highlight">
  <IconStyle>
    <color>ff424242</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>1</scale>
  </LabelStyle>
</Style>
<StyleMap id="icon-1899-424242">
  <Pair>
    <key>normal</key>
    <styleUrl>#icon-1899-424242-normal</styleUrl>
  </Pair>
  <Pair>
    <key>highlight</key>
    <styleUrl>#icon-1899-424242-highlight</styleUrl>
  </Pair>
</StyleMap>
<Style id="icon-1899-A52714-normal">
  <IconStyle>
    <color>ff1427a5</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>0</scale>
  </LabelStyle>
</Style>
<Style id="icon-1899-A52714-highlight">
  <IconStyle>
    <color>ff1427a5</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>1</scale>
  </LabelStyle>
</Style>
<StyleMap id="icon-1899-A52714">
  <Pair>
    <key>normal</key>
    <styleUrl>#icon-1899-A52714-normal</styleUrl>
  </Pair>
  <Pair>
    <key>highlight</key>
    <styleUrl>#icon-1899-A52714-highlight</styleUrl>
  </Pair>
</StyleMap>
<Style id="icon-1899-F57C00-normal">
  <IconStyle>
    <color>ff007cf5</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>0</scale>
  </LabelStyle>
</Style>
<Style id="icon-1899-F57C00-highlight">
  <IconStyle>
    <color>ff007cf5</color>
    <scale>1</scale>
    <Icon>
      <href>http://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png</href>
    </Icon>
    <hotSpot x="32" xunits="pixels" y="64" yunits="insetPixels"/>
  </IconStyle>
  <LabelStyle>
    <scale>1</scale>
  </LabelStyle>
</Style>
<StyleMap id="icon-1899-F57C00">
  <Pair>
    <key>normal</key>
    <styleUrl>#icon-1899-F57C00-normal</styleUrl>
  </Pair>
  <Pair>
    <key>highlight</key>
    <styleUrl>#icon-1899-F57C00-highlight</styleUrl>
  </Pair>
</StyleMap>`;
  return estilosmapa;
};
