import React, { Component } from "react";
import { Cell } from "fixed-data-table-2";

export class HeaderOrdenable extends Component {
  render() {
    const { column, title, sort } = this.props;
    return (
      <Cell
        className="tableHeader"
        onClick={this.props.cambiarOrden.bind(this, column)}
      >
        {title}{" "}
        {sort.column === column && sort.type === "ASC" ? (
          <i className="fas fa-arrow-up" />
        ) : null}
        {sort.column === column && sort.type === "DESC" ? (
          <i className="fas fa-arrow-down" />
        ) : null}
      </Cell>
    );
  }
}

export default HeaderOrdenable;
