import React, { Component } from "react";
import help1 from "../img/help1.png";
import help2 from "../img/help2.png";
import help3 from "../img/help3.png";
import help4 from "../img/help4.png";
import help5 from "../img/help5.png";
import help6 from "../img/help6.png";
import help7 from "../img/help7.png";
import help8 from "../img/help8.png";
import help9 from "../img/help9.png";

export class Ayuda extends Component {
  render() {
    return (
      <div className="ayuda">
        <div className="overlay" onClick={this.props.toggle.bind(this)} />
        <div className="contenedor" data-simplebar>
          <h2>Visualización de sectores</h2>
          <img src={help1} alt="Ayuda 1" />
          <p>
            Esta sección muestra el estado actual de los sectores y sus equipos,
            alertando si hay medidas que representen un problema.
          </p>
          <div className="separador" />
          <img src={help2} alt="Ayuda 2" />
          <p>
            Clickeando en el nombre del sector, se puede seleccionar entre la
            lista de sectores disponibles para visualizar sus datos.
          </p>
          <img src={help3} alt="Ayuda 3" />
          <p>
            <b>1- </b>El mapa muestra los equipos del sector, con un ícono que
            representa su tipo, al clickear en ellos el mapa se amplía para ver
            mejor su ubicación.
          </p>
          <p>
            <b>2- </b>Clickeando en los botones "Descargar KML" o "Descargar
            GPX", se inicia la descarga de un archivo que contiene la
            informacion del mapa que se visualiza actualmente en el formato
            seleccionado.
          </p>
          <p>
            <b>3- </b>El botón "Reestablecer" Vuelve a centrar el mapa a la
            posición por defecto en la que todos los puntos son visibles.
          </p>
          <div className="separador" />
          <img src={help4} alt="Ayuda 4" />
          <p>
            <b>1- </b>Al clickear en el ícono, el mapa se centra en la
            localización del equipo.
          </p>
          <p>
            <b>2- </b>Al clickear en el ícono, se abre la ventana de detalles
            del equipo.
          </p>
          <div className="separador" />
          <img src={help5} alt="Ayuda 5" />
          <p>
            <b>1- </b>Clickear en el botón despliega un campo de búsqueda, que
            filtra los equipos del sector actual por su ubicación o ID.
          </p>
          <p>
            <b>2- </b>Estos íconos permiten cambiar de sector e iniciar o pausar
            la reproducción automática, en la que se va recorriendo los
            distintos sectores.
          </p>
          <p>
            <b>3- </b>Este campo permite elegir el tiempo que dura en pantalla
            cada pagina de equipos antes de pasar a la siguiente, durante la
            reproducción automática.
          </p>
          <div className="separador" />
          <h2>Listado de equipos</h2>
          <img src={help6} alt="Ayuda 6" />
          <p>
            El listado de equipos permite visualizar todos los datos de cada
            equipo, filtrandolos y ordenandolos como el usuario necesite.
          </p>
          <p>Para filtrar, se clickea en el botón "Filtrar datos". </p>
          <p>
            Se puede seleccionar cuales columnas estarán visibles y tambien
            filtrar por nombre, tipo, estado y sector.
          </p>
          <div className="separador" />
          <img src={help7} alt="Ayuda 7" />
          <p>
            La tabla permite ordenarse por cualquiera de sus columnas de forma
            ascendente o descendente
          </p>
          <p>
            <b>1- </b> Para imprimír la tabla solo hay que clickear en el botón
            "Imprimir tabla".
          </p>
          <p>
            <b>2- </b>A continuación se abrirá una ventana con la tabla lista
            para imprimir, solo hace falta clickear en el botón "Imprimir".
          </p>
          <div className="separador" />
          <h2>Configuración de sectores</h2>
          <img src={help8} alt="Ayuda 8" />
          <p>
            <b>1- </b> Para configurar los sectores, se debe clickear en una de
            las opciones, a continuación se abre la ventana de configuración.
          </p>
          <p>
            <b>2- </b>Se debe rellenar los datos que corresponda y darle click
            al botón "Confirmar" o "Guardar cambios".
          </p>
          <p>
            <b>4- </b>Luego de confirmar saldrá una notificación avisando que
            los cambios fueron correctamente guardados.
          </p>
          <img src={help9} alt="Ayuda 9" />
          <p>
            A la hora de editar los sectores, tenemos algunas opciones
            distintas.
          </p>
          <p>
            <b>1- </b>El botón "Eliminar sector" permite eliminar el sector
            actual.
          </p>
          <p>
            <b>2- </b>El botón "Restaurar configuración" vuelve a cargar la
            ultima configuración guardada en la base de datos en caso de que se
            haya cometido algún error.{" "}
          </p>{" "}
          <p>
            Se puede ver el progreso en la barra de abajo que indica que se
            están cargando los datos.
          </p>
        </div>
      </div>
    );
  }
}

export default Ayuda;
