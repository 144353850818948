import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStore } from "../../actions/sectoresActions";
import { Link } from "react-router-dom";
import { formatNumber, formatDate, checkGrey } from "../Funciones";
import Sqe from "../visOnline/tabla/Sqe";
import Bateria from "../visOnline/tabla/Bateria";

export class Otro extends Component {
  mostrarPunto() {
    var id = Object.keys(this.props.data)[0];
    id = this.props.data[Object.keys(this.props.data)[0]].dlgid;
    this.props.changeStore({ currentPoint: id });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var pkmonitoreo = Object.keys(this.props.data)[0];
    var {
      q0,
      pA,
      pB,
      CAUDAL_ACUMULADO,
      macro,
      reguladora,
      pA_state,
      pB_state,
      q0_state,
      pZA,
      pZB,
      tx_state,
      dlgid,
      sqe,
      alimentacion,
      bt
    } = this.props.data[pkmonitoreo];

    var id = dlgid;
    // formatear fecha
    var fechadata = formatDate(this.props.data[pkmonitoreo].fechadata);
    if (fechadata === -1) {
      tx_state = 'ERROR'
      fechadata = '01/01/2000 00:00:00'

    }

    // Probando si la fecha da error 
    try {
      let fechaPrueba = fechadata.substring(0, 10)
      let fechaPrueba2 = fechadata.substring(10)
    } catch (error) {
      console.log("Error de fecha en:", dlgid)
    }
    // chequear si es de ose o spymovil
    const { sinInstalar, poseedor, ubicacion } = checkGrey(
      this.props.data[pkmonitoreo].ubicacion
    );

    // datos bateria
    var icon, tipo;
    switch (alimentacion) {
      case "TURBINA":
        icon = "turbina";
        tipo = "Turbina";
        break;
      case "CORRIENTE":
        icon = "fas fa-plug";
        tipo = "Corriente";
        break;
      case "SOLAR":
        icon = "fas fa-sun";
        tipo = "Solar";
        break;

      default:
        tipo = "Bateria";
        icon = "";
        break;
    }

    // retornar contenido
    if (tx_state === "ERROR") {
      return (
        <div
          className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${this.props.hidden ? "ocultar" : ""
            }`}
        >
          <div className={`data-sensor otro ${sinInstalar ? "grey" : null}`}>
            <div className="header">
              <div className="header-text">
                <div className="header-id">
                  {id}{" "}
                  <div className="botones">
                    <button
                      onClick={this.mostrarPunto.bind(this)}
                      title="Ver en el mapa"
                    >
                      <i className="fas fa-map-marked-alt" />
                    </button>
                    <Link
                      to={"/detalle/" + pkmonitoreo}
                      target="_blank"
                      title="Detalles"
                    >
                      <i className="fas fa-info" />
                    </Link>
                  </div>
                </div>
                {sinInstalar ? (
                  <div className="header-address">
                    <div
                      style={{
                        marginBottom: ".2em"
                      }}
                    >
                      {ubicacion}
                    </div>
                    <div
                      style={{
                        background: "rgb(48, 48, 48)",
                        border: "2px solid white",
                        padding: ".3em ",
                        width: "100%",
                        fontSize: "1.4em",
                        textAlign: "center"
                      }}
                    >
                      Sin instalar
                    </div>
                  </div>
                ) : (
                    <div className="header-address">{ubicacion}</div>
                  )}
                {sinInstalar ? (
                  <div
                    className="header-type "
                    style={{
                      fontSize: "1.5em",
                      display: "inline-block",
                      width: "100%"
                    }}
                  >
                    {poseedor}
                  </div>
                ) : (
                    <div className="header-type ">
                      Otro <i className="fas fa-random" />
                    </div>
                  )}
              </div>
            </div>
            <div
              className="content"
              style={{ display: sinInstalar ? "none" : "block" }}
            >
              {/* presion */}
              <div className={`content-data full ${tx_state}`}>
                <div className="data-section">
                  <div className="data-value">
                    <h3>Error</h3>
                  </div>
                </div>
              </div>

              <div className={`content-data OK`}>
                <div className="data-section">
                  <Sqe data={sqe} />{" "}
                  <Bateria
                    data={formatNumber.new(bt.toFixed(1))}
                    icon={icon}
                    tipo={tipo}
                  />
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${this.props.hidden ? "ocultar" : ""
          }`}
      >
        <div className={`data-sensor otro ${sinInstalar ? "grey" : null}`}>
          <div className="header">
            <div className="header-text">
              <div className="header-id">
                {id}{" "}
                <div className="botones">
                  <button
                    onClick={this.mostrarPunto.bind(this)}
                    title="Ver en el mapa"
                  >
                    <i className="fas fa-map-marked-alt" />
                  </button>
                  <Link
                    to={"/detalle/" + pkmonitoreo}
                    target="_blank"
                    title="Detalles"
                  >
                    <i className="fas fa-info" />
                  </Link>
                </div>
              </div>
              {sinInstalar ? (
                <div className="header-address">
                  <div
                    style={{
                      marginBottom: ".2em"
                    }}
                  >
                    {ubicacion}
                  </div>
                  <div
                    style={{
                      background: "rgb(48, 48, 48)",
                      border: "2px solid white",
                      padding: ".3em ",
                      width: "100%",
                      fontSize: "1.4em",
                      textAlign: "center"
                    }}
                  >
                    Sin instalar
                  </div>
                </div>
              ) : (
                  <div className="header-address">{ubicacion}</div>
                )}
              {sinInstalar ? (
                <div
                  className="header-type "
                  style={{
                    fontSize: "1.5em",
                    display: "inline-block",
                    width: "100%"
                  }}
                >
                  {poseedor}
                </div>
              ) : (
                  <div className="header-type ">
                    Otro <i className="fas fa-random" />
                  </div>
                )}
            </div>
          </div>
          <div
            className="content"
            style={{ display: sinInstalar ? "none" : "block" }}
          >
            {/* presion */}
            <div className={`content-data ${pA_state}`}>
              <div className="data-section">
                <div className="data-name">pA</div>
                <div className="data-value">
                  {formatNumber.new(pA.toFixed(2))}{" "}
                  <div className="data-unit">mca</div>
                </div>
              </div>
              <div className="data-section">
                <div className="data-name">pZA</div>
                <div className="data-value">
                  {pA_state === "ERROR"
                    ? "-"
                    : formatNumber.new(pZA.toFixed(2))}
                  <div className="data-unit">mca</div>
                </div>
              </div>
            </div>
            {/* presion salida*/}
            {reguladora === 1 ? (
              <div className={`content-data ${pB_state}`}>
                <div className="data-section">
                  <div className="data-name">pB</div>
                  <div className="data-value">
                    {formatNumber.new(pB.toFixed(2))}{" "}
                    <div className="data-unit">mca</div>
                  </div>
                </div>
                <div className="data-section">
                  <div className="data-name">pZB</div>
                  <div className="data-value">
                    {pB === 0 || pB_state === "ERROR"
                      ? "-"
                      : formatNumber.new(pZB.toFixed(2))}
                    <div className="data-unit">mca</div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* caudal */}
            {macro === 1 ? (
              <div className={`content-data ${q0_state}`}>
                <div className="data-section">
                  <div className="data-name">Caudal (Ins)</div>
                  <div className="data-value">
                    {formatNumber.new(q0)}{" "}
                    <div className="data-unit">
                      mt<sup>3</sup>h
                    </div>
                  </div>
                </div>
                <div className="data-section">
                  <div className="data-name">Acum. (Mes)</div>
                  <div className="data-value">
                    {formatNumber.new(Math.round(CAUDAL_ACUMULADO))}
                    <div className="data-unit">
                      m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={`content-data OK`}>
              <div className="data-section">
                <Sqe data={sqe} />{" "}
                <Bateria
                  data={formatNumber.new(bt.toFixed(1))}
                  icon={icon}
                  tipo={tipo}
                />
              </div>
              <div className="data-section">
                <div className="data-value">
                  <span className="fecha">
                    {fechadata.substring(0, 10)}
                    <br />
                    {fechadata.substring(10)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { changeStore }
)(Otro);
