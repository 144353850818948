import React, { Component } from "react";
import HeadContainer from "./header/HeadContainer";
import EquipoContainer from "./sectores/EquipoContainer";
import { connect } from "react-redux";
import { getSectores, changeStore } from "../actions/sectoresActions";
import Load from "./Load";

export class VisualizarSectores extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }

  ////////////////////////////////////////////////
  //Realizo la peticion al servidor cada x tiempo
  //reviso si me pude conectar al servidor antes de mostrar los elementos
  //en pantalla, si no pude muestro una pantalla de carga o error
  ///////////////////////////////////////////////

  componentDidMount() {
    this.actualizar();
    console.log('start actualizar');
  }

  /////////////////////////
  //actualizar()
  ////////////////////////
  // hago la peticion al servidor, y vuelvo a llamar a la misma funcion
  // luego de determinado tiempo

  actualizar() {
    console.log('run actualizar');
    this.props.getSectores(this.props.region);
    this.timer = setTimeout(
      () => {
        this.actualizar();
      },
      this.props.errors ? 1000 * 5 : 1000 * 60 * 3
    );
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.props.changeStore({
      timer: null,
      recorriendo: false
    });
  }

  render() {
    const { lastRegion, connected, errors, region } = this.props;

    if (region === lastRegion && connected) {
      return (
        <div>
          <HeadContainer />
          <EquipoContainer />
        </div>
      );
    }
    return <Load error={errors} />;
  }
}

const mapStateToProps = state => ({
  errors: state.sector.errors,
  connected: state.sector.connected,
  lastRegion: state.sector.lastRegion
});

export default connect(
  mapStateToProps,
  { getSectores, changeStore }
)(VisualizarSectores);
