import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getNextSector,
  getPrevSector,
  changeStore
} from "../../actions/sectoresActions";

export class Controls extends Component {
  state = {
    tiempo: 7,
    busqueda: "",
    visible: false
  };

  /////////////////////////////////////////////
  //muestro u oculto la barra de busqueda
  /////////////////////////////////////////////

  changeTimeXpage(e) {
    var tiempo = e.target.value;

    // controlar que el tiempo este entre 3 y 60 segundos
    if (tiempo > 60) {
      this.props.changeStore({ timeXpage: 60 });
      tiempo = 60;
    } else if (tiempo < 3) {
      this.props.changeStore({ timeXpage: 3 });
      tiempo = 3;
    } else {
      this.props.changeStore({ timeXpage: tiempo });
    }
    this.setState({
      tiempo: tiempo
    });
  }

  // creo un listener por si el usuario redimensiona la pantalla
  componentDidMount() {
    this.updateDim = this.updateDimensions.bind(this);
    window.addEventListener("resize", this.updateDim);
  }
  componentWillUnmount() {
    // elimino el listener si desmonto el componente
    window.removeEventListener("resize", this.updateDim);
  }
  // en caso de que la redimensione a menos de 600 px mientras
  // este reproduciendo los equipos, detengo el desplazamiento
  updateDimensions() {
    if (this.props.recorriendo && window.innerWidth < 600) {
      this.props.pausar();
    }
  }

  handleInput(e) {
    // cambio el dato de acuerdo al name y el value del input
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  toggle(variable) {
    // cambio el foco y muestro el input al hacer toggle del mismo
    if (
      !this.state[variable] &&
      document.getElementById("busqueda") &&
      variable == "visible"
    ) {
      document.getElementById("busqueda").focus();
    } else if (
      this.state[variable] &&
      document.getElementById("busqueda") &&
      variable == "visible"
    ) {
      document.getElementById("busqueda").blur();
    }

    this.setState({
      [variable]: !this.state[variable]
    });
  }

  filtrar() {
    const { equipos, currentSector, tipo, sectores } = this.props;
    var busqueda = this.state.busqueda.toLowerCase();
    var equiposDelSector = [...equipos[currentSector]];
    var tiposDelSector = [...tipo[currentSector]];

    // creo las variables vacias para rellenar con las coincidencias
    var coincidencias = [];
    var coincidenciaTipo = [];
    var coincidenciaIcono = [];

    // si el input estaba cerrado entonces lo muestro, sino sigo filtrando
    if (!this.state.visible) {
      this.toggle("visible");
      return null;
    }

    // filtro los puntos que tengan un id o ubicacion similar a la busqueda
    equiposDelSector.forEach((equipo, i) => {
      let key = Object.keys(equipo)[0];
      if (
        equipo[key].dlgid.toLowerCase().indexOf(busqueda) !== -1 ||
        equipo[key].ubicacion.toLowerCase().indexOf(busqueda) !== -1
      ) {
        coincidencias.push(equipo);
        coincidenciaTipo.push(tiposDelSector[i]);
        coincidenciaIcono.push(
          sectores[Object.keys(sectores)[currentSector]].equipos[i].map_ico
        );
      }
    });

    // pongo las coincidencias en el store
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.props.changeStore({ coincidencias, busqueda, coincidenciaTipo });
  }

  limpiarFiltro() {
    const { equipos, currentSector, tipo } = this.props;
    // reseteo el filtro
    this.props.changeStore({
      coincidencias: equipos[currentSector],
      busqueda: "",
      coincidenciaTipo: tipo[currentSector]
    });
    this.setState({
      busqueda: ""
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentDidUpdate(prevProps) {
    const { recorriendo, currentSector } = this.props;
    // si cambio de sector o empiezo a recorrer limpio el filtro
    if (
      (!prevProps.recorriendo && recorriendo) ||
      prevProps.currentSector !== currentSector
    ) {
      this.limpiarFiltro();
    }
  }

  keyDown(e) {
    // agrego eventos de teclado al input de busqueda
    if (e.key === "Enter" || e.which === "13" || e.keyCode === "13") {
      this.filtrar();
    }
    if (
      (e.key === "Escape" || e.which === "27" || e.keyCode === "27") &&
      this.state.visible
    ) {
      this.toggle("visible");
    }
  }

  render() {
    const { recorriendo } = this.props;
    const { busqueda, visible } = this.state;

    return (
      <div className="controls-container">
        <div
          className="buscador"
          style={{ display: recorriendo ? "none" : "" }}
        >
          <div className="buscador-container">
            <button
              onClick={this.limpiarFiltro.bind(this)}
              style={{
                display:
                  this.props.busqueda.length > 0 && visible ? " block" : "none"
              }}
            >
              Reestablecer
            </button>
            <i
              className="fas fa-times"
              style={{ display: !visible ? "none" : "" }}
              onClick={this.toggle.bind(this, "visible")}
            />
            <input
              type="text"
              onChange={this.handleInput.bind(this)}
              value={busqueda}
              name="busqueda"
              id="busqueda"
              placeholder="ID o Dirección"
              style={{
                width: visible ? "12.9em" : "",
                padding: visible ? "0.4em" : ""
              }}
              onKeyDown={this.keyDown.bind(this)}
            />
            <i
              className="fas fa-search"
              onClick={this.filtrar.bind(this)}
              style={{ borderLeft: !visible ? "none" : "" }}
            />
          </div>
        </div>
        <div className="reproductor">
          <div className="timer" style={{ display: recorriendo ? "none" : "" }}>
            <i className="far fa-clock" />
            <input
              type="number"
              min="3"
              max="60"
              value={this.state.tiempo}
              onChange={this.changeTimeXpage.bind(this)}
            />
            <span>S</span>
          </div>
          <div
            className="pausar"
            onClick={this.props.getPrevSector.bind(this)}
            style={{ display: recorriendo ? "none" : "" }}
          >
            <i className="fas fa-step-backward" />
          </div>
          <div
            className="pausar pausa"
            style={{
              display: recorriendo ? "inline-block" : "none"
            }}
            onClick={this.props.pausar.bind(this)}
          >
            <i className="fas fa-pause" />
          </div>

          <div
            className="pausar"
            style={{
              display: recorriendo ? "none" : "inline-block"
            }}
            onClick={this.props.reproducir.bind(this)}
          >
            <i className="fas fa-play" />
          </div>
          <div
            className="pausar"
            style={{ display: recorriendo ? "none" : "" }}
            onClick={this.props.getNextSector.bind(this)}
          >
            <i className="fas fa-step-forward" />
          </div>
        </div>
        <div
          className="controls"
          style={{
            transform: this.state.visible
              ? " translateX(0%)"
              : "translateX(calc(-100% - 2px))"
          }}
        />
      </div>
    );
  }
}

// guardo los datos del store en props
const mapStateToProps = state => ({
  recorriendo: state.sector.recorriendo,
  currentSector: state.sector.currentSector,
  equipos: state.sector.dataSectores,
  busqueda: state.sector.busqueda,
  tipo: state.sector.tipo,
  sectores: state.sector.sectores.sectores
});

export default connect(
  mapStateToProps,
  { getNextSector, getPrevSector, changeStore }
)(Controls);
