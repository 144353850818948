import React, { Component } from "react";
import "./App.css";
import "./css/main.css";
import "./css/bootstrap-grid.min.css";
import { Provider } from "react-redux";
import store from "./store";
import VisualizarSectores from "./components/VisualizarSectores";
import Navbar from "./components/Navbar";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import TableContainer from "./components/visOnline/TableContainer";
import Configuracion from "./components/configuracion/Configuracion";
import DetalleEquipo from "./components/detalle/DetalleEquipo";
import TablaImpresion from "./components/TablaImpresion";

class App extends Component {
  render() {
    console.log("Version: 2.0.3 (15/10/2020)");
    // reviso si estoy en la version actual o si necesito limpiar el store
    // por haber cambiado a una nueva
    var fechaVersion = "15/10/2020";
    var lastUpdate = window.localStorage.LastUpdate;

    if (lastUpdate) {
      var fechaUltima = lastUpdate.split(" ")[0];
      fechaUltima = fechaUltima.split("/");
      fechaUltima =
        fechaUltima[2] + "-" + fechaUltima[1] + "-" + fechaUltima[0];
      fechaVersion = fechaVersion.split("/");
      fechaVersion =
        fechaVersion[2] + "-" + fechaVersion[1] + "-" + fechaVersion[0];

      var fechas = [fechaVersion, fechaUltima];

      fechas.sort(function(a, b) {
        if (a > b) {
          return -1;
        }
        if (a < b) {
          localStorage.clear();
          window.location.reload();
          return 1;
        }
        localStorage.clear();
        window.location.reload();
        return 0;
      });
    }

    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Navbar />
            <Switch>
              <Route
                path="/"
                exact
                render={props => (
                  <VisualizarSectores {...props} region={this.props.region} />
                )}
              />
              <Route
                path="/configuracion"
                exact
                render={props => (
                  <Configuracion {...props} region={this.props.region} />
                )}
              />
              <Route
                path="/online"
                exact
                render={props => (
                  <TableContainer {...props} region={this.props.region} />
                )}
              />
              <Route path="/tablaImp" exact component={TablaImpresion} />
              <Route
                path="/detalle/:id"
                exact
                render={props => (
                  <DetalleEquipo {...props} region={this.props.region} />
                )}
              />
              <Route
                render={props => (
                  <VisualizarSectores {...props} region={this.props.region} />
                )}
              />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
