import {
  GET_SECTORES,
  GET_NEXT_SECTOR,
  ERROR_REQUEST,
  GET_PREV_SECTOR,
  CHANGE_STORE_TV
} from "./types";
import axios from "axios";

export const getSectores = region => dispatch => {
  // request al server para traer los datos
  // si falla activo la funcion de error

  var d = new Date();
  var n = d.getTime();
  axios
    .get(`${region}?var=${n}`)
    .then(res => {
      var respuesta = res.data;
      // var respuesta = json;
      var grupoSectores = [];
      var equipos = [];
      var equiposKeys = Object.keys(respuesta.equipos);

      // si no llega nada mando error
      if (
        !respuesta.equipos ||
        respuesta.equipos.length < 1 ||
        !respuesta.sectores ||
        respuesta.sectores.length < 1
      ) {
        dispatch({
          type: ERROR_REQUEST,
          payload: region
        });
        return null;
      }

      //ahora la variable equipos tiene todos los equipos guardados en un array
      equiposKeys.forEach((key, index) => {
        equipos.push({ [key]: respuesta.equipos[key] });
      });

      // ahora grupoSectores tiene un array con todos los datos de los sectores
      // siempre y cuando tengan equipos y datos
      var keys = Object.keys(respuesta.sectores);
      var filtro = [];

      keys.forEach((key, index) => {
        if (
          respuesta.sectores[key].equipos &&
          respuesta.sectores[key].nombre &&
          respuesta.sectores[key].equipos.length > 0
        ) {
          grupoSectores.push(respuesta.sectores[key]);
        } else {
          filtro.push(key);
        }
      });

      var filtroRespuesta = { ...respuesta };

      filtro.forEach(key => {
        delete filtroRespuesta.sectores[key];
      });

      var sectores = [];
      var tipoEquipo = [];

      // guardo los equipos en arrays agrupados por los sectores a los que
      // pertenecen
      grupoSectores.forEach((sector, index) => {
        let equiposSector = sector.equipos;
        sectores[index] = [];
        tipoEquipo[index] = [];

        equiposSector.forEach((equipo, i) => {
          var posicion = null;
          for (let k = 0; k < equipos.length; k++) {
            if (Object.keys(equipos[k])[0] === equipo.pkmonitoreo) {
              posicion = k;
            }
          }
          sectores[index].push(equipos[posicion]);
          tipoEquipo[index].push(equipo.tipo);
        });
      });

      // el array sectores ahora trae en cada posicion los datos de los equipos
      // que le pertenecen, la variable tipoEquipo guarda el tipo de equipo que
      // es cada uno segun la posicion

      dispatch({
        type: GET_SECTORES,
        payload: {
          respuesta: filtroRespuesta,
          grupoSectores,
          sectores,
          tipoEquipo,
          region
        }
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: ERROR_REQUEST,
        payload: region
      });
    });
};

// paso el currentSector al siguiente sector
export const getNextSector = () => dispatch => {
  return dispatch({
    type: GET_NEXT_SECTOR
  });
};

// paso el currentSector al sector anterior
export const getPrevSector = () => dispatch => {
  return dispatch({
    type: GET_PREV_SECTOR
  });
};

export const changeStore = objeto => dispatch => {
  dispatch({
    type: CHANGE_STORE_TV,
    payload: objeto
  });
};
