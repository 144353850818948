import React, { Component } from "react";

export class Error extends Component {
  state = {
    visible: true
  };

  // oculto el elemento al hacer click en la cruz
  // solo se va a mostrar si hay un error
  toggle() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    return (
      <div
        className="error"
        style={{
          display: this.props.error && this.state.visible ? "block" : "none"
        }}
      >
        <i className="fas fa-times" onClick={this.toggle.bind(this)} />
        <h3>Error al actualizar los datos</h3>
        <p>La ultima conexion fue: {this.props.date}</p>
      </div>
    );
  }
}

export default Error;
