import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStore } from "../../../actions/actionsListado";
export class ColumnCheckbox extends Component {
  state = {
    checked: false
  };
  // manejar que columnas se van a mostrar u ocultar
  // usando los checkboxes
  handleColumnChange(e) {
    var propiedad = e.target.name;
    var columns = this.props.columns;
    columns[propiedad] = !columns[propiedad];
    this.setState(
      {
        checked: e.target.checked
      },
      () => {
        this.props.changeStore({ columns });
      }
    );
  }

  componentDidMount() {
    const { column, columns } = this.props;
    // veo si la columna que representa este componente esta visible

    this.setState({
      checked: columns[column]
    });
  }

  componentDidUpdate(prevProps) {
    // si cambia de visible a no visible o viceversa, actualizo el state
    if (
      prevProps.columns[prevProps.column] !==
      this.props.columns[this.props.column]
    ) {
      this.setState({
        checked: this.props.columns[this.props.column]
      });
    }
  }

  render() {
    const { title, column } = this.props;

    return (
      <div className="columnCheckbox">
        <label htmlFor={column}>{title}</label>
        <input
          onChange={this.handleColumnChange.bind(this)}
          type="checkbox"
          id={column}
          name={column}
          checked={this.state.checked}
        />
        <label htmlFor={column} className="labelContainer">
          <div className="visibleBox" />
        </label>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  columns: state.listado.columns
});
export default connect(
  mapStateToProps,
  { changeStore }
)(ColumnCheckbox);
