import React, { Component } from "react";

export class Popup extends Component {
  render() {
    const { success, mensaje } = this.props;
    return (
      <div
        className={`popup ${success ? "OK" : "ERROR"}`}
        style={{ animation: success ? "" : "none" }}
      >
        {success ? (
          <div>
            <i
              className="fas fa-times"
              onClick={this.props.popup.bind(this, false, true)}
            />
            <p>{mensaje}</p>
          </div>
        ) : (
          <div>
            <i
              className="fas fa-times"
              onClick={this.props.popup.bind(this, false, true)}
            />
            <p>Hubo un error al aplicar los cambios, intenta nuevamente.</p>
            <p>Detalle del error:</p>
            <p className="err">{mensaje}</p>
          </div>
        )}
      </div>
    );
  }
}

export default Popup;
