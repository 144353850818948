import React, { Component } from "react";
import { changeStore, getListado } from "../../actions/actionsListado";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export class BusquedaListado extends Component {
  componentDidMount() {
    const { lastRegion } = this.props;
    this.props.getListado(lastRegion);
    // hago la peticion al sv, guardo los datos en el store de redux
  }

  filtrar() {
    const { equipos, busqueda } = this.props;
    // filtro los equipos que correspondan a la busqueda por id o ubicacion
    // guardo esas coincidencias en el state y envio al usuario
    // al listado con el filtro elegido
    if (equipos.length > 0) {
      var coincidencias = equipos.filter(equipo => {
        return (
          equipo.dlgid.toLowerCase().indexOf(busqueda) !== -1 ||
          equipo.ubicacion.toLowerCase().indexOf(busqueda) !== -1
        );
      });

      this.props.changeStore({ coincidencias, search: busqueda });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="buscarListado">
        <h2>
          No hay resultados para su búsqueda <u>en este sector</u>, haga{" "}
          <Link to="/online" onClick={this.filtrar.bind(this)}>
            click aquí
          </Link>{" "}
          si desea buscar en el listado completo de equipos.
        </h2>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  equipos: state.listado.equipos,
  lastRegion: state.sector.lastRegion,
  busqueda: state.sector.busqueda
});
export default connect(
  mapStateToProps,
  { changeStore, getListado }
)(BusquedaListado);
