import React, { Component } from "react";

export class Sqe extends Component {
  render() {
    const { data } = this.props;

    var clase = "";
    if (data <= 80) {
      clase = "OK";
    } else if (data > 80 && data <= 90) {
      clase = "yellow";
    } else if (data > 90 && data <= 100) {
      clase = "orange";
    } else if (data > 100 && data <= 105) {
      clase = "darkorange";
    } else if (data > 105) {
      clase = "red";
    }

    if (data === -1) {
      clase = "";
    }

    return (
      <div className={`sqe ${clase}`} title={data !== -1 ? data : "?"}>
        {/* {this.props.data} */}
        <i className="fas fa-wifi" />
        <span className="number">{data !== -1 ? data : "?"}</span>
      </div>
    );
  }
}

export default Sqe;
