import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { changeStore } from "../../actions/sectoresActions";
import { checkGrey, formatDate, formatNumber } from "../Funciones";
import Bateria from "../visOnline/tabla/Bateria";
import Sqe from "../visOnline/tabla/Sqe";

export class PerforacionSinA extends Component {
  mostrarPunto() {
    var id = Object.keys(this.props.data)[0];
    id = this.props.data[Object.keys(this.props.data)[0]].dlgid;
    this.props.changeStore({ currentPoint: id });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var pkmonitoreo = Object.keys(this.props.data)[0];
    var {
      CAUDAL,
      CAUDAL_state,
      PRESION_ALTA,
      PRESION_ALTA_state,
      PUMP_PERF_STATE,
      PUMP_PERF_STATE_state,
      DEPOSITO_DE_CLORO,
      DEPOSITO_DE_CLORO_state,
      tx_state,
      dlgid,
    } = this.props.data[pkmonitoreo];

    var id = dlgid;
    // formatear fecha
    var fechadata = formatDate(this.props.data[pkmonitoreo].fechadata);
    if (fechadata === -1) {
      tx_state = "ERROR";
      fechadata = "01/01/2000 00:00:00";
    }

    // Probando si la fecha da error
    try {
      let fechaPrueba = fechadata.substring(0, 10);
      let fechaPrueba2 = fechadata.substring(10);
    } catch (error) {
      console.log("Error de fecha en:", dlgid);
    }
    // chequear si es de ose o spymovil
    const { sinInstalar, poseedor, ubicacion } = checkGrey(
      this.props.data[pkmonitoreo].ubicacion
    );

    // datos bateria
    var icon, tipo;

    // retornar contenido
    if (tx_state === "ERROR") {
      return (
        <div
          className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${
            this.props.hidden ? "ocultar" : ""
          }`}
        >
          <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
            <div className="header">
              <div className="header-text">
                <div className="header-id">
                  {id}{" "}
                  <div className="botones">
                    <button
                      onClick={this.mostrarPunto.bind(this)}
                      title="Ver en el mapa"
                    >
                      <i className="fas fa-map-marked-alt" />
                    </button>
                    <Link
                      to={"/detalle/" + pkmonitoreo}
                      target="_blank"
                      title="Detalles"
                    >
                      <i className="fas fa-info" />
                    </Link>
                  </div>
                </div>
                {sinInstalar ? (
                  <div className="header-address">
                    <div
                      style={{
                        marginBottom: ".2em",
                      }}
                    >
                      {ubicacion}
                    </div>
                    <div
                      style={{
                        background: "rgb(48, 48, 48)",
                        border: "2px solid white",
                        padding: ".3em ",
                        width: "100%",
                        fontSize: "1.4em",
                        textAlign: "center",
                      }}
                    >
                      Sin instalar
                    </div>
                  </div>
                ) : (
                  <div className="header-address">{ubicacion}</div>
                )}
                {sinInstalar ? (
                  <div
                    className="header-type "
                    style={{
                      fontSize: "1.5em",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    {poseedor}
                  </div>
                ) : (
                  <div className="header-type ">PERFORACIÓN</div>
                )}
              </div>
            </div>
            <div
              className="content"
              style={{ display: sinInstalar ? "none" : "block" }}
            >
              {/* presion */}
              <div className={`content-data full ${tx_state}`}>
                <div className="data-section">
                  <div className="data-value">
                    <h3>Error</h3>
                  </div>
                </div>
              </div>

              <div className={`content-data OK`}>
                <div className="data-section">
                  <div className="data-name">Fecha</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${
          this.props.hidden ? "ocultar" : ""
        }`}
      >
        <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
          <div className="header">
            <div className="header-text">
              <div className="header-id">
                {id}{" "}
                <div className="botones">
                  <button
                    onClick={this.mostrarPunto.bind(this)}
                    title="Ver en el mapa"
                  >
                    <i className="fas fa-map-marked-alt" />
                  </button>
                  <Link
                    to={"/detalle/" + pkmonitoreo}
                    target="_blank"
                    title="Detalles"
                  >
                    <i className="fas fa-info" />
                  </Link>
                </div>
              </div>
              {sinInstalar ? (
                <div className="header-address">
                  <div
                    style={{
                      marginBottom: ".2em",
                    }}
                  >
                    {ubicacion}
                  </div>
                  <div
                    style={{
                      background: "rgb(48, 48, 48)",
                      border: "2px solid white",
                      padding: ".3em ",
                      width: "100%",
                      fontSize: "1.4em",
                      textAlign: "center",
                    }}
                  >
                    Sin instalar
                  </div>
                </div>
              ) : (
                <div className="header-address">{ubicacion}</div>
              )}
              {sinInstalar ? (
                <div
                  className="header-type "
                  style={{
                    fontSize: "1.5em",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  {poseedor}
                </div>
              ) : (
                <div className="header-type ">PERFORACIÓN</div>
              )}
            </div>
          </div>
          <div
            className="content"
            style={{ display: sinInstalar ? "none" : "block" }}
          >
            {CAUDAL || CAUDAL === 0 ? (
              <div className={`content-data ${CAUDAL_state}`}>
                <div className="data-section">
                  <div className="data-name">Caudal</div>
                  <div className="data-value"> </div>
                </div>
                <div className="data-section">
                  <div className="data-name"></div>
                  <div className="data-value">
                    {CAUDAL_state !== "OK" ? (
                      "Error"
                    ) : (
                      <React.Fragment>
                        {formatNumber.new(CAUDAL.toFixed(2))}{" "}
                        <div className="data-unit">
                          mt<sup>3</sup>h
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {/* Deposito */}
            {DEPOSITO_DE_CLORO || DEPOSITO_DE_CLORO === 0 ? (
              <div className={`content-data ${DEPOSITO_DE_CLORO_state}`}>
                <div className="data-section">
                  <div className="data-name-deposito ">Depósito de cloro</div>
                  <div className="data-value">
                    {" "}
                  </div>
                </div>
                <div className="data-section">
                  <div className="data-name"></div>
                  <div className="data-value">
                    {DEPOSITO_DE_CLORO_state !== "OK" ? (
                      "Error"
                    ) : (
                      <React.Fragment>
                        {formatNumber.new(DEPOSITO_DE_CLORO)}{" "}
                        <div className="data-unit">%</div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {/* presion */}
            {PRESION_ALTA || PRESION_ALTA === 0 ? (
              <div className={`content-data ${PRESION_ALTA_state}`}>
                {PRESION_ALTA || PRESION_ALTA === 0 ? (
                  <div className="data-section">
                    <div className="data-name">Presión</div>
                    <div className="data-value">
                      {PUMP_PERF_STATE ? (
                        <React.Fragment>
                          {PRESION_ALTA_state !== "OK" ? (
                            "Error"
                          ) : (
                            <React.Fragment>
                              {formatNumber.new(PRESION_ALTA.toFixed(2))}
                              <div className="data-unit">bar</div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : null}

                <div
                  className={`data-section ${
                    PUMP_PERF_STATE === 1 ? "BOMBA" : ""
                  }`}
                >
                  {PUMP_PERF_STATE ? (
                    <div className="data-name">Bomba</div>
                  ) : (
                    <div className="data-name">{""}</div>
                  )}
                  <div className="data-value">
                    {PUMP_PERF_STATE ? (
                      PUMP_PERF_STATE === 0 ? (
                        "Apagada"
                      ) : PUMP_PERF_STATE === 1 ? (
                        "Encendida"
                      ) : PUMP_PERF_STATE === "ERROR" ? (
                        "Error"
                      ) : (
                        ""
                      )
                    ) : (
                      <React.Fragment>
                        {PRESION_ALTA_state !== "OK" ? (
                          "Error"
                        ) : (
                          <React.Fragment>
                            {formatNumber.new(PRESION_ALTA.toFixed(2))}
                            <div className="data-unit">bar</div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}

                  </div>
                </div>
              </div>
            ) : null}

            {!PRESION_ALTA && PUMP_PERF_STATE ? (
              <div className={`content-data OK`}>
                <div className={`data-section `}>
                  <div className="data-name">Bomba</div>
                  <div className="data-value">{""}</div>
                </div>

                <div
                  className={`data-section ${
                    PUMP_PERF_STATE === 1 ? "BOMBA" : "ERROR"
                  }`}
                >
                  <div className="data-name">{""}</div>
                  <div className="data-value">
                    {PUMP_PERF_STATE === 0 ? "Apagada" : ""}
                    {PUMP_PERF_STATE === 1 ? "Encendida" : ""}
                    {PUMP_PERF_STATE === "ERROR" ? "Error" : ""}

                  </div>
                </div>
              </div>
            ) : null}

            {/* caudal */}

            {tx_state === "WARNING" ? (
              <div className={`content-data ${tx_state}`}>
                <div className="data-section">
                  <div className="data-name">Error</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`content-data OK`}>
                <div className="data-section">
                  <div className="data-name">Fecha</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { changeStore })(PerforacionSinA);
