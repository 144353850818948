import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStore } from "../../actions/sectoresActions";
import { Link } from "react-router-dom";
import { formatNumber, formatDate, checkGrey } from "../Funciones";
import Sqe from "../visOnline/tabla/Sqe";
import Bateria from "../visOnline/tabla/Bateria";

export class Tanque extends Component {
  mostrarPunto() {
    var id = Object.keys(this.props.data)[0];
    id = this.props.data[Object.keys(this.props.data)[0]].dlgid;
    this.props.changeStore({ currentPoint: id });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  componentDidMount() {
    this.cargarTanque();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this[this.props.data[Object.keys(this.props.data)[0]].dlgid]) {
      this[this.props.data[Object.keys(this.props.data)[0]].dlgid] = null;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data[Object.keys(prevProps.data)[0]].dlgid !==
      this.props.data[Object.keys(this.props.data)[0]].dlgid
    ) {
      this.cargarTanque();
    }
  }

  cargarTanque() {
    var id = this.props.data[Object.keys(this.props.data)[0]].dlgid;

    if (!document.getElementById(id)) {
      return null;
    }
    if (!window.FusionCharts) {
      this.timeout = setTimeout(() => {
        this.cargarTanque();
      }, 1000);
      return null;
    }
    var { altura_max, altura } = this.props.data[
      Object.keys(this.props.data)[0]
    ];
    const dataSource = {
      chart: {
        lowerlimit: "0",
        upperlimit: `${altura_max.toFixed(2)}`,
        lowerlimitdisplay: "0",
        upperlimitdisplay: `${altura_max.toFixed(2)} Mts`,
        numbersuffix: "Mts",
        cylfillcolor: "#007de4",
        plottooltext: `Altura: <b>${altura.toFixed(2)} mts</b>`,
        theme: "fusion",
        baseFont: "Poppins, sans-serif",
        bgColor: "#f3f3f3",
        bgAlpha: "100",
        baseFontColor: "#000000"
      },
      value: `${altura}`
    };

    this[id] = null;

    this[id] = new window.FusionCharts({
      type: "cylinder",
      renderAt: id,
      width: "200",
      height: "170",
      dataFormat: "json",
      dataSource
    }).render();
  }

  render() {
    var pkmonitoreo = Object.keys(this.props.data)[0];
    var {
      altura_state,
      porcentaje,
      altura,
      tx_state,
      dlgid,
      sqe,
      bt,
      alimentacion
    } = this.props.data[pkmonitoreo];
    var id = dlgid;

    // formatear fecha
    var fechadata = formatDate(this.props.data[pkmonitoreo].fechadata);
    // var fechadata = undefined
    if (fechadata === -1) {
      tx_state = 'ERROR'
      fechadata = '01/01/2000 00:00:00'

    }
    // var fechadata = undefined

    // Probando si la fecha da error 
    try {
      let fechaPrueba = fechadata.substring(0, 10)
      let fechaPrueba2 = fechadata.substring(10)
    } catch (error) {
      console.log("Error de fecha en:", dlgid)
    }
    // chequear si es de ose o spymovil
    const { sinInstalar, poseedor, ubicacion } = checkGrey(
      this.props.data[pkmonitoreo].ubicacion
    );

    // datos bateria
    var icon, tipo;
    switch (alimentacion) {
      case "TURBINA":
        icon = "turbina";
        tipo = "Turbina";
        break;
      case "CORRIENTE":
        icon = "fas fa-plug";
        tipo = "Corriente";
        break;
      case "SOLAR":
        icon = "fas fa-sun";
        tipo = "Solar";
        break;

      default:
        tipo = "Bateria";
        icon = "";
        break;
    }
    // retornar contenido
    if (tx_state === "ERROR") {
      return (
        <div
          className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${this.props.hidden ? "ocultar" : ""
            }`}
        >
          <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
            <div className="header">
              <div className="header-text">
                <div className="header-id">
                  {id}{" "}
                  <div className="botones">
                    <button
                      onClick={this.mostrarPunto.bind(this)}
                      title="Ver en el mapa"
                    >
                      <i className="fas fa-map-marked-alt" />
                    </button>
                    <Link
                      to={"/detalle/" + pkmonitoreo}
                      target="_blank"
                      title="Detalles"
                    >
                      <i className="fas fa-info" />
                    </Link>
                  </div>
                </div>
                {sinInstalar ? (
                  <div className="header-address">
                    <div
                      style={{
                        marginBottom: ".2em"
                      }}
                    >
                      {ubicacion}
                    </div>
                    <div
                      style={{
                        background: "rgb(48, 48, 48)",
                        border: "2px solid white",
                        padding: ".3em ",
                        width: "100%",
                        fontSize: "1.4em",
                        textAlign: "center"
                      }}
                    >
                      Sin instalar
                    </div>
                  </div>
                ) : (
                    <div className="header-address">{ubicacion}</div>
                  )}
                {sinInstalar ? (
                  <div
                    className="header-type "
                    style={{
                      fontSize: "1.5em",
                      display: "inline-block",
                      width: "100%"
                    }}
                  >
                    {poseedor}
                  </div>
                ) : (
                    <div className="header-type ">
                      Tanque <i className="fas fa-fill" />
                    </div>
                  )}
              </div>
            </div>
            <div
              className="content"
              style={{ display: sinInstalar ? "none" : "block" }}
            >
              {/* presion */}
              <div className={`content-data full ${tx_state}`}>
                <div className="data-section">
                  <div className="data-value">
                    <h3>Error</h3>
                  </div>
                </div>
              </div>

              <div className={`content-data OK`}>
                <div className="data-section">
                  <Sqe data={sqe} />{" "}
                  <Bateria
                    data={formatNumber.new(bt.toFixed(1))}
                    icon={icon}
                    tipo={tipo}
                  />
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${this.props.hidden ? "ocultar" : ""
          }`}
      // style={{ display: this.props.hidden ? "none" : "" }}
      >
        <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
          <div className="header">
            <div className="header-text">
              <div className="header-id">
                {id}{" "}
                <div className="botones">
                  <button
                    onClick={this.mostrarPunto.bind(this)}
                    title="Ver en el mapa"
                  >
                    <i className="fas fa-map-marked-alt" />
                  </button>
                  <Link
                    to={"/detalle/" + pkmonitoreo}
                    title="Detalles"
                    target="_blank"
                  >
                    <i className="fas fa-info" />
                  </Link>
                </div>
              </div>
              {sinInstalar ? (
                <div className="header-address">
                  <div
                    style={{
                      marginBottom: ".2em"
                    }}
                  >
                    {ubicacion}
                  </div>
                  <div
                    style={{
                      background: "rgb(48, 48, 48)",
                      border: "2px solid white",
                      padding: ".3em ",
                      width: "100%",
                      fontSize: "1.4em",
                      textAlign: "center"
                    }}
                  >
                    Sin instalar
                  </div>
                </div>
              ) : (
                  <div className="header-address">{ubicacion} </div>
                )}
              {sinInstalar ? (
                <div
                  className="header-type "
                  style={{
                    fontSize: "1.5em",
                    display: "inline-block",
                    width: "100%"
                  }}
                >
                  {poseedor}
                </div>
              ) : (
                  <div className="header-type ">
                    Tanque <i className="fas fa-fill" />
                  </div>
                )}
            </div>
          </div>
          <div
            className="content"
            style={{ display: sinInstalar ? "none" : "block" }}
          >
            <div className="content-data full OK">
              <div className="data-section tanque">
                <div className="tanqueContainer">
                  <div id={id} />
                </div>
              </div>
            </div>
            <div className={`content-data ${altura_state}`}>
              <div className="data-section">
                <div className="data-name">Altura</div>
                <div className="data-value">
                  {altura_state !== "OK" ? (
                    "Error"
                  ) : (
                      <React.Fragment>
                        {formatNumber.new(altura.toFixed(2))}{" "}
                        <div className="data-unit">mts</div>
                      </React.Fragment>

                    )}

                </div>
              </div>
              <div className="data-section">
                <div className="data-name">Porcentaje</div>
                <div className="data-value">
                  {formatNumber.new(porcentaje.toFixed(2))}{" "}
                  <div className="data-unit">%</div>
                </div>
              </div>
            </div>
            <div className={`content-data OK`}>
              <div className="data-section">
                <Sqe data={sqe} />{" "}
                <Bateria
                  data={formatNumber.new(bt.toFixed(1))}
                  icon={icon}
                  tipo={tipo}
                />
              </div>
              <div className="data-section">
                <div className="data-value">
                  <span className="fecha">
                    {
                      fechadata.substring(0, 10)
                    }
                    <br />
                    {fechadata.substring(10)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { changeStore }
)(Tanque);
