import { GET_LISTADO, TABLE_SORT, CHANGE_STORE } from "../actions/types";
const initialState = {
  equipos: [],
  columns: {
    dlgid: true,
    ubicacion: true,
    q0: true,
    CAUDAL_ACUMULADO: true,
    pA: true,
    pZA: true,
    pB: true,
    pZB: true,
    cota: true,
    sqe: true,
    bt: true,
    fechadata: true,
    altura: true,
    porcentaje: true,
    CAUDAL: true,
    PRESION_ALTA: true,
    PUMP_PERF_STATE: true,
    DEPOSITO_DE_CLORO: true,
    CL_LIBRE: true,
    CL_ALARM_CONTROL: true,
    H_MAX_TQ: true,
    H_TQ: true,
    CAUDAL_IMP: true,
    P_PRESSURE: true,
  },
  sort: {
    column: "dlgid",
    type: "ASC"
  },
  coincidencias: [],
  search: "",
  filtro: "Cualquiera",
  sector: null,
  nameSectores: [],
  sectores: [],
  error: false,
  clase: "",
  estado: "",
  solo: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LISTADO:
      var { equipos, sectores, nameSectores, data } = action.payload;
      var { sort } = state;

      // ordenar array segun el orden del state
      equipos = equipos.sort(function (a, b) {
        var aComparator = isNaN(a[sort.column])
          ? a[sort.column].toLowerCase()
          : a[sort.column];

        var bComparator = isNaN(b[sort.column])
          ? b[sort.column].toLowerCase()
          : b[sort.column];

        if (aComparator > bComparator) {
          return state.sort.type === "DESC" ? -1 : 1;
        }
        if (aComparator < bComparator) {
          return state.sort.type === "DESC" ? 1 : -1;
        }
        // a must be equal to b
        return 0;
      });

      // separar los que tienen error
      var conError = [];
      var conAlerta = [];
      var conProblemas = [];
      equipos = equipos.filter(equipo => {
        if (
          equipo.ubicacion.substring(0, 3) === "OSE" ||
          equipo.ubicacion.substring(0, 8) === "SPYMOVIL"
        ) {
          conError.push(equipo);
          return false;
        }

        if (equipo.tx_state === "WARNING") {
          conAlerta.push(equipo);
          return false;
        }
        if (equipo.tx_state === "ERROR" || equipo.tx_state === "Error" || equipo.tx_state === "SI") {
          conProblemas.push(equipo);
          return false;
        }

        return true;
      });

      equipos = [...conAlerta, ...conProblemas, ...equipos, ...conError];

      // revisar si hay solo equipos de un tipo
      var solo = false;
      var pq = false;
      var tq = false;
      var spx = false;
      var caudal = false;

      equipos.forEach(equipo => {
        if (equipo.id.substring(0, 2) === "PQ") {
          pq = true;
        }
        if (equipo.id.substring(0, 2) === "TQ") {
          tq = true;
        } 
        if (equipo.id.substring(0, 3) === "SPX") {
          spx = true;
        }


        // reviso si hay caudalimetro para ocultar todo lo relacionado a caudal
        if (equipo.macro && equipo.macro === 1) {
          caudal = true;
        }
      });

      if (!tq) {
        solo = "PQ";
      }
      if (!pq) {
        solo = "TQ";
      }
      if (!spx) {
        solo = "SPX";
      }

      // muestro las columnas que correspondan
      var columns = {
        dlgid: true,
        ubicacion: true,
        q0: true,
        CAUDAL_ACUMULADO: true,
        pA: true,
        pZA: true,
        pB: true,
        pZB: true,
        cota: true,
        sqe: true,
        bt: true,
        fechadata: true,
        altura: true,
        porcentaje: true,
        CAUDAL: true,
        PUMP_PERF_STATE: true,
        PRESION_ALTA: true,
        DEPOSITO_DE_CLORO: true,
        CL_LIBRE: true,
        CL_ALARM_CONTROL: true,
        H_MAX_TQ: true,
        H_TQ: true,
        CAUDAL_IMP: true,
        P_PRESSURE: true,
      };
      var cambios = [];

      if (solo === "PQ") {
        cambios = "altura, porcentaje".split(", ");
      }
      if (solo === "TQ") {
        cambios = "q0, CAUDAL_ACUMULADO, pA, pZA, pB, pZB, cota".split(", ");
      }
      if (solo === "SPX") {
        cambios = "altura, porcentaje, q0, CAUDAL_ACUMULADO, pA, pZA, pB, pZB, cota".split(", ");
      }
      // , CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, CL_LIBRE, CL_ALARM_CONTROL, H_TQ, H_MAX_TQ, CAUDAL_IMP, P_PRESSURE
      if (!caudal) {
        var cambiosCaudal = "q0, CAUDAL_ACUMULADO, pB, pZB".split(", ");
        cambios = [...cambios, ...cambiosCaudal];
      }
      // quito las columnas que no deben mostrarse
      cambios.forEach(cambio => {
        columns[cambio] = false;
      });

      return {
        ...state,
        equipos,
        coincidencias:
          state.search !== "" || state.filtro !== "Cualquiera"
            ? state.coincidencias
            : equipos,
        sectores,
        nameSectores,
        error: false,
        data,
        solo,
        columns
      };

    case TABLE_SORT:
      var { column, type } = action.payload;
      var sort = { column, type };
      var { coincidencias } = state;
      // ordeno el array segun lo indicado por el usuario
      coincidencias = coincidencias.sort(function (a, b) {
        if (a[sort.column] === undefined) {
          a[sort.column] = -1;
        }
        if (b[sort.column] === undefined) {
          b[sort.column] = -1;
        }
        var aComparator = isNaN(a[sort.column])
          ? a[sort.column].toLowerCase()
          : a[sort.column];

        var bComparator = isNaN(b[sort.column])
          ? b[sort.column].toLowerCase()
          : b[sort.column];
        if (aComparator > bComparator) {
          return sort.type === "DESC" ? -1 : 1;
        }
        if (aComparator < bComparator) {
          return sort.type === "DESC" ? 1 : -1;
        }
        // a must be equal to b
        return 0;
      });

      // separar los que tienen error
      var conError = [];
      var conAlerta = [];
      var conProblemas = [];
      coincidencias = coincidencias.filter(equipo => {
        if (
          equipo.ubicacion.substring(0, 3) === "OSE" ||
          equipo.ubicacion.substring(0, 8) === "SPYMOVIL"
        ) {
          conError.push(equipo);
          return false;
        }

        if (equipo.tx_state === "WARNING") {
          conAlerta.push(equipo);
          return false;
        }
        if (equipo.tx_state === "ERROR") {
          conProblemas.push(equipo);
          return false;
        }

        return true;
      });

      coincidencias = [
        ...conAlerta,
        ...conProblemas,
        ...coincidencias,
        ...conError
      ];

      return {
        ...state,
        sort,
        coincidencias
      };

    case CHANGE_STORE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
