import React, { Component } from "react";
import { formatNumber } from "../Funciones";

export class SectorDataCaudal extends Component {
  render() {
    const {
      instantaneo,
      acumulado,
      nocturno,
      nocMeta,
      estado
    } = this.props.datos;

    return (
      <div className="data-block">
        <div className="data-title">Caudal</div>
        <div className={"data-container "}>
          <div className={"data-subcontainer " + estado.instantaneo}>
            <div className="data-name">Inst.:</div>
            <div className="data-content">
              <div className="data-value">
                {formatNumber.new(instantaneo.toFixed(2)) != -1
                  ? formatNumber.new(instantaneo.toFixed(2))
                  : "-"}{" "}
                <div className="data-unit">
                  mt<sup>3</sup>/h
                </div>
              </div>
            </div>
          </div>
          <div className="data-subcontainer">
            <div className="data-name">Acum. Mensual:</div>
            <div className="data-content">
              <div className="data-value">
                {formatNumber.new(Math.round(acumulado)) != -1
                  ? formatNumber.new(Math.round(acumulado))
                  : "-"}{" "}
                <div className="data-unit">
                  mt<sup>3</sup>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"data-container caudal " + estado.nocMeta}>
          <div className={"data-subcontainer "}>
            <div className="data-name">Noc. Pro.:</div>
            <div className="data-content">
              <div className="data-value">
                {formatNumber.new(nocturno) != -1
                  ? formatNumber.new(nocturno.toFixed(2))
                  : "-"}{" "}
                <div className="data-unit">mt3/h</div>
              </div>
            </div>
          </div>
          <div className={"data-subcontainer "}>
            <div className="data-name">Noc. meta:</div>
            <div className="data-content">
              <div className="data-value">
                {formatNumber.new(nocMeta) != -1
                  ? formatNumber.new(nocMeta.toFixed(2))
                  : "-"}{" "}
                <div className="data-unit">mt3/h</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectorDataCaudal;
