import React, { Component } from "react";
import SectorDataCaudal from "./SectorDataCaudal";
import SectorDataElevada from "./SectorDataElevada";
import { connect } from "react-redux";
import { changeStore } from "../../actions/sectoresActions";
import bomba from "./bomba.json";
import SectorDataPerforaciones from "./SectorDataPerforaciones";

export class SectorData extends Component {
  state = {
    nombre: ""
  };

  componentDidMount() {
    this.changeName();
  }
  changeName() {
    // cambio el titulo por el del sector actual
    this.setState({
      nombre: this.props.currentSector
    });
  }
  componentDidUpdate(prevProps) {
    // si cambio de sector cambio el titulo
    if (this.props.currentSector !== prevProps.currentSector) {
      this.changeName();
    }
  }

  handleSelect(e) {
    var value = parseInt(e.target.value);
    // detengo la reproduccion y cambio el sector por el nuevo
    this.props.changeStore({
      currentSector: value,
      timer: null,
      recorriendo: false
    });

    this.setState({
      nombre: value
    });
  }

  render() {
    const { datos, sectores } = this.props;
    // si hay agua elevada renderizo el componente agua elevada, de lo contrario solo el caudal
    const aguaElevada = datos.agua_elevada !== undefined ? true : false;
    const caudal = datos.caudal !== undefined ? true : false;


    // preguntar si es una perforacion 
    var {
      VAR_FREC,
      D_EXEC_PER_PUMP,
      D_EXEC_PER_PUMP_ERROR,
      T_EXEC_PER_PUMP,
      SW1,
      EMERGENCY_STATE,
      EMERGENCY_SYSTEM,
      LOCAL_MODE
    } = datos.perfAutoDatos;
    const perfAutoDatos = datos.perfAutoDatos !== undefined ? true : false;
    // const bombaAuto = false;
    // const { tanques, bombas } = this.bomba[1];


    return (
      <div className="sectorDataContainer">
        <div className="sector">
          <div className="sector-name">
            <label htmlFor="nombre">
              <h2 className="name">
                <select
                  id="nombre"
                  value={this.state.nombre}
                  onChange={this.handleSelect.bind(this)}
                >
                  {sectores.map((sector, index) => {
                    return (
                      <option key={index} value={index}>
                        {sector}
                      </option>
                    );
                  })}
                </select>
              </h2>
            </label>
          </div>
          <div className="sector-data">
            {caudal ? <SectorDataCaudal datos={datos.caudal} /> : null}
            {aguaElevada ? (
              <SectorDataElevada datos={datos.agua_elevada} />
            ) : null}

            {/* enviar por props los datos de la perforacion */}
            {VAR_FREC || D_EXEC_PER_PUMP || D_EXEC_PER_PUMP_ERROR || T_EXEC_PER_PUMP || SW1 || EMERGENCY_STATE || EMERGENCY_SYSTEM || LOCAL_MODE ? (
              <SectorDataPerforaciones datos={datos.perfAutoDatos} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sectores: state.sector.grupoSectores.map(sector => {
    return sector.nombre;
  }),
  currentSector: state.sector.currentSector
});

export default connect(
  mapStateToProps,
  { changeStore }
)(SectorData);
