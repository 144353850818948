import React, { Component } from "react";
import axios from "axios";
import CrearPresionesCaudales from "./CrearPresionesCaudales";
import CrearAguaElevada from "./CrearAguaElevada";
import EditarPresionesCaudales from "./EditarPresionesCaudales";
import EditarAguaElevada from "./EditarAguaElevada";
import "../../css/config.css";
import { Link } from "react-router-dom";
import Load from "../Load";
import Footer from "../Footer";
import ScrollTop from "../ScrollTop";
import "../../css/simplebar.css";
import Popup from "./Popup";

export class Configuracion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: "",
      error: false,
      popup: false,
      success: true,
      mensaje: "",
      loading: false
    };
  }

  popup(tipo, success, mensaje) {
    this.setState(
      {
        popup: tipo,
        success,
        mensaje
      },
      () => {
        if (success) {
          this.timeout = setTimeout(() => {
            this.setState({
              popup: false
            });
          }, 5000);
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  componentDidMount() {
    this.leerDatos();
  }
  leerDatos() {
    // this.setState({
    //   sectores: json.sectores,
    //   equipos: json.equipos
    // });

    // hago la peticion al servidor y guardo los datos recibidos en el
    // state para poder utilizarlo en los demas componentes
    this.setState({
      loading: true
    });
    axios
      .get(this.props.region)
      .then(res => {
        if (
          res.data.sectores &&
          res.data.equipos &&
          res.data.url_adm_sector &&
          res.data.region
        ) {
          this.setState({
            sectores: res.data.sectores,
            equipos: res.data.equipos,
            error: false,
            url: res.data.url_adm_sector,
            region: res.data.region,
            loading: false
          });
        } else {
          this.setState({
            error: true
          });
        }
      })
      .catch(err => {
        this.setState({
          error: true
        });
        console.log(err);
      });
  }

  toggle(variable) {
    // elijo cual componente va a ser visible, los demas no se renderizan
    if (this.state.visible !== variable) {
      this.setState({
        visible: variable
      });
    } else {
      this.setState({
        visible: ""
      });
    }
  }

  render() {
    const {
      visible,
      sectores,
      error,
      popup,
      success,
      mensaje,
      loading
    } = this.state;
    // si aun no carga la peticion al server entonces muestro
    // carga o error
    if (!sectores && !error) {
      return <Load error={false} />;
    }

    if (!sectores && error) {
      return (
        <div className="load">
          <div className="error inicial">
            <h2>No se pudo conectar con el servidor</h2>
            <p>
              Vuelve a intentarlo en unos momentos,{" "}
              <Link to="/">has click aquí</Link> para volver al inicio.{" "}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="configuracion">
        {loading ? (
          <div className="loading">
            <div className="cargando" />
            <span>Actualizando Configuracion...</span>
          </div>
        ) : null}
        {popup ? (
          <Popup
            success={success}
            popup={this.popup.bind(this)}
            mensaje={mensaje}
          />
        ) : null}
        <ScrollTop />
        <div className="container">
          <h1>Configuración de Sectores</h1>
          <div className="formulario">
            <div className="row">
              {/* crear */}
              <div className="col-lg-6">
                <h2 onClick={this.toggle.bind(this, "crearPresionesCaudales")}>
                  Crear sector
                </h2>
                <div
                  className="window"
                  style={{
                    display:
                      visible == "crearPresionesCaudales" ? "block" : "none"
                  }}
                >
                  <i
                    className="fas fa-times"
                    onClick={this.toggle.bind(this, "crearPresionesCaudales")}
                  />
                  <div
                    className="overlaywindow"
                    onClick={this.toggle.bind(this, "crearPresionesCaudales")}
                  />
                  {visible === "crearPresionesCaudales" ? (
                    <CrearPresionesCaudales
                      equipos={this.state.equipos}
                      url={this.state.url}
                      toggle={this.toggle.bind(this)}
                      region={this.state.region}
                      popup={this.popup.bind(this)}
                      cargar={this.leerDatos.bind(this)}
                    />
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                {/* editar */}
                <h2 onClick={this.toggle.bind(this, "EditarPresionesCaudales")}>
                  Editar sector
                </h2>
                {Object.keys(sectores).length > 0 ? (
                  <div
                    className="window"
                    style={{
                      display:
                        visible == "EditarPresionesCaudales" ? "block" : "none"
                    }}
                  >
                    <div
                      className="overlaywindow"
                      onClick={this.toggle.bind(
                        this,
                        "EditarPresionesCaudales"
                      )}
                    />
                    <i
                      className="fas fa-times"
                      onClick={this.toggle.bind(
                        this,
                        "EditarPresionesCaudales"
                      )}
                    />

                    {visible === "EditarPresionesCaudales" ? (
                      <EditarPresionesCaudales
                        equipos={this.state.equipos}
                        sectores={this.state.sectores}
                        cargar={this.leerDatos.bind(this)}
                        url={this.state.url}
                        toggle={this.toggle.bind(this)}
                        region={this.state.region}
                        popup={this.popup.bind(this)}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className="col-lg-6">
                <h2
                  onClick={this.toggle.bind(this, "crearAguaElevada")}
                  className="elevada"
                >
                  Crear sistema de agua elevada
                </h2>
                <div
                  className="window"
                  style={{
                    display: visible == "crearAguaElevada" ? "block" : "none"
                  }}
                >
                  <i
                    className="fas fa-times"
                    onClick={this.toggle.bind(this, "crearAguaElevada")}
                  />
                  <div
                    className="overlaywindow"
                    onClick={this.toggle.bind(this, "crearAguaElevada")}
                  />

                  {visible === "crearAguaElevada" ? (
                    <CrearAguaElevada
                      equipos={this.state.equipos}
                      url={this.state.url}
                      toggle={this.toggle.bind(this)}
                      region={this.state.region}
                      popup={this.popup.bind(this)}
                      cargar={this.leerDatos.bind(this)}
                    />
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <h2
                  onClick={this.toggle.bind(this, "EditarAguaElevada")}
                  className="elevada"
                >
                  Editar sistema de agua elevada
                </h2>
                {Object.keys(sectores).length > 0 ? (
                  <div
                    className="window"
                    style={{
                      display: visible == "EditarAguaElevada" ? "block" : "none"
                    }}
                  >
                    <div
                      className="overlaywindow"
                      onClick={this.toggle.bind(this, "EditarAguaElevada")}
                    />
                    <i
                      className="fas fa-times"
                      onClick={this.toggle.bind(this, "EditarAguaElevada")}
                    />
                    {visible === "EditarAguaElevada" ? (
                      <EditarAguaElevada
                        equipos={this.state.equipos}
                        sectores={this.state.sectores}
                        cargar={this.leerDatos.bind(this)}
                        url={this.state.url}
                        toggle={this.toggle.bind(this)}
                        region={this.state.region}
                        popup={this.popup.bind(this)}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Configuracion;
