import React, { Component } from "react";
import PuntoEntrada from "./PuntoEntrada";
import PuntoExportacion from "./PuntoExportacion";
import PuntoMedio from "./PuntoMedio";
import Error from "./Error";
import Controls from "./Controls";
import Tanque from "./Tanque";
import Bomba from "./Bomba";
import Otro from "./Otro";
import PerforacionSinA from "./PerforacionSinA"
import { connect } from "react-redux";
import {
  getNextSector,
  getPrevSector,
  changeStore
} from "../../actions/sectoresActions";
import BusquedaListado from "./BusquedaListado";

import perforacionAuto from "../header/bomba.json";
import BombaConA from "./BombaConA";
import TanqueConA from "./TanqueConA";
import Cloro from "./Cloro";

export class EquipoContainer extends Component {
  reproducir(time) {
    // limpio los timeouts si siguen corriendo
    this.limpiarTimeouts();
    // si la resolucion es mayor a la de celular inicio la reproduccion
    if (window.innerWidth > 600) {
      // inicio la reproduccion
      this.props.changeStore({ recorriendo: true, timer: time });
      // espero a que se hayan hecho los cambios en el store y activo las funciones
      setTimeout(() => {
        this.timer();
        this.desplazar(true);
      }, 100);
    }
  }

  limpiarTimeouts() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.timeout2) {
      clearTimeout(this.timeout2);
      this.timeout2 = null;
    }
  }

  pausar() {
    // limpio los timeouts
    this.limpiarTimeouts();
    // detengo la reproduccion
    this.props.changeStore({ timer: null, recorriendo: false });
  }

  componentWillUnmount() {
    this.limpiarTimeouts();

    window.removeEventListener("resize", this.updateDim);
  }

  /////////
  //timer()
  /////////
  // cuenta regresiva antes de pasar al siguiente sector

  timer() {
    ///////////////////////////////////////////////////////////
    // solo sigue corriendo en caso de que RECORRIENDO sea TRUE
    ///////////////////////////////////////////////////////////

    var self = this;
    // si el timer aun no es 0 lo disminuyo en 1 cada segundo
    if (
      this.props.timer != null &&
      this.props.recorriendo &&
      this.props.timer > 0 &&
      window.innerWidth > 600
    ) {
      this.props.changeStore({ timer: self.props.timer - 1 });
      this.timeout = setTimeout(function () {
        self.timer();
      }, 1000);
    }
    // sino reinicio el timer y vuelvo a empezar
    else if (
      this.props.recorriendo &&
      this.props.timer <= 0 &&
      this.props.timer != null
    ) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.props.getNextSector();
    }
  }

  ///////////////////////////////////
  //ComponentDidMount()
  //////////////////////////////////
  //Cuando el componente carga se realizan las siguientes tareas:
  //1- Se traen los datos del sector actual
  //2- se calcula la cantidad de equipos que entran en pantalla
  //3- se calcula la cantidad total de paginas que va a tener el desplazamiento
  //4- se inicia el listener para actualizar estos calculos en caso de que cambie
  // el ancho de la ventana
  //5- cambio el estado utilizando los calculos que realice antes
  //6- inicio la reproduccion

  componentDidMount() {
    // calcular cantidad de elementos
    const { maxXpage, totalPages } = this.calcPaginacion();
    // si cambio el tamano de la pantalla actualizo el calculo de la cantidad
    // de equipos

    this.updateDim = this.updateDimensions.bind(this);
    window.addEventListener("resize", this.updateDim);

    // cambio los datos segun los calculos de arriba

    this.props.changeStore({
      maxXpage,
      totalPages,
      currentPage: 1,
      timeXpage: 7
    });
    // inicio la reproduccion
    this.reproducir(totalPages * this.props.timeXpage);
  }

  /////////////////////////////////////
  //componentDidUpdate()
  ////////////////////////////////////
  // 1-si estaba recorriendo los sectores y paso al siguiente,
  // reviso que todo este bien y continuo con TIMER()
  // 2-actualizo tambien el tiempo de espera de TIMER() tomando
  // en cuenta la cantidad de equipos del sector nuevo
  // 3- Cambio el state calculando de nuevo la paginacion si es necesario

  componentDidUpdate(prevProps) {
    /////////////////////////////
    // recuento la paginacion
    //////////////////////////////
    const { maxXpage, totalPages } = this.calcPaginacion();

    // si cambia la cantidad total de paginas las actualizo
    if (prevProps.totalPages !== totalPages) {
      this.props.changeStore({
        maxXpage,
        totalPages,
        currentPage: 1
      });
    }

    // si estoy reproduciendo y cambio de sector porque el timer llega a 0,
    // llamo nuevamente a timer
    if (
      this.props.recorriendo &&
      this.props.currentSector !== prevProps.currentSector
    ) {
      // cambio el tiempo que dura la reproduccion dependiendo de la cantidad de paginas
      // en el sector al que avanzo

      this.props.changeStore({
        timer: totalPages * this.props.timeXpage
      });
      if (prevProps.timer === 0) {
        setTimeout(() => {
          this.timer();
        }, 100);
      }
    }
  }

  calcPaginacion() {
    // calcular cantidad de elementos
    var maxXpage;
    var ancho = window.innerWidth;
    if (ancho < 768) {
      maxXpage = 1;
    } else if (ancho >= 768 && ancho < 992) {
      maxXpage = 2;
    } else if (ancho >= 992 && ancho < 1200) {
      maxXpage = 3;
    } else if (ancho >= 1200 && ancho < 2000) {
      maxXpage = 4;
    } else if (ancho >= 2000 && ancho < 2400) {
      maxXpage = 5;
    } else if (ancho >= 2400 && ancho < 2800) {
      maxXpage = 6;
    } else if (ancho >= 2800) {
      maxXpage = 7;
    } else {
      maxXpage = Math.floor(ancho / 400);
    }
    //si redondea a cero entonces es 1
    if (maxXpage < 1) {
      maxXpage = 1;
    }

    var totalPages = Math.ceil(this.props.coincidencias.length / maxXpage);
    if (totalPages < 1) {
      totalPages = 1;
    }

    return { maxXpage, totalPages };
  }
  ////////////////////
  //updateDimension()
  ///////////////////
  //Esta funcion se llama si cambio el ancho de la ventana, en ese
  // caso recalculo la paginacion

  updateDimensions() {
    // recuento la paginacion
    const { maxXpage, totalPages } = this.calcPaginacion();

    if (this.props.totalPages !== totalPages) {
      this.props.changeStore({
        maxXpage,
        totalPages,
        currentPage: 1
      });
    }
  }

  //////////////////
  //desplazar()
  /////////////////
  //1-si el desplazamiento esta activado y no es una pantalla de celular
  // avanzo a la siguiente pagina o la primera dependiendo de en cual
  //este actualmente
  //2-finalmente vuelvo a llamar a la misma funcion con setTimeout
  //luego de algunos segundos para que siga avanzando automaticamente

  desplazar(first) {
    // si estoy reproduciendo y la pantalla es mayor a celular inicio la funcion
    if (this.props.recorriendo && window.innerWidth > 600) {
      // si es la primera vez que activo la funcion entonces no avanzo a la siguiente pagina
      if (!first) {
        // avanzo a a siguiente pagina, si estoy en la ultima entonces regreso a la primera
        if (this.props.currentPage < this.props.totalPages) {
          this.props.changeStore({
            currentPage: this.props.currentPage + 1
          });
        } else {
          this.props.changeStore({
            currentPage: 1
          });
        }
      }
      // espero el tiempo que determina el usuario por pagina, y vuelvo a ejecutar
      // la misma funcion
      var self = this;
      this.timeout2 = setTimeout(() => {
        self.desplazar(false);
      }, this.props.timeXpage * 1000);
    } else {
      // si no cumplo la primera condicion entonces detengo la reproduccion

      this.props.changeStore({
        recorriendo: false,
        timer: null
      });
    }
  }

  // cambiar tiempo que se muestra cada pagina
  changeTimeXpage(seconds) {
    this.props.changeStore({
      timeXpage: seconds
    });
  }

  render() {
    // si ya cargaron los datos muestro todo en pantalla
    if (
      this.props.equipos.length > 0 &&
      this.props.currentSector !== undefined
    ) {
      const { coincidencias, coincidenciaTipo } = this.props;

      // calculo el tiempo que tomaria avanzar de un sector al otro para
      // ponerlo dentro de la funcion reproducir
      var timer = this.props.totalPages * this.props.timeXpage;

      // muestro los equipos de acuerdo a sus datos

      var equipos = coincidencias.map((equipo, index) => {
        /////////////////////////////////////////////////////
        //hago el calculo para verificar si el equipo actual
        //que estoy procesando debe, o no mostrarse
        /////////////////////////////////////////////////////
        var oculto = true;
        const { currentPage, maxXpage } = this.props;

        if (
          this.props.recorriendo &&
          index + 1 > (currentPage - 1) * maxXpage &&
          index + 1 <= currentPage * maxXpage
        ) {
          oculto = false;
        }

        if (!this.props.recorriendo) {
          oculto = false;
        }

        // segun la clase del equipo retorno un tipo de equipo u otro
        switch (coincidenciaTipo[index]) {
          case "BOMBA_AUTO":
            return (
              <BombaConA
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "CLORO_AUTO":
            return (
              <Cloro
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );


          case "TANQUE_AUTO":
            return (
              <TanqueConA
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "PERFORACION":
            return (
              <PerforacionSinA
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "ENTRADA":
            return (
              <PuntoEntrada
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "SALIDA":
            return (
              <PuntoExportacion
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "MEDIO":
            return (
              <PuntoMedio
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          case "TANQUE":
            return (
              <Tanque
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );
          case "BOMBA":
            return (
              <Bomba
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );
          case "OTRO":
            return (
              <Otro
                data={equipo}
                index={index + 1}
                key={index}
                hidden={oculto}
              />
            );

          default:
            break;
        }

        return null;
      });



      // retornar contenido
      return (
        <div className="SensorContainer">
          {/* el componente controls maneja la visualizacion y el tiempo por pagina */}
          <Controls
            changeTimeXpage={this.changeTimeXpage.bind(this)}
            reproducir={this.reproducir.bind(this, timer)}
            pausar={this.pausar.bind(this)}
          />
          {/* cartel de error */}
          <Error error={this.props.errors} date={this.props.lastUpdate} />

          {/* contenedor */}
          <div className="container-fluid">
            <div className="sensores">
              {/*div que contiene los equipos*/}
              {coincidencias.length < 1 ? <BusquedaListado /> : null}
              <div className="row">{equipos}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// guardo los datos del store en props
const mapStateToProps = state => ({
  equipos: state.sector.dataSectores,
  currentSector: state.sector.currentSector,
  errors: state.sector.errors,
  lastUpdate: state.sector.lastUpdate,
  tipo: state.sector.tipo,
  recorriendo: state.sector.recorriendo,
  timer: state.sector.timer,
  currentPage: state.sector.currentPage,
  maxXpage: state.sector.maxXpage,
  totalPages: state.sector.totalPages,
  timeXpage: state.sector.timeXpage,
  coincidencias: state.sector.coincidencias,
  coincidenciaTipo: state.sector.coincidenciaTipo
});

export default connect(
  mapStateToProps,
  {
    getNextSector,
    getPrevSector,
    changeStore
  }
)(EquipoContainer);
