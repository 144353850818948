import React, { Component } from "react";

export class Modal extends Component {
  render() {
    return (
      <div className="modaldelete">
        <div className="overlay" />
        <div className="contenedor">
          <p>
            ¿Está seguro que desea eliminar el sector?, esta acción no se puede
            deshacer.
          </p>
          <div className="botones">
            <button onClick={this.props.delete.bind(this)}>Confirmar</button>
            <button onClick={this.props.cancelar.bind(this)}>Cancelar</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
