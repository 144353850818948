import {
  GET_SECTORES,
  GET_NEXT_SECTOR,
  ERROR_REQUEST,
  GET_PREV_SECTOR,
  CHANGE_STORE_TV
} from "../actions/types";

import { formatedDate } from "../components/Funciones";

////////////////////////////////////
////Estado inicial
////////////////////////////////////

///////////
//sectores:
//////////
// el objeto con el array de sectores, lo tomo de localStorage por
//defecto en caso de que no haya conexion, en caso de poder conectarme
//lo sustituyo por la informacion nueva de la base.

////////////////
//currentSector:
////////////////
//sector actual que recorro en la visualizacion, por defecto el primero

/////////
//errors:
////////
//booleano, se convierte en true en caso de no poder conectar con la base de datos
//y suelta un error

/////////////
//lastupdate:
////////////
//ultima fecha donde se conecto con la base de datos, se actualiza
//cada vez que se logra hacer la conexion

//////////////
//connected:
//////////////
//esta variable indica si se pudo conectar al menos una vez con el servidor
// de esta manera sabemos si hay datos guardados en localStorage o no
// a la hora de mostrar un error o mostrar los datos guardados
var storage = window.localStorage;

const initialState = {
  sectores: storage.Sectores !== undefined ? JSON.parse(storage.Sectores) : {},
  currentSector: 0,
  errors: false,
  lastUpdate: storage.LastUpdate !== undefined ? storage.LastUpdate : "",
  tipo: storage.tipo !== undefined ? JSON.parse(storage.tipo) : [],
  connected: storage.Sectores !== undefined ? true : false,
  grupoSectores:
    storage.grupoSectores !== undefined
      ? JSON.parse(storage.grupoSectores)
      : [],
  dataSectores:
    storage.dataSectores !== undefined ? JSON.parse(storage.dataSectores) : [],
  currentPoint: null,
  recorriendo: false,
  timer: null,
  currentPage: 1,
  maxXpage: 1,
  totalPages: 1,
  timeXpage: 7,
  lastRegion: storage.lastRegion !== undefined ? storage.lastRegion : "",
  coincidencias:
    storage.dataSectores !== undefined
      ? JSON.parse(storage.dataSectores)[0]
      : [],
  busqueda: "",
  coincidenciaTipo:
    storage.tipo !== undefined ? JSON.parse(storage.tipo)[0] : []
};

////////////////////////////
//funciones de los sectores
///////////////////////////

//////////////
//GET_SECTORES
/////////////
// la funcion se detalla en /actions/sectoresActions.js
//hace la request a la base de datos, si tiene exito sustituye el state
// sectores, de lo contrario llama a la funcion ERROR_REQUEST

//////////////////
//GET_NEXT_SECTOR
/////////////////
//Cambia el state currentSector al siguiente,
// en caso de estar en el maximo vuelve al 0

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SECTORES:
      ////////////////////////////////////////////////
      //traigo el sector seleccionado con el id actual,
      //formateo la fecha actual para guardarla como ultima actualizacion
      ////////////////////////////////////////////////
      const { formateada } = formatedDate();

      if (
        window.localStorage.lastRegion &&
        window.localStorage.lastRegion !== action.payload.region
      ) {
        localStorage.clear();
      }

      const {
        respuesta,
        grupoSectores,
        sectores,
        tipoEquipo,
        region
      } = action.payload;

      //////////////////////////////////////
      // guardo los valores en localstorage
      /////////////////////////////////////
      var nombres = "Sectores, grupoSectores, dataSectores, tipo, LastUpdate, lastRegion".split(
        ", "
      );
      var variables = [
        respuesta,
        grupoSectores,
        sectores,
        tipoEquipo,
        formateada,
        region
      ];
      nombres.forEach((nombre, i) => {
        window.localStorage.setItem(
          nombre,
          i > 3 ? variables[i] : JSON.stringify(variables[i])
        );
      });

      //actualizo el state
      return {
        ...state,
        sectores: respuesta,
        lastUpdate: formateada,
        errors: false,
        connected: true,
        currentSector:
          state.grupoSectores.length !== grupoSectores.length
            ? 0
            : state.currentSector,
        grupoSectores: grupoSectores,
        dataSectores: sectores,
        tipo: tipoEquipo,
        lastRegion: region,
        coincidencias: sectores[state.currentSector],
        coincidenciaTipo: tipoEquipo[state.currentSector]
      };

    case GET_NEXT_SECTOR:
      //cambio el sector actual al siguiente o al primero si llegue al final
      var next = 1;
      if (state.currentSector < state.grupoSectores.length - 1) {
        next = state.currentSector + 1;
      } else {
        next = 0;
      }

      return {
        ...state,
        currentSector: next
      };

    case GET_PREV_SECTOR:
      //cambio el sector actual al anterior o al ultimo si llegue al final
      var prev;
      if (state.currentSector - 1 >= 0) {
        prev = state.currentSector - 1;
      } else {
        prev = state.grupoSectores.length - 1;
      }

      return {
        ...state,
        currentSector: prev
      };
    case ERROR_REQUEST:
      if (
        window.localStorage.lastRegion &&
        window.localStorage.lastRegion !== action.payload
      ) {
        localStorage.clear();
      }
      // si hay un error cambio el state a error
      return {
        ...state,
        errors: true
      };

    case CHANGE_STORE_TV:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
}
