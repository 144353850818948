import React, { Component } from "react";
import "../css/navbar.css";
import pajaro from "../img/pajaro.png";
import { Link } from "react-router-dom";
import Ayuda from "./Ayuda";

export class Navbar extends Component {
  state = {
    sectores: false,
    ayuda: false
  };

  toggle(variable) {
    var enlaces = ["sectores"];

    enlaces.forEach(enlace => {
      this.setState({
        [enlace]: false
      });
    });

    this.setState({
      [variable]: !this.state[variable]
    });
  }

  // reclamo(e) {
  //   var ventana = window.open(
  //     "/reclamo",
  //     "Reclamo",
  //     "menubar=no, title=no, location=no, width=600, height=800"
  //   );
  // }

  ayuda() {
    if (this.state.visible) {
      this.toggle("visible");
    }
    this.setState({
      ayuda: !this.state.ayuda
    });
  }

  render() {
    const { ayuda } = this.state;
    return (
      <div className="navbar">
        {ayuda ? <Ayuda toggle={this.ayuda.bind(this)} /> : null}
        <div className="navContainer">
          <div className="toggle" onClick={this.toggle.bind(this, "visible")}>
            <i className="fas fa-bars" />
          </div>
          <div className="main">
            <a
              href="http://www.spymovil.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="logo">
                <div className="spy">
                  <span>SPYMOVIL</span>
                </div>
                <div className="sub">Ingeniería industrial</div>
                <img src={pajaro} alt="Logo spymovil" />
              </div>
            </a>
            <div className="links">
              <Link to="/online" className="link">
                <span className="title">Listado</span>
              </Link>
              <div className="link">
                <span className="title">Sectores</span>
                <div className="sublinks">
                  <Link to="/">Visualización</Link>
                  {/* <Link to="/configuracion">Configuración</Link> */}
                </div>
              </div>
              <div className="link" onClick={this.ayuda.bind(this)}>
                <span className="title">Ayuda</span>
              </div>
            </div>
          </div>
          <div
            className="linkContainer"
            style={{
              height: this.state.visible ? "calc(100vh - 50px)" : "0vh"
            }}
          >
            <div className="dropdown">
              <div className="title">
                <Link to="/online" onClick={this.toggle.bind(this, "visible")}>
                  Listado{" "}
                  <i className="fas fa-caret-down" style={{ opacity: "0" }} />
                </Link>
              </div>
            </div>
            <div className="dropdown">
              <div
                className="title"
                onClick={this.toggle.bind(this, "sectores")}
              >
                Sectores{" "}
                <i
                  className="fas fa-caret-down"
                  style={{
                    transform: this.state.sectores ? "rotate(180deg)" : ""
                  }}
                />
              </div>
              <div
                className="content"
                style={{
                  height: this.state.sectores ? `${41.5 * 2}px` : "0"
                }}
              >
                <Link to="/" onClick={this.toggle.bind(this, "visible")}>
                  Visualización
                </Link>
                <Link
                  to="/configuracion"
                  onClick={this.toggle.bind(this, "visible")}
                >
                  Configuración
                </Link>
              </div>
            </div>
            <div className="dropdown">
              <div className="title" onClick={this.ayuda.bind(this)}>
                Ayuda{" "}
                <i className="fas fa-caret-down" style={{ opacity: "0" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
