import React, { Component } from "react";
import Map from "../header/Map";
import axios from "axios";

export class CrearPresionesCaudales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      ext: 0,
      nroConex: 0,
      exp: 0,
      fci: 0,
      yellow: 0,
      green: 0,
      pmedio: [],
      pEntrada: [],
      pSalida: [],
      pCritico: [],
      pOtros: [],
      equipos: null,
      errorCrearSector: false,
      puntosMapa: [],
      equiposMapa: [],
      mt3xcon: 0,
      ranc: 0,
      mensajeError: ""
    };
  }

  componentDidMount() {
    // guardo los props como el state para poder editarlo
    this.setState({
      equipos: this.props.equipos
    });
  }

  handleInputs(e) {
    // cambio el valor del elemento basado en el name del input
    var valor = e.target.value;
    var variable = e.target.name;
    this.setState({
      [variable]: valor
    });
  }

  addPunto(tipo) {
    // agrego un punto al tipo correspondiente de puntos
    var puntos = this.state[tipo];
    puntos.push("");
    this.setState({
      [tipo]: puntos
    });
  }

  deleteSelect(index, tipo) {
    // quito el elemento que corresponda de acuerdo al tipo y la posicion
    // a continuacion uso la funcion cargarpuntos para reorganizar los datos
    // y que el mapa se actualice
    var puntos = this.state[tipo];
    this.setState(
      {
        [tipo]: puntos.filter((punto, i) => {
          return i !== index;
        })
      },
      () => {
        this.setState({
          puntosMapa: this.cargarPuntos().puntosMapa,
          equiposMapa: this.cargarPuntos().equiposMapa
        });
      }
    );
  }

  sendNewSector() {
    var variables = "nombre, pmedio, pEntrada, pSalida, pCritico, pOtros, mt3xcon, ranc".split(
      ", "
    );
    // reviso que las variables no vengan vacias
    var error = false;
    variables.forEach(variable => {
      if (
        typeof this.state[variable] === "string" &&
        this.state[variable] === ""
      ) {
        error = true;
      } else if (typeof this.state[variable] === "object") {
        let keys = Object.keys(this.state[variable]);

        keys.forEach(key => {
          if (this.state[variable][key] === "") {
            error = true;
          }
        });
      }
    });

    // si hay error lo muestro
    if (error) {
      this.setState(
        {
          errorCrearSector: true,
          mensajeError:
            "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
        },
        () => {
          document
            .querySelector(".parrafoerror")
            .scrollIntoView({ behavior: "smooth" });
        }
      );
    } else {
      const {
        nombre,
        ext,
        nroConex,
        exp,
        fci,
        yellow,
        green,
        pmedio,
        pEntrada,
        pSalida,
        pCritico,
        pOtros,
        mt3xcon,
        ranc
      } = this.state;

      // reviso si hay equipos repetidos
      Array.prototype.unique = (function(a) {
        return function() {
          return this.filter(a);
        };
      })(function(a, b, c) {
        return c.indexOf(a, b + 1) < 0;
      });

      var todosLosEquipos = [
        ...pmedio,
        ...pEntrada,
        ...pSalida,
        ...pCritico,
        ...pOtros
      ];

      if (todosLosEquipos.length !== todosLosEquipos.unique().length) {
        this.setState(
          {
            errorCrearSector: true,
            mensajeError:
              "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
          },
          () => {
            document
              .querySelector(".parrafoerror")
              .scrollIntoView({ behavior: "smooth" });
          }
        );
        return null;
      }

      // guardo los equipos como objetos con su tipo y pkmonitoreo
      var equipos = [];
      pmedio.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "MEDIO" });
      });
      pEntrada.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "ENTRADA" });
      });
      pSalida.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "SALIDA" });
      });
      pCritico.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "CRITICO" });
      });
      pOtros.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "OTRO" });
      });

      // pongo el formato correcto antes de enviar el dato
      var sector = {
        nombre,
        extension: parseFloat(ext),
        nroconexiones: parseFloat(nroConex),
        expfugas: parseFloat(exp),
        fci: parseFloat(fci),
        caudalnocturnometa: parseFloat(green),
        caudalnocturnometa2: parseFloat(yellow),
        mt3xcon: parseFloat(mt3xcon),
        equipos,
        action: "INSERT",
        tiposector: "PQ",
        ranc
      };
      this.setState({
        errorCrearSector: false
      });

      // convierto el objeto en una string para enviarlo por get
      var texto = JSON.stringify(sector);
      axios
        .get(`${this.props.url}?data=${texto}`)
        .then(res => {
          if (res.data.response === "OK") {
            this.props.toggle("");
            this.props.cargar();
            this.props.popup(true, true, "El sector ha sido creado con éxito");
          } else if (res.data.response === "ERROR_RANC") {
            this.setState(
              {
                errorCrearSector: true,
                mensajeError:
                  "El código Ranc ingresado ya está en uso por otro sector."
              },
              () => {
                document
                  .querySelector(".parrafoerror")
                  .scrollIntoView({ behavior: "smooth" });
              }
            );
          } else {
            this.props.cargar();
            this.props.popup(true, false, res.data.response);
          }
        })
        .catch(err => {
          this.props.cargar();
          this.props.popup(
            true,
            false,
            "Hubo un error, compruebe la conexión intente nuevamente."
          );
        });
    }
  }

  handleSelect(indice, variable, e) {
    var puntos = this.state[variable];
    puntos[indice] = e.target.value;
    // hago el cambio en el state segun el tipo de punto y su posicion
    // luego actualizo los puntos del mapa
    this.setState(
      {
        [variable]: puntos
      },
      () => {
        this.setState({
          puntosMapa: this.cargarPuntos().puntosMapa,
          equiposMapa: this.cargarPuntos().equiposMapa
        });
      }
    );
  }

  cargarPuntos() {
    const { pmedio, pEntrada, pSalida, pCritico, pOtros } = this.state;
    var equiposEstado = [
      ...pmedio,
      ...pEntrada,
      ...pSalida,
      ...pCritico,
      ...pOtros
    ];

    var equiposMapa = [];
    var puntosMapa = [];
    var equipos = this.props.equipos;
    // por cada equipo asignado al sector
    // detecto cual es el tipo de equipo, si bomba tanque, etc y lo
    // guardo en la variable equipotipo
    equiposEstado.forEach(key => {
      if (key !== "") {
        var coincidencias = "MEDIO, ENTRADA, SALIDA, CRITICO, OTRO".split(", ");
        var variables = "pmedio, pEntrada, pSalida, pCritico, pOtros".split(
          ", "
        );
        var equipotipo = "";

        variables.forEach((variable, i) => {
          if (this.state[variable].indexOf(key) !== -1) {
            equipotipo = coincidencias[i];
          }
        });

        var tipo = "";

        if (equipotipo === "ENTRADA" || equipotipo === "SALIDA") {
          if (equipos[key].macro === 1) {
            tipo = "caudalimetro";
          } else if (equipos[key].reguladora === 1) {
            tipo = "reguladora";
          }
        }
        if (equipotipo === "OTRO") {
          if (equipos[key].reguladora === 1) {
            tipo = "reguladora";
          }
        }
        if (equipotipo === "CRITICO") {
          if (equipos[key].reguladora === 1) {
            tipo = "reguladora";
          } else {
            tipo = "punto_medio";
          }
        }

        if (equipotipo === "MEDIO") {
          tipo = "punto_medio";
        }

        // por cada equipo que tenga un dlgid seleccionado, agrego el punto
        // y el equipo para luego devolver esas variables y usarlas en el mapa
        puntosMapa.push({
          lat: equipos[key].latitud,
          lng: equipos[key].longitud,
          tipo
        });
        equiposMapa.push({ [key]: equipos[key] });
      }
    });

    return {
      puntosMapa,
      equiposMapa
    };
  }

  render() {
    const {
      yellow,
      green,
      pmedio,
      pEntrada,
      pSalida,
      errorCrearSector,
      pCritico,
      pOtros,
      puntosMapa,
      equiposMapa,
      mensajeError
    } = this.state;

    // agrego los campos de texto comunes
    var inputs = [
      { type: "text", name: "nombre", placeholder: "Nombre" },
      { type: "number", name: "ext", placeholder: "Extension de red" },
      { type: "number", name: "nroConex", placeholder: "Nro. conexiones" },
      { type: "number", name: "exp", placeholder: "Exp. fugas" },
      { type: "number", name: "fci", placeholder: "Fci" },
      { type: "number", name: "mt3xcon", placeholder: "Mt3xcon" },
      { type: "number", name: "ranc", placeholder: "Ranc" }
    ];

    if (!this.state.equipos) {
      return null;
    }
    var keys = Object.keys(this.state.equipos);
    var equipos = [];

    // agrego los equipos disponibles a una variable para que aparezcan
    // como opciones en los select
    keys.forEach((key, i) => {
      equipos.push(this.state.equipos[key]);
      equipos[i].pkmonitoreo = key;
    });

    return (
      <div id="crearSector" className="inputs" data-simplebar>
        <p
          className="parrafoerror"
          style={{ display: errorCrearSector ? "block" : "none" }}
        >
          {mensajeError}
        </p>{" "}
        <div className="row editar">
          <div className="col-md-6">
            <h3>Datos del sector</h3>
            {inputs.map((input, i) => {
              return (
                <div key={i}>
                  <label>{input.placeholder}</label>
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    onChange={this.handleInputs.bind(this)}
                    value={this.state[input.name]}
                    min="0"
                  />
                </div>
              );
            })}
            <div className="caudal">
              <label>Caudal nocturno meta</label>
              <div className="valores">
                <div className="row">
                  <div className="col-2">
                    <label className="green" htmlFor="green" />
                    <label className="yellow" htmlFor="yellow" />
                    <label className="red" htmlFor="red" />
                  </div>
                  <div className="col-10">
                    <div className="data">
                      <span>&lt; =</span>{" "}
                      <input
                        type="number"
                        id="green"
                        name="green"
                        onChange={this.handleInputs.bind(this)}
                        value={green}
                      />
                    </div>
                    <div className="data">
                      <span>&lt; =</span>{" "}
                      <input
                        type="number"
                        id="yellow"
                        name="yellow"
                        onChange={this.handleInputs.bind(this)}
                        value={yellow}
                      />
                    </div>
                    <div className="data">
                      <span>> </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 puntos">
            <h3>Puntos del sector</h3>
            <div className="medio">
              <label>
                Punto medio{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pmedio")}
                />
              </label>
              {pmedio.map((punto, index) => {
                let filtro = equipos.filter((equipo, i) => {
                  if (equipo.reguladora !== 1 && equipo.macro !== -1) {
                    return equipo;
                  }
                });
                return (
                  <div key={index}>
                    <span className="punto">M{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pmedio")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pmedio")}
                    />
                  </div>
                );
              })}
              {pmedio.length < 1 ? (
                <p className="mensaje">No hay puntos medios</p>
              ) : null}
            </div>
            <div className="entrada">
              <label>
                Puntos de entrada{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pEntrada")}
                />
              </label>
              {pEntrada.map((punto, index) => {
                let filtro = equipos.filter((equipo, i) => {
                  if (equipo.macro === 1) {
                    return equipo;
                  }
                });
                return (
                  <div key={index}>
                    <span className="punto">E{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pEntrada")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pEntrada")}
                    />
                  </div>
                );
              })}
              {pEntrada.length < 1 ? (
                <p className="mensaje">No hay puntos de entrada</p>
              ) : null}
            </div>
            <div className="salida">
              <label>
                Puntos de salida{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pSalida")}
                />
              </label>
              {pSalida.map((punto, index) => {
                let filtro = equipos.filter((equipo, i) => {
                  if (equipo.macro === 1) {
                    return equipo;
                  }
                });
                return (
                  <div key={index}>
                    <span className="punto">S{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pSalida")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pSalida")}
                    />
                  </div>
                );
              })}
              {pSalida.length < 1 ? (
                <p className="mensaje">No hay puntos de salida</p>
              ) : null}
            </div>
            <div className="critico">
              <label>
                Puntos críticos{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pCritico")}
                />
              </label>
              {pCritico.map((punto, index) => {
                return (
                  <div key={index}>
                    <span className="punto">C{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pCritico")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {equipos
                        ? equipos.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pCritico")}
                    />
                  </div>
                );
              })}
              {pCritico.length < 1 ? (
                <p className="mensaje">No hay puntos críticos</p>
              ) : null}
            </div>
            <div className="otros">
              <label>
                Otros puntos{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pOtros")}
                />
              </label>
              {pOtros.map((punto, index) => {
                return (
                  <div key={index}>
                    <span className="punto">O{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pOtros")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {equipos
                        ? equipos.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pOtros")}
                    />
                  </div>
                );
              })}
              {pOtros.length < 1 ? (
                <p className="mensaje">No hay puntos de otras categorías</p>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <div className="botonesConf">
              <button onClick={this.sendNewSector.bind(this)}>Confirmar</button>
            </div>
          </div>
        </div>
        <Map equipos={equiposMapa} puntos={puntosMapa} />
      </div>
    );
  }
}

export default CrearPresionesCaudales;
