import React, { Component } from "react";
import pajaro from "../img/pajaro.png";

export class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="row">
          <div className="col-12 col-md-3">
            <h4>
              <a
                href="http://www.spymovil.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="logo">
                  <div className="spy">
                    <span>SPYMOVIL</span>
                  </div>
                  <div className="sub">Ingeniería industrial</div>
                  <img src={pajaro} alt="Logo spymovil" />
                </div>
              </a>
            </h4>
          </div>
          <div className="col-sm-4 col-md-3">
            <p>
              Teléfono: <small>(598) 2925 1081 / 2929 8806</small>
            </p>
          </div>
          <div className="col-sm-4 col-md-3">
            <p>
              Dirección:{" "}
              <small>Minas 2045 C.P. 11.800, Montevideo - Uruguay</small>
            </p>
          </div>
          <div className="col-sm-4 col-md-3">
            <p>
              Email: <small>ventas@spymovil.com</small>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
