import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { changeStore } from "../../actions/sectoresActions";
import { checkGrey, formatDate, formatNumber } from "../Funciones";
import Bateria from "../visOnline/tabla/Bateria";
import Sqe from "../visOnline/tabla/Sqe";
import AlarmaIcon from "./AlarmaIcon";

export class Cloro extends Component {
  mostrarPunto() {
    var id = Object.keys(this.props.data)[0];
    id = this.props.data[Object.keys(this.props.data)[0]].dlgid;
    this.props.changeStore({ currentPoint: id });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    var pkmonitoreo = Object.keys(this.props.data)[0];
    var {
      CL_MAX_ALARM,
      CL_MIN_ALARM,
      CL_ALARM_CONTROL,
      CL_ALARM_STATE,
      CL_LIBRE,
      PUMP_DOS_STATE,
      T_EXEC_DOS_PUMP,
      tx_state,
      dlgid,
    } = this.props.data[pkmonitoreo];

    CL_LIBRE = parseFloat(CL_LIBRE);
    CL_MAX_ALARM = parseFloat(CL_MAX_ALARM);
    CL_MIN_ALARM = parseFloat(CL_MIN_ALARM);
    CL_ALARM_STATE = CL_ALARM_STATE === "NO" ? "OK" : "ERROR";

    var id = dlgid;
    // formatear fecha
    var fechadata = formatDate(this.props.data[pkmonitoreo].fechadata);
    if (fechadata === -1) {
      tx_state = "ERROR";
      fechadata = "01/01/2000 00:00:00";
    }

    // Probando si la fecha da error
    try {
      let fechaPrueba = fechadata.substring(0, 10);
      let fechaPrueba2 = fechadata.substring(10);
    } catch (error) {
      console.log("Error de fecha en:", dlgid);
    }

    // chequear si es de ose o spymovil
    const { sinInstalar, poseedor, ubicacion } = checkGrey(
      this.props.data[pkmonitoreo].ubicacion
    );

    var coincidenciaTipo = "CLORO_AUTO";
    var CL_ALARM = "OK";
    if (CL_LIBRE < CL_MIN_ALARM) {
      CL_ALARM = "WARNING";
    }
    if (CL_LIBRE > CL_MAX_ALARM) {
      CL_ALARM = "WARNING";
    }
    var props = { CL_ALARM, coincidenciaTipo };
    const alarma = AlarmaIcon(props);

    // datos bateria
    var icon, tipo;

    // retornar contenido
    if (tx_state === "ERROR") {
      return (
        <div
          className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${
            this.props.hidden ? "ocultar" : ""
          }`}
        >
          <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
            <div className="header">
              <div className="header-text">
                <div className="header-id">
                  {id}{" "}
                  <div className="botones">
                    <button
                      onClick={this.mostrarPunto.bind(this)}
                      title="Ver en el mapa"
                    >
                      <i className="fas fa-map-marked-alt" />
                    </button>
                    <Link
                      to={"/detalle/" + pkmonitoreo}
                      target="_blank"
                      title="Detalles"
                    >
                      <i className="fas fa-info" />
                    </Link>
                  </div>
                </div>
                {sinInstalar ? (
                  <div className="header-address">
                    <div
                      style={{
                        marginBottom: ".2em",
                      }}
                    >
                      {ubicacion}
                    </div>
                    <div
                      style={{
                        background: "rgb(48, 48, 48)",
                        border: "2px solid white",
                        padding: ".3em ",
                        width: "100%",
                        fontSize: "1.4em",
                        textAlign: "center",
                      }}
                    >
                      Sin instalar
                    </div>
                  </div>
                ) : (
                  <div className="header-address">{ubicacion}</div>
                )}
                {sinInstalar ? (
                  <div
                    className="header-type "
                    style={{
                      fontSize: "1.5em",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    {poseedor}
                  </div>
                ) : (
                  <React.Fragment>
                    {alarma !== "" ? (
                      alarma
                    ) : (
                      <div className="header-type ">CLORO</div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div
              className="content"
              style={{ display: sinInstalar ? "none" : "block" }}
            >
              {/* presion */}
              <div className={`content-data full ${tx_state}`}>
                <div className="data-section">
                  <div className="data-value">
                    <h3>Error</h3>
                  </div>
                </div>
              </div>

              <div className={`content-data OK`}>
                <div className="data-section">
                  <div className="data-name">Fecha</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`sensor col-sm-12 col-md-6 col-lg-4 col-xl-3 ${
          this.props.hidden ? "ocultar" : ""
        }`}
      >
        <div className={`data-sensor tanque ${sinInstalar ? "grey" : null}`}>
          <div className="header">
            <div className="header-text">
              <div className="header-id">
                {id}{" "}
                <div className="botones">
                  <button
                    onClick={this.mostrarPunto.bind(this)}
                    title="Ver en el mapa"
                  >
                    <i className="fas fa-map-marked-alt" />
                  </button>
                  <Link
                    to={"/detalle/" + pkmonitoreo}
                    target="_blank"
                    title="Detalles"
                  >
                    <i className="fas fa-info" />
                  </Link>
                </div>
              </div>
              {sinInstalar ? (
                <div className="header-address">
                  <div
                    style={{
                      marginBottom: ".2em",
                    }}
                  >
                    {ubicacion}
                  </div>
                  <div
                    style={{
                      background: "rgb(48, 48, 48)",
                      border: "2px solid white",
                      padding: ".3em ",
                      width: "100%",
                      fontSize: "1.4em",
                      textAlign: "center",
                    }}
                  >
                    Sin instalar
                  </div>
                </div>
              ) : (
                <div className="header-address">{ubicacion}</div>
              )}
              {sinInstalar ? (
                <div
                  className="header-type "
                  style={{
                    fontSize: "1.5em",
                    display: "inline-block",
                    width: "100%",
                  }}
                >
                  {poseedor}
                </div>
              ) : (
                <React.Fragment>
                  {alarma !== "" ? (
                    alarma
                  ) : (
                    <div className="header-type ">CLORO</div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          <div
            className="content"
            style={{ display: sinInstalar ? "none" : "block" }}
          >
            {CL_LIBRE || CL_LIBRE === 0 ? (
              <div className={`content-data ${CL_ALARM}`}>
                <div className="data-section">
                  <div className="data-name">Cloro</div>
                  <div className="data-value"> </div>
                </div>
                <div className="data-section">
                  <div className="data-name"></div>
                  <div className="data-value">
                    {formatNumber.new(CL_LIBRE.toFixed(2))}{" "}
                    <div className="data-unit">ppm</div>
                  </div>
                </div>
              </div>
            ) : null}

            {/* Deposito */}
            {CL_ALARM_CONTROL ? (
              <div className={`content-data ${CL_ALARM_STATE}`}>
                <div className="data-section">
                  <div className="data-name-deposito ">Control de alarma</div>
                  <div className="data-value"> </div>
                </div>
                <div className="data-section">
                  <div className="data-name"></div>
                  <div className="data-value">
                    {CL_ALARM_CONTROL === "NO" ? "Desactivado" : "Activo"}
                  </div>
                </div>
              </div>
            ) : null}
            {/* presion */}
            {PUMP_DOS_STATE ? (
              <div className={`content-data OK`}>
                {PUMP_DOS_STATE ? (
                  <div
                    className={`data-section ${
                      PUMP_DOS_STATE === "ON" ? "BOMBA" : "ERROR"
                    }`}
                  >
                    <div className="data-name">Estado</div>
                    <div className="data-value">{""}</div>
                  </div>
                ) : null}

                <div
                  className={`data-section ${
                    PUMP_DOS_STATE === "ON" ? "BOMBA" : "ERROR"
                  }`}
                >
                  <div className="data-name">{""}</div>
                  <div className="data-value">
                    {PUMP_DOS_STATE === "OFF"
                      ? "Apagada"
                      : PUMP_DOS_STATE === "ON"
                      ? "Encendida"
                      : PUMP_DOS_STATE === "ERROR"
                      ? "Error"
                      : ""}
                  </div>
                </div>
              </div>
            ) : null}
            {/* caudal */}

            {tx_state === "WARNING" ? (
              <div className={`content-data ${tx_state}`}>
                <div className="data-section">
                  <div className="data-name">Error</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`content-data OK`}>
                <div className="data-section">
                  <div className="data-name">Fecha</div>
                </div>
                <div className="data-section">
                  <div className="data-value">
                    <span className="fecha">
                      {fechadata.substring(0, 10)}
                      <br />
                      {fechadata.substring(10)}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { changeStore })(Cloro);
