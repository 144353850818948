import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

var url = document.getElementById("url").value;

// var url = 'http://www.spymovil.com/OSEAPI/api/sectores/8/';
// var url = 'http://www.spymovil.com/OSEAPIV2/api/sectores/16/';

if (url != "") {
  ReactDOM.render(<App region={url} />, document.getElementById("root"));
}

serviceWorker.unregister();
