import React, { Component } from "react";
import Map from "../header/Map";
import axios from "axios";
import Modal from "./Modal";

export class EditarAguaElevada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectorActual: "",
      sectores: null,
      errorEditarSector: false,
      equipos: null,
      puntos: [],
      equiposMapa: [],
      mensajeError: "",
      modalVisible: false
    };
  }
  componentDidMount() {
    // formateo y guardo la informacion en el state
    this.guardarEstado();
  }

  componentDidUpdate(prevProps, prevState) {
    // vuelvo a formatear si se cambia de sector
    if (
      prevProps.sectores !== this.props.sectores ||
      prevState.sectorActual !== this.state.sectorActual
    ) {
      this.guardarEstado();
      this.setState({
        errorEditarSector: false,
        modalVisible: false
      });
    }
  }

  guardarEstado() {
    // formateo los datos de forma que me sea mas comoda de manejar
    var keysE = Object.keys(this.props.equipos);
    var keysS = Object.keys(this.props.sectores);
    var equipos = [];
    var sectores = [];

    keysE.forEach((key, i) => {
      equipos.push(this.props.equipos[key]);
      equipos[i].pkmonitoreo = key;
    });
    keysS.forEach((key, i) => {
      sectores.push(this.props.sectores[key]);
      sectores[i].identificador = key;
    });

    this.setState({
      sectores,
      equipos
    });
  }

  handleSectorInputs(e) {
    // edito el valor de la variable segun el name del input
    var valor = e.target.value;
    var variable = e.target.name;
    var sectores = [...this.state.sectores];
    // ranc tiene que ser mayor a 0
    if (variable === "ranc" && parseFloat(valor) < 0) {
      return null;
    }
    sectores[this.state.sectorActual][variable] = valor;

    this.setState({
      sectores
    });
  }

  cargarPuntos(sectorActual) {
    if (sectorActual == "") {
      sectorActual = 0;
    }
    var equiposActual = [...this.state.sectores[sectorActual].equipos];
    var puntos = [];
    var equiposMapa = [];
    var keys = Object.keys(this.props.equipos);

    // guardo los datos de los puntos y equipos para usar en el mapa
    equiposActual.forEach(equipo => {
      keys.forEach((key, i) => {
        if (equipo.pkmonitoreo !== "" && key === equipo.pkmonitoreo) {
          equiposMapa.push({ [equipo.pkmonitoreo]: this.props.equipos[key] });
          var tipo = "";
          switch (equipo.tipo) {
            case "BOMBA":
              tipo = "bomba";
              break;
            case "TANQUE":
              tipo = "tanque";
              break;
            case "OTRO":
              tipo = "otro";
              break;
            default:
              break;
          }

          puntos.push({
            lat: this.props.equipos[key].latitud,
            lng: this.props.equipos[key].longitud,
            tipo
          });
        }
      });
    });

    return {
      puntos,
      equiposMapa
    };
  }

  changeSector(e) {
    var sectorActual = e.target.value;
    // cambio el sector actual por el seleccionado, actualizo los
    // puntos del mapa
    this.setState({
      sectorActual,
      puntos: this.cargarPuntos(sectorActual).puntos,
      equiposMapa: this.cargarPuntos(sectorActual).equiposMapa
    });
  }

  handleChangeSelect(index, e) {
    var valor = e.target.value;
    var sectores = [...this.state.sectores];
    var sectorActual = this.state.sectorActual;
    sectores[this.state.sectorActual].equipos[index].pkmonitoreo = valor;
    // cambio el dato seleccionado y actualizo los puntos
    this.setState(
      {
        sectores
      },
      () => {
        this.setState({
          puntos: this.cargarPuntos(sectorActual).puntos,
          equiposMapa: this.cargarPuntos(sectorActual).equiposMapa
        });
      }
    );
  }

  addEquipo(tipo) {
    var sectores = [...this.state.sectores];
    var equipos = [
      ...sectores[this.state.sectorActual].equipos,
      { tipo, pkmonitoreo: "" }
    ];
    // agrego un equipo nuevo
    sectores[this.state.sectorActual].equipos = equipos;
    this.setState({
      sectores
    });
  }

  deleteEquipo(index) {
    var sectores = [...this.state.sectores];
    var equiposTipo = [...sectores[this.state.sectorActual].equipos];
    const { sectorActual } = this.state;

    // quito el elemento segun su index, luego actualizo los puntos del mapa
    sectores[this.state.sectorActual].equipos = equiposTipo.filter(
      (equipo, i) => {
        return i !== index;
      }
    );
    this.setState(
      {
        sectores
      },
      () => {
        this.setState({
          puntos: this.cargarPuntos(sectorActual).puntos,
          equiposMapa: this.cargarPuntos(sectorActual).equiposMapa
        });
      }
    );
  }

  // confirmacion
  sendEditedSector() {
    const { sectores, sectorActual } = this.state;
    var error = false;

    // reviso puntos sin seleccionar y repetidos
    var equipos = [...sectores[sectorActual].equipos];
    var pkmonitoreos = [];
    equipos.forEach(equipo => {
      if (
        equipo.pkmonitoreo === "" ||
        pkmonitoreos.indexOf(equipo.pkmonitoreo) !== -1
      ) {
        error = true;
      } else {
        pkmonitoreos.push(equipo.pkmonitoreo);
      }
    });

    if (
      sectores[sectorActual].ranc === "" ||
      sectores[sectorActual].nombre === ""
    ) {
      error = true;
    }

    if (error) {
      this.setState(
        {
          errorEditarSector: true,
          mensajeError:
            "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
        },
        () => {
          document
            .querySelector(".parrafoerror")
            .scrollIntoView({ behavior: "smooth" });
        }
      );
      return null;
    } else {
      // formateo los datos para enviarlos
      var sectorCompleto = { ...sectores[sectorActual] };
      const { identificador, nombre, equipos, ranc } = sectorCompleto;
      var sectorFormateado = {
        equipos,
        nombre,
        action: "UPDATE",
        tiposector: "TQ",
        id: identificador,
        ranc
      };
    }

    this.setState({
      errorEditarSector: false,
      mensajeError:
        "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
    });

    // convierto el dato en string y lo envio
    var texto = JSON.stringify(sectorFormateado);

    axios
      .get(`${this.props.url}?data=${texto}`)
      .then(res => {
        if (res.data.response === "OK") {
          this.props.toggle("");
          this.props.cargar();
          this.props.popup(
            true,
            true,
            "Los cambios han sido guardados con éxito"
          );
        } else if (res.data.response === "ERROR_RANC") {
          this.setState({
            errorEditarSector: true,
            mensajeError:
              "El código Ranc ingresado ya está en uso por otro sector."
          });
        } else {
          this.props.cargar();
          this.props.popup(true, false, res.data.response);
        }
      })
      .catch(err => {
        this.props.cargar();
        this.props.popup(
          true,
          false,
          "Hubo un error, compruebe la conexión intente nuevamente."
        );
      });
  }

  deleteSector() {
    const { sectores, sectorActual } = this.state;

    var data = JSON.stringify({
      action: "DELETE",
      id: sectores[sectorActual].identificador,
      tiposector: "TQ"
    });

    this.toggleModal();

    axios
      .get(`${this.props.url}?data=${data}`)
      .then(res => {
        if (res.data.response === "OK") {
          this.props.toggle("");
          this.props.popup(
            true,
            true,
            `El sector ${sectores[sectorActual].nombre} se eliminó con éxito`
          );
          this.props.cargar();
        } else {
          this.props.cargar();
          this.props.popup(true, false, res.data.response);
        }
      })
      .catch(err => {
        this.props.cargar();
        this.props.popup(
          true,
          false,
          "Hubo un error al guardar los cambios, intente nuevamente."
        );
      });
  }

  toggleModal() {
    this.setState({
      modalVisible: !this.state.modalVisible
    });
  }

  recargar() {
    this.props.cargar();
    // reinicio la configuracion que llegaba del servidor
    setTimeout(() => {
      this.setState({
        puntos: this.cargarPuntos(this.state.sectorActual).puntos,
        equiposMapa: this.cargarPuntos(this.state.sectorActual).equiposMapa
      });
    }, 100);
  }

  render() {
    const {
      errorEditarSector,
      sectores,
      equipos,
      sectorActual,
      puntos,
      equiposMapa,
      mensajeError,
      modalVisible
    } = this.state;

    // inputs comunes
    var inputs = [
      { type: "text", name: "nombre", placeholder: "Nombre" },
      { type: "number", name: "ranc", placeholder: "Ranc" }
    ];

    if (!this.state.sectores || !this.state.equipos) {
      return null;
    }

    var contadores = [0, 0, 0];
    var puntosTipos = [[], [], []];
    var cant = [0, 0, 0];
    var tipos = ["TANQUE", "BOMBA", "OTRO"];
    // creo las variables para recorrerlas y asignarles puntos

    if (sectorActual !== "") {
      // reviso que clase de punto son
      tipos.forEach((tipo, i) => {
        sectores[sectorActual].equipos.forEach((equipo, k) => {
          if (equipo.tipo == tipo) {
            this.state.equipos.forEach(equiposState => {
              if (equiposState.pkmonitoreo === equipo.pkmonitoreo) {
                puntosTipos[i].push(equiposState);
              }
            });
          }
        });
        cant[i] = puntosTipos[i].length;
      });

      // si alguno de los puntos que no estan agregados cumplen los
      // parametros normales para ser esa clase de punto, los agrego

      const agregarPuntos = (i, equipo) => {
        var existencia = false;
        puntosTipos[i].forEach(punto => {
          if (punto && punto.pkmonitoreo === equipo.pkmonitoreo) {
            existencia = true;
          }
        });

        return existencia;
      };

      equipos.forEach((equipo, i) => {
        // agrego los tanques
        if (
          equipo.pkmonitoreo.substring(0, 3) === "TQ_" &&
          !agregarPuntos(0, equipo)
        ) {
          puntosTipos[0].push(equipo);
        }
        // bombas
        if (equipo.macro === 1 && !agregarPuntos(1, equipo)) {
          puntosTipos[1].push(equipo);
        }
        // otro
        if (
          equipo.macro !== 1 &&
          equipo.pkmonitoreo.substring(0, 2) !== "TQ" &&
          !agregarPuntos(2, equipo)
        ) {
          puntosTipos[2].push(equipo);
        }
      });
    }

    // filtro repetidos
    Array.prototype.unique = (function(a) {
      return function() {
        return this.filter(a);
      };
    })(function(a, b, c) {
      return c.indexOf(a, b + 1) < 0;
    });

    puntosTipos[0] = puntosTipos[0].unique();
    puntosTipos[1] = puntosTipos[1].unique();
    puntosTipos[2] = puntosTipos[2].unique();

    // filtro los sectores que son de agua elevada

    var sectoresFiltrados = [];
    var indicesFiltrados = [];
    sectores.forEach((sector, i) => {
      var pertenece = false;
      var inicio = sector.identificador.substring(0, 3);
      if (inicio === "TQ_") {
        pertenece = true;
      }
      if (pertenece) {
        sectoresFiltrados.push(sector);
        indicesFiltrados.push(i);
      }
    });

    // retorno formulario
    return (
      <React.Fragment>
        {modalVisible ? (
          <Modal
            cancelar={this.toggleModal.bind(this)}
            delete={this.deleteSector.bind(this)}
          />
        ) : null}
        <div id="editarSector" className="inputs" data-simplebar>
          <p
            className="parrafoerror"
            style={{ display: errorEditarSector ? "block" : "none" }}
          >
            {mensajeError}
          </p>
          <select
            className="sectorSelector"
            value={sectorActual}
            onChange={this.changeSector.bind(this)}
          >
            <option value="">Selecciona un sector</option>
            {sectoresFiltrados.map((sector, index) => {
              return (
                <option key={index} value={indicesFiltrados[index]}>
                  {sector.nombre}
                </option>
              );
            })}
          </select>
          {sectorActual !== "" && equipos ? (
            <div>
              <div className="row editar">
                <div className="col-md-6">
                  <h3>Datos del sector</h3>
                  {inputs.map((input, i) => {
                    return (
                      <div key={i}>
                        <label>{input.placeholder}</label>
                        <input
                          type={input.type}
                          placeholder={input.placeholder}
                          name={input.name}
                          onChange={this.handleSectorInputs.bind(this)}
                          value={sectores[sectorActual][input.name]}
                          min="0"
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-6 puntos">
                  <h3>Puntos del sector</h3>
                  <div className="medio">
                    <label>
                      Tanques{" "}
                      <i
                        className="fas fa-plus"
                        onClick={this.addEquipo.bind(this, "TANQUE")}
                      />
                    </label>
                    {sectores[sectorActual].equipos.map((equipo, i) => {
                      if (equipo.tipo === "TANQUE") {
                        contadores[0]++;
                        return (
                          <div key={i}>
                            <span className="punto">T{contadores[0] - 1}</span>
                            <select
                              value={equipo.pkmonitoreo}
                              onChange={this.handleChangeSelect.bind(this, i)}
                              className={equipo.pkmonitoreo === "" ? "red" : ""}
                            >
                              <option value="">Selecciona un punto</option>
                              {puntosTipos[0].map((eq, indice) => {
                                return (
                                  <option key={indice} value={eq.pkmonitoreo}>
                                    {eq.ubicacion}
                                  </option>
                                );
                              })}
                            </select>
                            <i
                              className="fas fa-times"
                              onClick={this.deleteEquipo.bind(this, i)}
                            />
                          </div>
                        );
                      }
                    })}
                    {cant[0] < 1 ? (
                      <p className="mensaje">No hay tanques</p>
                    ) : null}
                  </div>

                  <div className="bomba">
                    <label>
                      Bombas{" "}
                      <i
                        className="fas fa-plus"
                        onClick={this.addEquipo.bind(this, "BOMBA")}
                      />
                    </label>
                    {sectores[sectorActual].equipos.map((equipo, i) => {
                      if (equipo.tipo === "BOMBA") {
                        contadores[1]++;
                        return (
                          <div key={i}>
                            <span className="punto">B{contadores[1] - 1}</span>
                            <select
                              key={i}
                              value={equipo.pkmonitoreo}
                              onChange={this.handleChangeSelect.bind(this, i)}
                              className={equipo.pkmonitoreo === "" ? "red" : ""}
                            >
                              <option value="">Selecciona un punto</option>
                              {puntosTipos[1].map((eq, indice) => {
                                return (
                                  <option key={indice} value={eq.pkmonitoreo}>
                                    {eq.ubicacion}
                                  </option>
                                );
                              })}
                            </select>
                            <i
                              className="fas fa-times"
                              onClick={this.deleteEquipo.bind(this, i)}
                            />
                          </div>
                        );
                      }
                    })}
                    {cant[1] < 1 ? (
                      <p className="mensaje">No hay bombas</p>
                    ) : null}
                  </div>
                  <div className="otros">
                    <label>
                      Otros{" "}
                      <i
                        className="fas fa-plus"
                        onClick={this.addEquipo.bind(this, "OTRO")}
                      />
                    </label>
                    {sectores[sectorActual].equipos.map((equipo, i) => {
                      if (equipo.tipo === "OTRO") {
                        contadores[2]++;
                        return (
                          <div key={i}>
                            <span className="punto">B{contadores[2] - 1}</span>
                            <select
                              key={i}
                              value={equipo.pkmonitoreo}
                              onChange={this.handleChangeSelect.bind(this, i)}
                              className={equipo.pkmonitoreo === "" ? "red" : ""}
                            >
                              <option value="">Selecciona un punto</option>
                              {puntosTipos[2].map((eq, indice) => {
                                return (
                                  <option key={indice} value={eq.pkmonitoreo}>
                                    {eq.ubicacion}
                                  </option>
                                );
                              })}
                            </select>
                            <i
                              className="fas fa-times"
                              onClick={this.deleteEquipo.bind(this, i)}
                            />
                          </div>
                        );
                      }
                    })}
                    {cant[2] < 1 ? (
                      <p className="mensaje">No puntos de otra categoría</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="botonesConf crear">
                    <div className="row">
                      <div className="col-md-4">
                        <button
                          onClick={this.toggleModal.bind(this)}
                          className="delete"
                        >
                          Eliminar Sector
                        </button>
                      </div>

                      <div className="col-md-4">
                        {" "}
                        <button
                          onClick={this.recargar.bind(this)}
                          className="restaurar"
                        >
                          Restaurar configuración
                        </button>
                      </div>
                      <div className="col-md-4">
                        <button onClick={this.sendEditedSector.bind(this)}>
                          Guardar cambios
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Map equipos={equiposMapa} puntos={puntos} />
            </div>
          ) : (
            <div className="emptySec">
              <h3>
                No has seleccionado un sector, selecciona uno para ver y editar
                su configuración.
              </h3>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EditarAguaElevada;
