import React, { Component } from "react";
import ListadoContainer from "./tabla/ListadoContainer";
import HandleColumns from "./formulario/HandleColumns";
import Filtros from "./formulario/Filtros";
import { connect } from "react-redux";
import ScrollTop from "../ScrollTop";
import { getListado, changeStore } from "../../actions/actionsListado";
import "../../css/online.css";
import Load from "../Load";
import Footer from "../Footer";
import "../../css/fixed-data-table.css";
import Map from "../header/Map";

class TableContainer extends Component {
  state = {
    filterVisible: false,
    mapVisible: true
  };

  imprimir() {
    var url = window.location.href.replace(window.location.hash, "#/tablaImp");

    var ventana = window.open(
      url,
      "grafica",
      "menubar=no, title=no, location=no, width=800, height=600"
    );
    ventana.columns = this.props.columns;
    ventana.coincidencias = this.props.coincidencias;
  }

  componentDidMount() {
    // hago la peticion de los datos a la url que llega en el input
    // de index.html
    this.props.getListado(this.props.region);
  }

  toggle(variable) {
    // elijo una variable a la que convierto de true a false y viceversa
    this.setState({
      [variable]: !this.state[variable]
    });
  }

  componentWillUnmount() {
    // al desmontar el componente reseteo la busqueda
    this.clean();
  }

  clean() {
    const { equipos, solo } = this.props;
    // limpio las busquedas
    // y reseteo las columnas en caso de ser necesario
    var cambios = [];
    var columns = {
      dlgid: true,
      ubicacion: true,
      q0: true,
      CAUDAL_ACUMULADO: true,
      pA: true,
      pZA: true,
      pB: true,
      pZB: true,
      cota: true,
      tx_state: true,
      bt: true,
      fechadata: true,
      altura: true,
      porcentaje: true
    };

    if (solo === "PQ") {
      cambios = "altura, porcentaje".split(", ");
    }
    if (solo === "TQ") {
      cambios = "q0, CAUDAL_ACUMULADO, pA, pZA, pB, pZB, cota".split(", ");
    }

    // reviso si tiene caudal para eliminar las columnas relacionadas a eso
    var caudal = false;
    equipos.forEach(equipo => {
      if (equipo.macro && equipo.macro === 1) {
        caudal = true;
      }
    });

    if (!caudal) {
      var cambiosCaudal = "q0, CAUDAL_ACUMULADO, pB, pZB".split(", ");
      cambios = [...cambios, ...cambiosCaudal];
    }

    // quito las columnas segun los filtros anteriores
    cambios.forEach(cambio => {
      columns[cambio] = false;
    });

    this.props.changeStore({
      coincidencias: equipos,
      search: "",
      filtro: "Cualquiera",
      clase: "",
      estado: "",
      columns
    });
  }

  render() {
    const { coincidencias, equipos, error, solo } = this.props;
    const { filterVisible, mapVisible } = this.state;

    // si aún no cargó la petición muestro pantalla de carga
    // o de error
    if (equipos.length < 1 && !error) {
      return <Load />;
    }
    if (error) {
      return (
        <div className="load">
          <div className="error inicial">
            <h2>No se pudo conectar con el servidor</h2>
            <p>Vuelve a intentarlo en unos momentos.</p>
          </div>
        </div>
      );
    }

    // el formato de las coincidencias es un array de objetos
    // sin embargo el componente mapa debe recibir cada objeto
    // con una llave, de forma nombreobjeto:{contenido}, en lugar de
    // {contenido}, por lo tanto lo que hago es crear un array
    // con los objetos en el formato adecuado para darselos al componente
    // map, tambien hago un array con los puntos y sus datos para el mapa

    var coincidenciasMapa = [];
    var puntos = [];
    coincidencias.forEach(coincidencia => {
      coincidenciasMapa.push({ [coincidencia.id]: { ...coincidencia } });
      puntos.push({
        lat: coincidencia.latitud,
        lng: coincidencia.longitud,
        tipo: coincidencia.map_ico
      });
    });

    return (
      <div className="contenedorListado">
        {/* mapa */}
        <div
          className="contenedorMapa"
          style={{ marginTop: mapVisible ? "" : "0.3em" }}
        >
          {/* los estilos varian dependiendo de si el mapa esta o no visible */}
          <div
            className="toggleMap"
            onClick={this.toggle.bind(this, "mapVisible")}
            style={{
              position: mapVisible ? "" : "static",
              borderRadius: mapVisible ? "0" : "",
              border: mapVisible ? "" : "none",
              boxShadow: mapVisible ? "none" : "",
              fontSize: mapVisible ? "" : "1em"
            }}
          >
            {mapVisible ? "Ocultar" : "Mostrar mapa"}
          </div>
          {mapVisible ? (
            <Map puntos={puntos} equipos={coincidenciasMapa} />
          ) : null}
        </div>
        <div className="botones">
          <label htmlFor="toggleFiltros" className="toggleVis">
            Filtrar datos
          </label>
          <button
            style={{ display: coincidencias.length < 1 ? "none" : "" }}
            className="botonImprimir"
            onClick={this.imprimir.bind(this)}
          >
            Imprimir tabla
          </button>
        </div>
        <ScrollTop />
        {/* formulario con checkboxes para mostrar u ocultar cada columna */}

        <input
          id="toggleFiltros"
          type="checkbox"
          checked={filterVisible}
          onChange={this.toggle.bind(this, "filterVisible")}
          style={{ display: "none" }}
        />

        <div
          className="busquedaContainer"
          style={{ display: filterVisible ? "block" : "none" }}
        >
          <div
            className="overlay"
            style={{ display: filterVisible ? "block" : "none" }}
            onClick={this.toggle.bind(this, "filterVisible")}
          />
          <div
            className="filterContainer"
            style={{ display: filterVisible ? "block" : "none" }}
          >
            <HandleColumns solo={solo} />
            {/* filtros de busqueda */}
            <Filtros toggle={this.toggle.bind(this, "filterVisible")} />

            <div className="cerrar limp" onClick={this.clean.bind(this)}>
              Limpiar filtros
            </div>
            <div
              className="cerrar"
              onClick={this.toggle.bind(this, "filterVisible")}
            >
              Cerrar
            </div>
          </div>
        </div>
        {/* tabla */}

        <div className="contTabla">
          {coincidencias.length < 1 ? (
            <div className="buscarListado light">
              <h2>No hay resultados para su búsqueda.</h2>
            </div>
          ) : (
            <ListadoContainer />
          )}
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  equipos: state.listado.equipos,
  coincidencias: state.listado.coincidencias,
  error: state.listado.error,
  columns: state.listado.columns,
  solo: state.listado.solo
});

export default connect(
  mapStateToProps,
  { getListado, changeStore }
)(TableContainer);
