import React from 'react'

export default function AlarmaIcon(props) {

    var electricaIcon = <i className="fas fa-bolt"></i>
    var termicaIcon = <i className="fas fa-thermometer-three-quarters"></i>
    var gabineteIcon = <i className="fas fa-lock-open"></i>
    var cloroIcon = <i className="fas fa-fill-drip"></i>

    const { FALLA_ELECTRICA, FALLA_TERMICA, GABINETE_ABIERTO, CL_ALARM} = props;
    const { coincidenciaTipo } = props;

    if (coincidenciaTipo === "TANQUE_AUTO") {
        return (
            GABINETE_ABIERTO === "SI" ? (
                <div className="alarma-icon-container">
                    <div className="header-type">TANQUE</div>
                    <div className="alarma-icon">
                        <div className="dato-falla" title="Gabinete abierto">
                            {gabineteIcon}
                        </div>
                    </div>
                </div>
            ) : ""
        )
    }

    else if (coincidenciaTipo === "CLORO_AUTO") {
        return (
            CL_ALARM === "WARNING" ? (
                <div className="alarma-icon-container">
                    <div className="header-type">CLORO</div>
                    <div className="alarma-icon">
                        <div className="dato-falla warning" title="Cloro fuera de límite">
                            {cloroIcon}
                        </div>
                    </div>
                </div>
            ) : ""
        )
    }

    else if (coincidenciaTipo === "BOMBA_AUTO") {
        return (
            FALLA_ELECTRICA === "SI" || FALLA_TERMICA === "SI" || GABINETE_ABIERTO === "SI" ? (
                <div className="alarma-icon-container">
                    <div className="header-type ">BOMBA</div>
                    <div>
                        {FALLA_ELECTRICA === "SI" ? (

                            <div className="alarma-icon">
                                <div className="dato-falla" title="Falla eléctrica">
                                    {electricaIcon}
                                </div>
                            </div>

                        ) : ""}

                        {FALLA_TERMICA === "SI" ? (

                            <div className="alarma-icon">
                                <div className="dato-falla" title="Falla térmica">
                                    {termicaIcon}
                                </div>
                            </div>

                        ) : ""}

                        {GABINETE_ABIERTO === "SI" ? (

                            <div className="alarma-icon">
                                <div className="dato-falla" title="Gabinete abierto">
                                    {gabineteIcon}
                                </div>
                            </div>

                        ) : ""}
                    </div>
                </div>
            ) : ""

        )
    }
}

