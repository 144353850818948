import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStore } from "../../../actions/actionsListado";
export class Filtros extends Component {
  // eventos para el input
  onKeyDown(e) {
    if (
      e.key === "Enter" ||
      e.which === "13" ||
      e.keyCode === "13" ||
      e.key === "Escape" ||
      e.which === "27" ||
      e.keyCode === "27"
    ) {
      this.props.toggle();
    }
  }

  // filtrado
  filtrar(posicionSector, busqueda, tipo, estado) {
    var equipos = this.props.equipos;
    var coincidencias = [...equipos];
    var { sectores, data } = this.props;

    // filtrar busqueda, filtro todos los equipos cuyo nombre e id
    // no tengan coincidencia con lo que se busca
    coincidencias = coincidencias.filter(coincidencia => {
      return (
        coincidencia.dlgid.toLowerCase().indexOf(busqueda) !== -1 ||
        coincidencia.ubicacion.toLowerCase().indexOf(busqueda) !== -1
      );
    });

    // filtrar sector
    // filtro los equipos que no pertenezcan al sector actual
    if (posicionSector !== "Cualquiera") {
      coincidencias = coincidencias.filter(coincidencia => {
        return sectores[posicionSector].indexOf(coincidencia.id) !== -1;
      });
    }
    // filtrar tipo

    if (tipo !== "") {
      var corresponden = [];
      var equiposFiltro = [];

      // guardo entre que equipos voy a hacer el filtro, dependiendo de
      // si se eligió sector o se dejo cualquiera
      if (posicionSector === "Cualquiera") {
        var keys = Object.keys(data.sectores);
        keys.forEach(key => {
          data.sectores[key].equipos.forEach(equipo => {
            equiposFiltro.push(equipo);
          });
        });
      } else {
        equiposFiltro = [
          ...data.sectores[Object.keys(data.sectores)[posicionSector]].equipos
        ];
      }

      // por cada equipo que pertenece al sector seleccionado
      // si son del tipo seleccionado los conservo, sino los quito
      equiposFiltro.forEach(equipo => {
        if (
          (tipo === "tanques" && equipo.tipo === "TANQUE") ||
          (tipo === "perforacion" && equipo.tipo === "PERFORACION") ||
          (tipo === "bomba_auto" && equipo.tipo === "BOMBA_AUTO") ||
          (tipo === "tanque_auto" && equipo.tipo === "TANQUE_AUTO") ||
          (tipo === "cloro_auto" && equipo.tipo === "CLORO_AUTO") ||
          (tipo === "presionesycaudales" && (equipo.tipo !== "TANQUE" && equipo.tipo !== "PERFORACION" && equipo.tipo !== "BOMBA_AUTO" && equipo.tipo !== "TANQUE_AUTO" && equipo.tipo !== "CLORO_AUTO"))
        ) {
          corresponden.push(equipo.pkmonitoreo);
        }
      });

      coincidencias = coincidencias.filter(coincidencia => {
        return corresponden.indexOf(coincidencia.id) !== -1;
      });
    }

    // filtrar por estado
    if (estado !== "") {
      coincidencias = coincidencias.filter(coincidencia => {
        return coincidencia.tx_state === estado;
      });
    }

    return coincidencias;
  }

  // buscar por id o direccion
  handleSearch(e) {
    var posicionSector = this.props.filtro;
    var busqueda = e.target.value.toLowerCase();
    var tipo = this.props.clase;
    var estado = this.props.estado;
    // envio las coincidencias a redux
    this.props.changeStore({
      coincidencias: this.filtrar(posicionSector, busqueda, tipo, estado),
      search: busqueda
    });
  }

  // filtrar por sector
  filtroSector(e) {
    var filtro = e.target.value;
    var search = this.props.search;
    var tipo = this.props.clase;
    var estado = this.props.estado;

    this.props.changeStore({
      coincidencias: this.filtrar(filtro, search, tipo, estado),
      filtro
    });
  }

  // por tipo de sector
  filtroTipo(e) {
    var clase = e.target.value;
    var busqueda = this.props.search;
    var posicionSector = this.props.filtro;
    var estado = this.props.estado;

    // envio las coincidencias a redux
    this.props.changeStore({
      coincidencias: this.filtrar(posicionSector, busqueda, clase, estado),
      clase
    });
    // var datosAcerrar = "altura, porcentaje, cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, CL_LIBRE, CL_ALARM_CONTROL, H_TQ, H_MAX_TQ, CAUDAL_IMP, P_PRESSURE".split(

    // if (clase === "tanques" || clase === "perforacion" || clase === "tanque_auto" || clase === "bomba_auto" || clase === "cloro_auto") {
    if (clase === "tanques") {
      var columns = { ...this.props.columns };
      var datosAcerrar = "cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, CL_LIBRE, CL_ALARM_CONTROL, H_TQ, H_MAX_TQ, CAUDAL_IMP, P_PRESSURE".split(
        ", "
      );
      datosAcerrar.forEach(dato => {
        columns[dato] = false;
      });
      this.props.changeStore({ columns });
    }
    else if (clase === "bomba_auto") {
      var columns = { ...this.props.columns };
      var datosAcerrar = "altura, porcentaje, cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, CL_LIBRE, CL_ALARM_CONTROL, H_TQ, H_MAX_TQ".split(
        ", "
      );
      datosAcerrar.forEach(dato => {
        columns[dato] = false;
      });
      this.props.changeStore({ columns });
    }
    else if (clase === "cloro_auto") {
      var columns = { ...this.props.columns };
      var datosAcerrar = "altura, porcentaje, cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, H_TQ, H_MAX_TQ, CAUDAL_IMP, P_PRESSURE".split(
        ", "
      );
      datosAcerrar.forEach(dato => {
        columns[dato] = false;
      });
      this.props.changeStore({ columns });
    }
    else if (clase === "tanque_auto") {
      var columns = { ...this.props.columns };
      var datosAcerrar = "altura, porcentaje, cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CAUDAL, PRESION_ALTA, DEPOSITO_DE_CLORO, CL_LIBRE, CL_ALARM_CONTROL, CAUDAL_IMP, P_PRESSURE".split(
        ", "
      );
      datosAcerrar.forEach(dato => {
        columns[dato] = false;
      });
      this.props.changeStore({ columns });
    }
    else if (clase === "perforacion") {
      var columns = { ...this.props.columns };
      var datosAcerrar = "altura, porcentaje, cota, pA, pB, pZA, pZB, q0, CAUDAL_ACUMULADO, CL_LIBRE, CL_ALARM_CONTROL, H_MAX_TQ, H_TQ, CAUDAL_IMP, P_PRESSURE".split(
        ", "
      );
      datosAcerrar.forEach(dato => {
        columns[dato] = false;
      });
      this.props.changeStore({ columns });
    }

    else {
      var columnsSelected = {
        dlgid: true,
        ubicacion: true,
        q0: true,
        CAUDAL_ACUMULADO: true,
        pA: true,
        pZA: true,
        pB: true,
        pZB: true,
        cota: true,
        tx_state: true,
        bt: true,
        fechadata: true,
        altura: true,
        porcentaje: true,
        sqe: true,
        CAUDAL: true,
        PRESION_ALTA: true,
        DEPOSITO_DE_CLORO: true,
        CL_LIBRE: true,
        CL_ALARM_CONTROL: true,
        H_MAX_TQ: true,
        H_TQ: true,
        CAUDAL_IMP: true,
        P_PRESSURE: true,
      };

      this.props.changeStore({ columns: columnsSelected });
    }
  }

  filtroEstado(e) {
    var clase = this.props.clase;
    var busqueda = this.props.search;
    var posicionSector = this.props.filtro;
    var estado = e.target.value;

    this.props.changeStore({
      coincidencias: this.filtrar(posicionSector, busqueda, clase, estado),
      estado
    });
  }

  render() {
    const { filtro, search, nameSectores, clase, estado, solo } = this.props;

    return (
      <div className="busqueda">
        <h2 className="headerTitle">Filtrar equipos</h2>
        <div>
          <label htmlFor="search">Buscar</label>
          <input
            placeholder="ID o direccion"
            onChange={this.handleSearch.bind(this)}
            id="search"
            value={search}
            onKeyDown={this.onKeyDown.bind(this)}
          />
        </div>
        <div>
          <label htmlFor="filtroTipo">Filtrar por sector</label>
          <select onChange={this.filtroSector.bind(this)} value={filtro}>
            <option value="Cualquiera">Cualquier sector</option>
            {nameSectores.map((sector, index) => {
              return (
                <option key={index} value={index}>
                  {sector}
                </option>
              );
            })}
          </select>
        </div>
        {!solo ? (
          <div>
            <label htmlFor="filtroType">Filtrar por tipo</label>
            <select onChange={this.filtroTipo.bind(this)} value={clase}>
              <option value="">Cualquier tipo</option>
              <option value="tanques">Tanques</option>
              <option value="perforacion">Perforación</option>
              <option value="bomba_auto">Bombas automatismo</option>
              <option value="tanque_auto">Tanques automatismo</option>
              <option value="cloro_auto">Cloro</option>
              <option value="presionesycaudales">Presiones y caudales</option>
            </select>
          </div>
        ) : null}
        <div>
          <label htmlFor="filtroEst">Filtrar por estado</label>
          <select onChange={this.filtroEstado.bind(this)} value={estado}>
            <option value="">Cualquier estado</option>
            <option value="ERROR">Error</option>
            <option value="WARNING">Alerta</option>
            <option value="OK">Normal</option>
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  equipos: state.listado.equipos,
  filtro: state.listado.filtro,
  search: state.listado.search,
  nameSectores: state.listado.nameSectores,
  sectores: state.listado.sectores,
  data: state.listado.data,
  clase: state.listado.clase,
  columns: state.listado.columns,
  estado: state.listado.estado,
  solo: state.listado.solo
});

export default connect(
  mapStateToProps,
  { changeStore }
)(Filtros);
