import React, { Component } from "react";
import Map from "../header/Map";
import axios from "axios";

export class CrearAguaElevada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      pTanque: [],
      pBomba: [],
      pOtro: [],
      equipos: null,
      errorCrearSector: false,
      puntosMapa: [],
      equiposMapa: [],
      mensajeError: "",
      ranc: "0"
    };
  }

  componentDidMount() {
    // guardo los props como el state para poder editarlo
    this.setState({
      equipos: this.props.equipos
    });
  }

  handleInputs(e) {
    // cambio el valor del elemento basado en el name del input
    var valor = e.target.value;
    var variable = e.target.name;
    if (variable === "ranc" && parseFloat(valor) < 0) {
      return null;
    }
    this.setState({
      [variable]: valor
    });
  }

  addPunto(tipo) {
    // agrego un punto al tipo correspondiente de puntos
    var puntos = this.state[tipo];
    puntos.push("");
    this.setState({
      [tipo]: puntos
    });
  }

  deleteSelect(index, tipo) {
    // quito el elemento que corresponda de acuerdo al tipo y la posicion
    // a continuacion uso la funcion cargarpuntos para reorganizar los datos
    // y que el mapa se actualice
    var puntos = this.state[tipo];
    this.setState(
      {
        [tipo]: puntos.filter((punto, i) => {
          return i !== index;
        })
      },
      () => {
        this.setState({
          puntosMapa: this.cargarPuntos().puntosMapa,
          equiposMapa: this.cargarPuntos().equiposMapa
        });
      }
    );
  }

  sendNewSector() {
    var variables = "nombre, pTanque, pBomba, pOtro, ranc".split(", ");
    var error = false;
    // reviso que las variables no vengan vacias
    variables.forEach(variable => {
      if (
        typeof this.state[variable] === "string" &&
        this.state[variable] === ""
      ) {
        error = true;
      } else if (typeof this.state[variable] === "object") {
        let keys = Object.keys(this.state[variable]);

        keys.forEach(key => {
          if (this.state[variable][key] === "") {
            error = true;
          }
        });
      }
    });

    // si hay error lo muestro
    if (error) {
      this.setState(
        {
          errorCrearSector: true,
          mensajeError:
            "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
        },
        () => {
          document
            .querySelector(".parrafoerror")
            .scrollIntoView({ behavior: "smooth" });
        }
      );
    } else {
      const { nombre, pTanque, pBomba, pOtro, ranc } = this.state;

      // reviso si hay equipos repetidos
      Array.prototype.unique = (function(a) {
        return function() {
          return this.filter(a);
        };
      })(function(a, b, c) {
        return c.indexOf(a, b + 1) < 0;
      });

      var todosLosEquipos = [...pTanque, ...pBomba];

      if (todosLosEquipos.length !== todosLosEquipos.unique().length) {
        this.setState(
          {
            errorCrearSector: true,
            mensajeError:
              "Debes llenar todos los campos correctamente, asegurate de no seleccionar un mismo equipo para varios puntos."
          },
          () => {
            document
              .querySelector(".parrafoerror")
              .scrollIntoView({ behavior: "smooth" });
          }
        );
        return null;
      }

      // guardo los equipos como objetos con su tipo y pkmonitoreo
      var equipos = [];
      pTanque.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "TANQUE" });
      });
      pBomba.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "BOMBA" });
      });
      pOtro.forEach(equipo => {
        equipos.push({ pkmonitoreo: equipo, tipo: "OTRO" });
      });

      // pongo el formato correcto antes de enviar el dato
      var sector = {
        nombre,
        equipos,
        action: "INSERT",
        tiposector: "TQ",
        ranc
      };

      this.setState({
        errorCrearSector: false
      });

      // convierto el objeto en una string para enviarlo por get
      var texto = JSON.stringify(sector);
      axios
        .get(`${this.props.url}?data=${texto}`)
        .then(res => {
          if (res.data.response === "OK") {
            this.props.toggle("");
            this.props.cargar();
            this.props.popup(true, true, "El sistema ha sido creado con éxito");
          } else if (res.data.response === "ERROR_RANC") {
            this.setState({
              errorCrearSector: true,
              mensajeError:
                "El código Ranc ingresado ya está en uso por otro sector."
            });
          } else {
            this.props.cargar();
            this.props.popup(true, false, res.data.response);
          }
        })
        .catch(err => {
          this.props.cargar();
          this.props.popup(
            true,
            false,
            "Hubo un error, compruebe la conexión intente nuevamente."
          );
        });
    }
  }

  handleSelect(indice, variable, e) {
    var puntos = this.state[variable];
    puntos[indice] = e.target.value;
    // hago el cambio en el state segun el tipo de punto y su posicion
    // luego actualizo los puntos del mapa
    this.setState(
      {
        [variable]: puntos
      },
      () => {
        this.setState({
          puntosMapa: this.cargarPuntos().puntosMapa,
          equiposMapa: this.cargarPuntos().equiposMapa
        });
      }
    );
  }

  cargarPuntos() {
    const { pTanque, pBomba } = this.state;
    var equiposEstado = [...pTanque, ...pBomba];

    var equiposMapa = [];
    var puntosMapa = [];
    var equipos = this.props.equipos;
    // por cada equipo asignado al sector
    // detecto cual es el tipo de equipo, si bomba tanque, etc y lo
    // guardo en la variable equipotipo
    equiposEstado.forEach(key => {
      var coincidencias = "BOMBA, TANQUE, OTRO".split(", ");
      var variables = "pBomba, pTanque, pOtro".split(", ");
      var equipotipo = "";

      variables.forEach((variable, i) => {
        if (this.state[variable].indexOf(key) !== -1) {
          equipotipo = coincidencias[i];
        }
      });

      // por cada equipo que tenga un dlgid seleccionado, agrego el punto
      // y el equipo para luego devolver esas variables y usarlas en el mapa
      if (key !== "") {
        puntosMapa.push({
          lat: equipos[key].latitud,
          lng: equipos[key].longitud,
          tipo: equipotipo.toLowerCase()
        });
        equiposMapa.push({ [key]: equipos[key] });
      }
    });

    return {
      puntosMapa,
      equiposMapa
    };
  }

  render() {
    const {
      pTanque,
      errorCrearSector,
      pBomba,
      pOtro,
      equiposMapa,
      puntosMapa,
      mensajeError
    } = this.state;
    // agrego los campos de texto comunes

    var inputs = [
      { type: "text", name: "nombre", placeholder: "Nombre" },
      { type: "number", name: "ranc", placeholder: "Ranc" }
    ];

    if (!this.state.equipos) {
      return null;
    }
    var keys = Object.keys(this.state.equipos);
    var equipos = [];

    // agrego los equipos disponibles a una variable para que aparezcan
    // como opciones en los select
    keys.forEach((key, i) => {
      equipos.push(this.state.equipos[key]);
      equipos[i].pkmonitoreo = key;
    });

    return (
      <div id="crearSector" className="inputs" data-simplebar>
        <p
          className="parrafoerror"
          style={{ display: errorCrearSector ? "block" : "none" }}
        >
          {mensajeError}
        </p>
        <div className="row editar">
          <div className="col-md-6">
            <h3>Datos del sector</h3>
            {inputs.map((input, i) => {
              return (
                <div key={i}>
                  <label>{input.placeholder}</label>
                  <input
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    onChange={this.handleInputs.bind(this)}
                    value={this.state[input.name]}
                    min="0"
                  />
                </div>
              );
            })}
          </div>

          <div className="col-md-6 puntos">
            <h3>Puntos del sector</h3>
            <div className="tanque">
              <label>
                Tanques{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pTanque")}
                />
              </label>
              {pTanque.map((punto, index) => {
                // filtro los que son tanques
                let filtro = equipos.filter((equipo, i) => {
                  if (equipo.pkmonitoreo.substring(0, 3) == "TQ_") {
                    return equipo;
                  }
                });
                return (
                  <div key={index}>
                    <span className="punto">T{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pTanque")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pTanque")}
                    />
                  </div>
                );
              })}
              {pTanque.length < 1 ? (
                <p className="mensaje">No hay tanques</p>
              ) : null}
            </div>
            <div className="bomba">
              <label>
                Bombas{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pBomba")}
                />
              </label>
              {pBomba.map((punto, index) => {
                // filtro las bombas
                let filtro = equipos.filter((equipo, i) => {
                  if (equipo.macro === 1) {
                    return equipo;
                  }
                });
                return (
                  <div key={index}>
                    <span className="punto">B{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pBomba")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pBomba")}
                    />
                  </div>
                );
              })}
              {pBomba.length < 1 ? (
                <p className="mensaje">No hay bombas</p>
              ) : null}
            </div>
            <div className="otros">
              <label>
                Otros{" "}
                <i
                  className="fas fa-plus"
                  onClick={this.addPunto.bind(this, "pOtro")}
                />
              </label>
              {pOtro.map((punto, index) => {
                // filtro los otros equipos
                var filtro = equipos.filter((equipo, i) => {
                  return (
                    equipo.macro !== 1 &&
                    equipo.pkmonitoreo.substring(0, 2) !== "TQ"
                  );
                });
                return (
                  <div key={index}>
                    <span className="punto">O{index}</span>
                    <select
                      value={punto}
                      onChange={this.handleSelect.bind(this, index, "pOtro")}
                      className={punto === "" ? "red" : ""}
                    >
                      <option value="">Selecciona un punto</option>
                      {filtro
                        ? filtro.map((equipo, i) => {
                            return (
                              <option key={i} value={equipo.pkmonitoreo}>
                                {equipo.ubicacion}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <i
                      className="fas fa-times"
                      onClick={this.deleteSelect.bind(this, index, "pOtro")}
                    />
                  </div>
                );
              })}
              {pOtro.length < 1 ? (
                <p className="mensaje">No hay puntos de otra categoría</p>
              ) : null}
            </div>
          </div>

          <div className="col-12">
            <div className="botonesConf">
              <button onClick={this.sendNewSector.bind(this)}>Confirmar</button>
            </div>
          </div>
        </div>

        <Map equipos={equiposMapa} puntos={puntosMapa} />
      </div>
    );
  }
}

export default CrearAguaElevada;
