import React, { Component } from "react";
import { Table, Column, Cell } from "fixed-data-table-2";
import HeaderOrdenable from "./HeaderOrdenable";
import { connect } from "react-redux";
import { tableSort } from "../../../actions/actionsListado";
import { Link } from "react-router-dom";
import { formatDate, formatNumber } from "../../Funciones";
import Bateria from "./Bateria";
import Sqe from "./Sqe";

export class ListadoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idWidth: 100,
      dirWidth: 200,
      cellWidth: 150,
      rowHeight: 60,
      tWidth: window.innerWidth - 40,
      columns: [],
      tHeight: window.innerHeight - 130,
      estadoGeneral: ""
    };
  }
  componentDidMount() {
    // recalcular anchos al cambiar la ventana
    this.calcularAnchos();
    this.calcular = this.calcularAnchos.bind(this);
    window.addEventListener("resize", this.calcular);
    this.setState({ columns: this.props.columns });
  }

  componentWillUnmount() {
    // quito el listener al desmontar el componente
    window.removeEventListener("resize", this.calcular);
  }

  componentDidUpdate(prevProps) {
    // si las columnas variaron en el state de redux, debo actualizar
    // el state del componente para mostrar los cambios
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
  }

  calcularAnchos() {
    // segun el ancho de la pantalla cambio el ancho de las celdas
    var idWidth = window.innerWidth > 600 ? 120 : 90;
    var dirWidth = window.innerWidth > 600 ? 250 : 105;
    var cellWidth = window.innerWidth > 600 ? 150 : 100;
    var rowHeight = window.innerWidth > 600 ? 60 : 95;
    var tHeight =
      window.innerWidth > 800
        ? window.innerHeight / 2
        : window.innerHeight - 130;
    var tWidth = window.innerWidth - 40;

    if (tWidth > 1300) {
      tWidth = 1300;
    }

    if (this.props.solo == "TQ" && tWidth > 1010) {
      tWidth = 1010;
    }

    this.setState({
      idWidth,
      dirWidth,
      cellWidth,
      rowHeight,
      tWidth,
      tHeight
    });
  }

  // cambiar orden de las columnas
  cambiarOrden(column) {
    // por defecto pongo orden ascendente al clickear en la cabecera,
    // si ya esta ordenado por esa columna y en orden ascendente entonces
    // cambio a descendente
    let sort = this.props.sort;
    let type = "ASC";
    if (sort.column === column && sort.type === "ASC") {
      type = "DESC";
    }
    var newSort = {
      column,
      type
    };

    this.props.tableSort(newSort);
  }

  render() {
    const { coincidencias, sort } = this.props;
    const { columns } = this.state;

    const {
      rowHeight,
      idWidth,
      cellWidth,
      dirWidth,
      tWidth,
      tHeight
    } = this.state;
    return (
      <div className="Listado" style={{ touchAction: "none" }}>
        <Table
          rowsCount={coincidencias.length}
          rowHeight={rowHeight}
          touchScrollEnabled={true}
          headerHeight={44}
          width={tWidth}
          maxHeight={tHeight}
        >
          {/* muestro el id */}
          {columns.dlgid ? (
            <Column
              fixed={true}
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="dlgid"
                  title="ID"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => (
                <Cell
                  {...props}
                  className={`Normal id ${
                    coincidencias[props.rowIndex].tx_state
                    } ${
                    coincidencias[props.rowIndex].ubicacion.substring(0, 3) ===
                      "OSE" ||
                      coincidencias[props.rowIndex].ubicacion.substring(0, 8) ===
                      "SPYMOVIL"
                      ? "grey"
                      : ""
                    }`}
                >
                  <Link
                    to={`/detalle/${coincidencias[props.rowIndex].id}`}
                    target="_blank"
                  >
                    {coincidencias[props.rowIndex].dlgid}
                  </Link>
                </Cell>
              )}
              width={idWidth}
              fixedLeft={true}
            />
          ) : null}

          {/* muestro la direccion */}
          {columns.ubicacion ? (
            <Column
              fixed={true}
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="ubicacion"
                  title="Direccion"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => (
                <Cell
                  {...props}
                  className={`Normal dir ${
                    coincidencias[props.rowIndex].tx_state
                    } ${
                    coincidencias[props.rowIndex].ubicacion.substring(0, 3) ===
                      "OSE" ||
                      coincidencias[props.rowIndex].ubicacion.substring(0, 8) ===
                      "SPYMOVIL"
                      ? "grey"
                      : ""
                    }`}
                >
                  <Link
                    to={`/detalle/${coincidencias[props.rowIndex].id}`}
                    target="_blank"
                  >
                    {coincidencias[props.rowIndex].ubicacion}
                  </Link>
                </Cell>
              )}
              width={dirWidth}
              fixedLeft={true}
            />
          ) : null}
          {/* muestro el caudal */}
          {columns.q0 ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="q0"
                  title="Caudal"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].q0_state;
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].q0 !== undefined &&
                      coincidencias[props.rowIndex].q0 !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].q0.toFixed(2)
                      ) + " mt3/h"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}

          {/* muestro el acumulado */}
          {columns.CAUDAL_ACUMULADO ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="CAUDAL_ACUMULADO"
                  title="Acumulado"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => (
                <Cell
                  {...props}
                  className={`${
                    coincidencias[props.rowIndex].tx_state
                    } OK celdaNumerica  ${
                    coincidencias[props.rowIndex].ubicacion.substring(0, 3) ===
                      "OSE" ||
                      coincidencias[props.rowIndex].ubicacion.substring(0, 8) ===
                      "SPYMOVIL"
                      ? "grey"
                      : ""
                    }`}
                >
                  {coincidencias[props.rowIndex].CAUDAL_ACUMULADO !==
                    undefined &&
                    coincidencias[props.rowIndex].CAUDAL_ACUMULADO !== -1
                    ? formatNumber.new(
                      coincidencias[props.rowIndex].CAUDAL_ACUMULADO.toFixed(
                        2
                      )
                    ) + " m3"
                    : null}
                </Cell>
              )}
              width={cellWidth}
            />
          ) : null}

          {/* muestro Cota */}
          {columns.cota ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="cota"
                  title="Cota"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } OK celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].cota !== undefined &&
                      coincidencias[props.rowIndex].cota !== -1
                      ? formatNumber.new(coincidencias[props.rowIndex].cota) +
                      " mts"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}

          {/* muestro pA */}

          {columns.pA ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="pA"
                  title="pA"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].pA_state;
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].pA !== undefined &&
                      coincidencias[props.rowIndex].pA !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].pA.toFixed(2)
                      ) + " mca"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}

          {/* muestro pZ_pA */}
          {columns.pZA ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="pZA"
                  title="pZA"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].pA_state;
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].pZA !== undefined &&
                      coincidencias[props.rowIndex].pZA !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].pZA.toFixed(2)
                      ) + " mca"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}
          {/* muestro pB */}

          {columns.pB ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="pB"
                  title="pB"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].pB_state;
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].pB !== undefined &&
                      coincidencias[props.rowIndex].pB !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].pB.toFixed(2)
                      ) + " mca"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}

          {/* muestro pZB */}
          {columns.pZB ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="pZB"
                  title="pZB"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].pB_state;
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].pZB !== undefined &&
                      coincidencias[props.rowIndex].pZB !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].pZB.toFixed(2)
                      ) + " mca"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth}
            />
          ) : null}
          {/* muestro altura */}
          {columns.altura || columns.H_TQ ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="altura"
                  title="Altura"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].altura_state;
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].altura !== undefined &&
                      coincidencias[props.rowIndex].altura !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].altura.toFixed(2)
                      ) + " mts"
                      : null}

                    {coincidencias[props.rowIndex].H_TQ !== undefined &&
                      coincidencias[props.rowIndex].H_TQ !== -1
                      ? formatNumber.new(
                        parseFloat(coincidencias[props.rowIndex].H_TQ).toFixed(2)
                      ) + " mts"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}
          {/* muestro porcentaje */}
          {columns.porcentaje ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="porcentaje"
                  title="Llenado"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].altura_state;

                let H_MAX_TQ = parseFloat(coincidencias[props.rowIndex].H_MAX_TQ)
                let H_TQ = parseFloat(coincidencias[props.rowIndex].H_TQ)

                var porcentajeAuto = null;
                if (H_MAX_TQ || H_TQ) {
                  porcentajeAuto = (H_TQ / H_MAX_TQ) * 100;

                }

                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].porcentaje !== undefined &&
                      coincidencias[props.rowIndex].porcentaje !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].porcentaje.toFixed(2)
                      ) + " %"
                      : null}

                    {porcentajeAuto !== null ? (
                      porcentajeAuto !== undefined &&
                        porcentajeAuto !== -1
                        ? formatNumber.new(
                          porcentajeAuto.toFixed(2)
                        ) + " %"
                        : null
                    ) : null}

                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {/* Columnas nuevas ========================================== */}
          {/* Caudal PSA */}
          {columns.CAUDAL || columns.CAUDAL_IMP ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="CAUDAL"
                  title="Caudal"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].CAUDAL_state;

                // if (columns.CAUDAL)

                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].CAUDAL !== undefined &&
                      coincidencias[props.rowIndex].CAUDAL !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].CAUDAL.toFixed(2)
                      ) + " mt3/h"
                      : null}

                    {coincidencias[props.rowIndex].CAUDAL_IMP !== undefined &&
                      coincidencias[props.rowIndex].CAUDAL_IMP !== -1
                      ? formatNumber.new(
                        parseFloat(coincidencias[props.rowIndex].CAUDAL_IMP).toFixed(2)
                      ) + " mt3/h"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}



          {columns.P_PRESSURE || columns.PRESION_ALTA ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="P_PRESSURE"
                  title="Presión"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].tx_state;
                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].P_PRESSURE !== undefined &&
                      coincidencias[props.rowIndex].P_PRESSURE !== -1
                      ? formatNumber.new(
                        parseFloat(coincidencias[props.rowIndex].P_PRESSURE).toFixed(2)
                      ) + " bar"
                      : null}

                    {coincidencias[props.rowIndex].PRESION_ALTA !== undefined &&
                      coincidencias[props.rowIndex].PRESION_ALTA !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].PRESION_ALTA.toFixed(2)
                      ) + " bar"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {/* PRESION PSA */}

          {columns.CL_LIBRE ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="CL_LIBRE"
                  title="Cloro"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                // let estado = coincidencias[props.rowIndex].tx_state;

                var estado = "OK";
                if (coincidencias[props.rowIndex].CL_LIBRE < coincidencias[props.rowIndex].CL_MIN_ALARM) {
                  estado = "WARNING";
                }
                if (coincidencias[props.rowIndex].CL_LIBRE > coincidencias[props.rowIndex].CL_MAX_ALARM) {
                  estado = "WARNING";
                }

                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].CL_LIBRE !== undefined &&
                      coincidencias[props.rowIndex].CL_LIBRE !== -1
                      ? formatNumber.new(
                        parseFloat(coincidencias[props.rowIndex].CL_LIBRE).toFixed(2)
                      ) + " ppm"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {columns.CL_ALARM_CONTROL ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="CL_ALARM_CONTROL"
                  title="Cloro alarma"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].tx_state;

                // var estado = "OK";
                // if (coincidencias[props.rowIndex].CL_LIBRE < coincidencias[props.rowIndex].CL_MIN_ALARM) {
                //   estado = "WARNING";
                // }
                // if (coincidencias[props.rowIndex].CL_LIBRE > coincidencias[props.rowIndex].CL_MAX_ALARM) {
                //   estado = "WARNING";
                // }

                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].CL_ALARM_CONTROL !== undefined &&
                      coincidencias[props.rowIndex].CL_ALARM_CONTROL !== -1
                      ? coincidencias[props.rowIndex].CL_ALARM_CONTROL === "SI"
                        ? "Activo"
                        : coincidencias[props.rowIndex].CL_ALARM_CONTROL === "NO"
                          ? "Desactivado"
                          : coincidencias[props.rowIndex].CL_ALARM_CONTROL === "ERROR"
                            ? "Error"
                            : ""

                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {/* Deposito de cloro */}
          {columns.DEPOSITO_DE_CLORO ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="DEPOSITO_DE_CLORO"
                  title="Dep. cloro"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].DEPOSITO_DE_CLORO_state;
                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].DEPOSITO_DE_CLORO !== undefined &&
                      coincidencias[props.rowIndex].DEPOSITO_DE_CLORO !== -1
                      ? formatNumber.new(
                        coincidencias[props.rowIndex].DEPOSITO_DE_CLORO.toFixed(2)
                      ) + " %"
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {/* PUMP_PERF_STATE */}
          {columns.PUMP_PERF_STATE ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="PUMP_PERF_STATE"
                  title="Bomba"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                let estado = coincidencias[props.rowIndex].PUMP_PERF_STATE;
                // let tx_state = coincidencias[props.rowIndex].tx_state;
                // let TX_ERROR = coincidencias[props.rowIndex].TX_ERROR;
                // if (tx_state === "Error") {
                //   tx_state = "ERROR";
                // } else if (tx_state === "Ok") {
                //   tx_state = "OK";
                // } else if (tx_state === "Alerta") {
                //   tx_state = "WARNING";
                // }
                // if (TX_ERROR === "SI") {
                //   TX_ERROR = "ERROR";
                // } else if (TX_ERROR === "NO") {
                //   TX_ERROR = "OK";
                // }
                // if (estado === undefined) {
                //   estado = this.state.estadoGeneral;
                // } else if (this.state.estadoGeneral !== estado) {
                //   this.setState({ estadoGeneral: estado });

                // }
                return (
                  <Cell
                    {...props}
                    className={`${
                      coincidencias[props.rowIndex].tx_state
                      } ${estado}alt celdaNumerica  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {coincidencias[props.rowIndex].PUMP_PERF_STATE !== undefined &&
                      coincidencias[props.rowIndex].PUMP_PERF_STATE !== -1
                      ? coincidencias[props.rowIndex].PUMP_PERF_STATE === 0
                        ? "Apagada"
                        : coincidencias[props.rowIndex].PUMP_PERF_STATE === 1
                          ? "Encendida"
                          : coincidencias[props.rowIndex].PUMP_PERF_STATE === "ON"
                            ? "Encendida"
                            : coincidencias[props.rowIndex].PUMP_PERF_STATE === "OFF"
                              ? "Apagada"
                              : coincidencias[props.rowIndex].PUMP_PERF_STATE === "ERROR"
                                ? "Error"
                                : ""
                      : null}
                  </Cell>
                );
              }}
              width={cellWidth - 10}
            />
          ) : null}

          {/* Columnas nuevas ========================================== */}

          {/* muestro fecha */}
          {columns.fechadata ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="fechadata"
                  title="Fecha"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                var fecha = coincidencias[props.rowIndex].fechadata;

                return (
                  <Cell
                    {...props}
                    className={`celdaNumerica ${
                      coincidencias[props.rowIndex].tx_state
                      } OK  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    {formatDate(fecha)}
                  </Cell>
                );
              }}
              width={cellWidth + 30}
            />
          ) : null}

          {/* muestro se;al */}
          {columns.sqe ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="sqe"
                  title="Señal"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                return (
                  <Cell
                    {...props}
                    className={`${coincidencias[props.rowIndex].tx_state}  ${
                      coincidencias[props.rowIndex].ubicacion.substring(
                        0,
                        3
                      ) === "OSE" ||
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          8
                        ) === "SPYMOVIL"
                        ? "grey"
                        : ""
                      }`}
                  >
                    <Sqe data={coincidencias[props.rowIndex].sqe} />
                  </Cell>
                );
              }}
              width={80}
            />
          ) : null}

          {/* muestro bateria */}
          {columns.bt ? (
            <Column
              header={
                <HeaderOrdenable
                  sort={sort}
                  column="bt"
                  title="Alimentación"
                  cambiarOrden={this.cambiarOrden.bind(this)}
                />
              }
              cell={props => {
                var dato = coincidencias[props.rowIndex];

                if (dato.alimentacion === "BATERIA") {
                  return (
                    <Cell
                      {...props}
                      className={`${
                        coincidencias[props.rowIndex].tx_state
                        } OK celdaNumerica  ${
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          3
                        ) === "OSE" ||
                          coincidencias[props.rowIndex].ubicacion.substring(
                            0,
                            8
                          ) === "SPYMOVIL"
                          ? "grey"
                          : ""
                        }`}
                    >
                      <Bateria
                        data={formatNumber.new(
                          coincidencias[props.rowIndex].bt
                        )}
                        icon=""
                        tipo="Bateria"
                      />
                    </Cell>
                  );
                } else if (dato.alimentacion === "SOLAR") {
                  return (
                    <Cell
                      {...props}
                      className={`${
                        coincidencias[props.rowIndex].tx_state
                        } OK celdaNumerica  ${
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          3
                        ) === "OSE" ||
                          coincidencias[props.rowIndex].ubicacion.substring(
                            0,
                            8
                          ) === "SPYMOVIL"
                          ? "grey"
                          : ""
                        }`}
                    >
                      <Bateria
                        data={formatNumber.new(
                          coincidencias[props.rowIndex].bt
                        )}
                        icon="fas fa-sun"
                        tipo="Solar"
                      />
                    </Cell>
                  );
                } else if (dato.alimentacion === "TURBINA") {
                  return (
                    <Cell
                      {...props}
                      className={`${
                        coincidencias[props.rowIndex].tx_state
                        } OK celdaNumerica  ${
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          3
                        ) === "OSE" ||
                          coincidencias[props.rowIndex].ubicacion.substring(
                            0,
                            8
                          ) === "SPYMOVIL"
                          ? "grey"
                          : ""
                        }`}
                    >
                      <Bateria
                        data={formatNumber.new(
                          coincidencias[props.rowIndex].bt
                        )}
                        icon="fas fa-sun"
                        tipo="Turbina"
                      />
                    </Cell>
                  );
                } else {
                  return (
                    <Cell
                      {...props}
                      className={`${
                        coincidencias[props.rowIndex].tx_state
                        } OK celdaNumerica  ${
                        coincidencias[props.rowIndex].ubicacion.substring(
                          0,
                          3
                        ) === "OSE" ||
                          coincidencias[props.rowIndex].ubicacion.substring(
                            0,
                            8
                          ) === "SPYMOVIL"
                          ? "grey"
                          : ""
                        }`}
                    >
                      <Bateria
                        data={"13"}
                        icon="fas fa-plug"
                        tipo="Corriente"
                      />
                    </Cell>
                  );
                }
              }}
              width={cellWidth}
            />
          ) : null}
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  coincidencias: state.listado.coincidencias,
  sort: state.listado.sort,
  columns: {
    dlgid: state.listado.columns.dlgid,
    ubicacion: state.listado.columns.ubicacion,
    q0: state.listado.columns.q0,
    CAUDAL_ACUMULADO: state.listado.columns.CAUDAL_ACUMULADO,
    pA: state.listado.columns.pA,
    pZA: state.listado.columns.pZA,
    pB: state.listado.columns.pB,
    pZB: state.listado.columns.pZB,
    cota: state.listado.columns.cota,
    sqe: state.listado.columns.sqe,
    bt: state.listado.columns.bt,
    fechadata: state.listado.columns.fechadata,
    porcentaje: state.listado.columns.porcentaje,
    altura: state.listado.columns.altura,

    CAUDAL: state.listado.columns.CAUDAL,
    PRESION_ALTA: state.listado.columns.PRESION_ALTA,
    PUMP_PERF_STATE: state.listado.columns.PUMP_PERF_STATE,
    DEPOSITO_DE_CLORO: state.listado.columns.DEPOSITO_DE_CLORO,
    CL_LIBRE: state.listado.columns.CL_LIBRE,
    CL_ALARM_CONTROL: state.listado.columns.CL_ALARM_CONTROL,
    H_MAX_TQ: state.listado.columns.H_MAX_TQ,
    H_TQ: state.listado.columns.H_TQ,
    CAUDAL_IMP: state.listado.columns.CAUDAL_IMP,
    P_PRESSURE: state.listado.columns.P_PRESSURE,
  },
  solo: state.listado.solo
});

export default connect(
  mapStateToProps,
  { tableSort }
)(ListadoContainer);
