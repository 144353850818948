import { GET_LISTADO, TABLE_SORT, CHANGE_STORE } from "./types";
import axios from "axios";

export const getListado = url => dispatch => {
  // peticion al sv
  axios
    .get(url)
    .then(res => {
      var respuesta = res.data;

      if (
        !respuesta.equipos ||
        respuesta.equipos.length < 1 ||
        !respuesta.sectores ||
        respuesta.sectores.length < 1
      ) {
        dispatch({
          type: CHANGE_STORE,
          payload: { error: true }
        });
        return null;
      }

      var equipos2 = [];
      var idEquipos = [];
      var sectores2 = [];
      var nameSectores = [];
      var keySectores = Object.keys(respuesta.sectores);

      Object.keys(respuesta.equipos).forEach(key => {
        idEquipos.push(key);
      });

      // guardo los equipos con los datos que necesito en un array
      idEquipos.forEach((key, index) => {
        equipos2.push(respuesta.equipos[key]);
        equipos2[index].id = idEquipos[index];
        var map_ico = "";

        keySectores.forEach(llave => {
          respuesta.sectores[llave].equipos.forEach(eqp => {
            if (eqp.pkmonitoreo === key) {
              map_ico = eqp.map_ico;
            }
          });
        });

        equipos2[index].map_ico = map_ico;
        equipos2[index].tipo = respuesta.equipos[key].tipo;
      });

      // si el sector no esta vacio lo guardo tambien
      keySectores.forEach((key, index) => {
        if (
          !respuesta.sectores[key].nombre ||
          !respuesta.sectores[key].equipos
        ) {
          return null;
        }
        nameSectores.push(respuesta.sectores[key].nombre);
        sectores2[index] = [];
        respuesta.sectores[key].equipos.forEach(equipo => {
          sectores2[index].push(equipo.pkmonitoreo);
        });
      });

      dispatch({
        type: GET_LISTADO,
        payload: {
          equipos: equipos2,
          sectores: sectores2,
          nameSectores,
          data: respuesta
        }
      });
    })
    .catch(err => {
      dispatch({
        type: CHANGE_STORE,
        payload: { error: true }
      });
    });
};

export const tableSort = sort => dispatch => {
  dispatch({
    type: TABLE_SORT,
    payload: sort
  });
};

export const changeStore = objeto => dispatch => {
  dispatch({
    type: CHANGE_STORE,
    payload: objeto
  });
};
