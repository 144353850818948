// mapas para tv
export const GET_SECTORES = "GET_SECTORES";
export const GET_NEXT_SECTOR = "GET_NEXT_SECTOR";
export const ERROR_REQUEST = "ERROR_REQUEST";
export const GET_SECTOR = "GET_SECTOR";
export const GET_PREV_SECTOR = "GET_PREV_SECTOR";
export const CHANGE_STORE_TV = "CHANGE_STORE_TV";

// listado
export const GET_LISTADO = "GET_LISTADO";
export const TABLE_SORT = "TABLE_SORT";
export const CHANGE_STORE = "CHANGE_STORE";
