import React, { Component } from "react";
import { formatNumber } from "../Funciones";

export class SectorDataPerforaciones extends Component {
  render() {
    const {
      VAR_FREC,
      D_EXEC_PER_PUMP,
      D_EXEC_PER_PUMP_ERROR,
      T_EXEC_PER_PUMP,
      SW1,
      EMERGENCY_STATE,
      EMERGENCY_SYSTEM,
      LOCAL_MODE,
    } = this.props.datos;

    var D_ERROR = D_EXEC_PER_PUMP_ERROR === "SI" ? "ERROR" : "OK";

    var modo = "";
    var modoClass = "";

    if (LOCAL_MODE === "SI") {
      modo = "Modo local";
      modoClass = "MANUAL";
    } else if (EMERGENCY_STATE === "SI") {
      modo = EMERGENCY_SYSTEM;
      modoClass = "REMOTO";
    } else if (SW1 === "AUTO") {
      modo = "Automático";
      modoClass = SW1;
    } else if (SW1 === "REMOTO") {
      modo = "Remoto";
      modoClass = SW1;
    }

    return (
      <div className="data-block">
        <div className="data-title">Perforación</div>
        <div className={"data-container " + D_ERROR}>
          <div className={"data-subcontainer "}>
            <div className="data-name">Diario:</div>
            <div className="data-content">
              <div className="data-value">
                {D_EXEC_PER_PUMP !== "" ? D_EXEC_PER_PUMP : "-"}{" "}
              </div>
            </div>
          </div>
          <div className="data-subcontainer">
            <div className="data-name">Total:</div>
            <div className="data-content">
              <div className="data-value">
                {T_EXEC_PER_PUMP !== "" ? T_EXEC_PER_PUMP : "-"}{" "}
              </div>
            </div>
          </div>
        </div>

        <div className={"data-container caudal " + modoClass}>
          <div className={"data-subcontainer "}>
            <div className="data-name">Frecuencia:</div>
            <div className="data-content">
              <div className="data-value">
                {VAR_FREC !== undefined ? VAR_FREC : "-"}{" "}
                <div className="data-unit">
                  {VAR_FREC !== undefined ? "rpm" : ""}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className={"data-subcontainer "}>
            <div className="data-name">Modo:</div>
            <div className="data-content">
              <div className="data-value">{modo}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectorDataPerforaciones;
