import React, { Component } from "react";
import Map from "./Map";
import SectorDataContainer from "./SectorDataContainer";
import { connect } from "react-redux";

export class HeadContainer extends Component {
  render() {
    var currentSector = this.props.currentSector;
    // si ya cargaron los datos renderizo la informacion en pantalla
    if (
      this.props.sectores !== undefined &&
      this.props.currentSector !== undefined &&
      this.props.sectores[currentSector] !== undefined
    ) {
      var { nombre, caudal, agua_elevada, D_EXEC_PER_PUMP, D_EXEC_PER_PUMP_ERROR, T_EXEC_PER_PUMP, VAR_FREC, SW1, EMERGENCY_STATE, EMERGENCY_SYSTEM, LOCAL_MODE } = this.props.sectores[currentSector];
      var { coincidencias, equipos, sectorData } = this.props;
      var perfAutoDatos = { D_EXEC_PER_PUMP, D_EXEC_PER_PUMP_ERROR, T_EXEC_PER_PUMP, VAR_FREC, SW1, EMERGENCY_STATE, EMERGENCY_SYSTEM, LOCAL_MODE };
      var datos = { caudal, agua_elevada, perfAutoDatos };

      // agrego los puntos
      var points = undefined;
      var puntos = [];

      var equiposDelSector = [];
      var arrayAux = [];
      equipos[currentSector].forEach(item => {
        if (!arrayAux.includes(item[Object.keys(item)[0]].dlgid)) {
          equiposDelSector.push(item);
          arrayAux.push(item[Object.keys(item)[0]].dlgid);
        }
      });


      var dataEquipos =
        sectorData[Object.keys(sectorData)[currentSector]].equipos;

      // reviso que tipo de equipos son en el sector actual para poner
      // el icono correcto en el mapa
      equiposDelSector.forEach((equipo, i) => {
        coincidencias.forEach((coincidencia, k) => {
          if (
            equipo[Object.keys(equipo)[0]].dlgid ===
            coincidencia[Object.keys(coincidencia)[0]].dlgid
          ) {
            puntos.push({
              lat: coincidencia[Object.keys(coincidencia)[0]].latitud,
              lng: coincidencia[Object.keys(coincidencia)[0]].longitud,
              tipo: dataEquipos[i].map_ico
            });
          }
        });
      });

      return (
        <div className="headContainer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <Map
                  puntos={puntos.length > 0 ? puntos : points}
                  equipos={coincidencias}
                />
              </div>
              <div className="col-lg-6">
                <SectorDataContainer sector={nombre} datos={datos} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="headContainer">
          <div className="container-fluid">
            <h2
              style={{
                color: "white",
                fontSize: "3em",
                textAlign: "center",
                margin: "1em auto"
              }}
            >
              No hay datos para mostrar
            </h2>
          </div>
        </div>
      );
    }
  }
}

// guardo los datos del store en props
const mapStateToProps = state => ({
  sectores: state.sector.grupoSectores,
  currentSector: state.sector.currentSector,
  errors: state.sector.errors,
  coincidencias: state.sector.coincidencias,
  equipos: state.sector.dataSectores,
  sectorData: state.sector.sectores.sectores
});

export default connect(
  mapStateToProps,
  null
)(HeadContainer);
