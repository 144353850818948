import React, { Component } from "react";
import ColumnCheckbox from "./ColumnCheckbox";

export class HandleColumns extends Component {
  render() {
    const { solo } = this.props;

    var columnData = [
      { title: "ID", column: "dlgid" },
      { title: "Direccion", column: "ubicacion" },
      { title: "Caudal", column: "q0" },
      { title: "Acumulado", column: "CAUDAL_ACUMULADO" },
      { title: "Cota", column: "cota" },
      { title: "pA", column: "pA" },
      { title: "pZA", column: "pZA" },
      { title: "pB", column: "pB" },
      { title: "pZB", column: "pZB" },
      { title: "Altura", column: "altura" },
      { title: "Llenado", column: "porcentaje" },
      { title: "Fecha", column: "fechadata" },
      { title: "Señal", column: "sqe" },
      { title: "Alimentación", column: "bt" }
    ];

    var filtro = [];

    if (solo === "TQ") {
      filtro = [2, 3, 4, 5, 6, 7, 8];
    }
    if (solo === "PQ") {
      filtro = [9, 10];
    }

    if (filtro.length > 0) {
      columnData = columnData.filter((columna, i) => {
        return filtro.indexOf(i) === -1;
      });
    }

    // agrego un checkbox por cada columna
    const checkBoxes = columnData.map((data, index) => {
      return (
        <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
          <ColumnCheckbox title={data.title} column={data.column} />
        </div>
      );
    });

    return (
      <div className="formColumnas">
        <div className="container">
          <h2 className="headerTitle">
            Selecciona las columnas que se muestran
          </h2>
          <div className="checkboxContainer">
            <div className="row">{checkBoxes}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default HandleColumns;
