import React, { Component } from "react";
import caudalimetro from "../../img/caudalimetro.png";
import error from "../../img/error.png";
import punto_medio from "../../img/punto_medio.png";
import reguladora from "../../img/reguladora.png";
import tanque from "../../img/tanque.png";
import bomba from "../../img/bomba-agua-icon.png";
import def from "../../img/blue-dot.png";
import box from "../../img/box.png";
import { connect } from "react-redux";
import { changeStore } from "../../actions/sectoresActions";
import { estilosmapa } from "../Funciones";

export class Map extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.bounds = null;
    this.showPoint = this.showPoint.bind(this);
    this.marcadores = null;
    this.idMarcadores = null;
  }

  // cuando inicia el componente agrego los puntos al mapa
  componentDidMount() {
    this.addPoints();
  }

  // cuando el componente se actualiza, dependiendo de si cambia el
  // sector o el punto actual que se esta viendo
  // inicio una funcion u otra
  componentDidUpdate(prevProps) {
    // si cambio el sector recargo los puntos
    if (prevProps.puntos !== this.props.puntos) {
      this.addPoints();
    }
    // si cambia el punto, centro el mapa en el mismo
    if (
      this.props.currentPoint != null &&
      this.props.currentPoint !== prevProps.currentPoint
    ) {
      this.showPoint();
    }
  }

  showPoint() {
    // detengo la reproduccion si esta activa
    this.props.changeStore({ timer: null });
    this.props.changeStore({ recorriendo: false });
    // tomo los datos de los equipos y del punto que quiero centrar
    const { equipos, currentPoint } = this.props;
    var datosEquipo;
    // por cada equipo, reviso cual es el que corresponde con el
    // id actual

    equipos.forEach((equipo, index) => {
      if (equipo[Object.keys(equipo)[0]].dlgid === currentPoint) {
        datosEquipo = equipo[Object.keys(equipo)[0]];
      }
    });

    const {
      latitud,
      longitud,
      pA,
      dlgid,
      pB,
      q0,
      ubicacion,
      altura,
      porcentaje
    } = datosEquipo;

    // creo el cuadro con los datos del punto
    var contentString = `<div class='googleCuadro'>
    <h3>${ubicacion}</h3>
      <h4>${dlgid}</h4>
      ${pA && pA !== -1 ? `<p>pA: ${pA.toFixed(2)} mca</p>` : ""}
      ${pB && pB !== -1 ? `<p>pB: ${pB.toFixed(2)} mca</p>` : ""}
      ${q0 && q0 !== -1 ? `<p>Caudal: ${q0.toFixed(2)} mca</p>` : ""}

      ${
      altura && altura !== -1 ? `<p>Altura: ${altura.toFixed(2)} mts</p>` : ""
      }
      ${
      porcentaje && porcentaje !== -1
        ? `<p>Porcentaje: ${porcentaje.toFixed(2)} mts</p>`
        : ""
      }
      </div>`;

    if (this.infowindow) {
      this.infowindow.close();
    }

    this.infowindow = new window.google.maps.InfoWindow({
      content: contentString
    });
    // inicio el cuadro, centrando en el marcador correspondiente
    // al id del punto en el que clickee
    this.infowindow.open(
      this.map,
      this.marcadores[this.idMarcadores.indexOf(dlgid)]
    );
    this.map.panTo({ lat: latitud, lng: longitud });
    this.map.setZoom(17);
    //vuelvo a dejar nulo el punto actual cuando cierro el cuadro para
    // poder volver a seleccionar el mismo 2 veces seguidas
    this.infowindow.addListener("closeclick", () => {
      this.props.changeStore({ currentPoint: null });
    });
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
  addPoints() {
    // vacio la variable que guarda el mapa
    // this.map = null;
    if (!window.google) {
      this.timeout = setTimeout(() => {
        this.addPoints.bind(this);
      }, 100);
      return null;
    }
    // inicializo el mapa en el div de render()
    if (!this.map) {
      this.map = new window.google.maps.Map(
        document.getElementById("mapContainer"),
        {
          center: { lat: -32.816933, lng: -56.15009 },
          zoom: 8,
          scaleControl: false,
          maxZoom: 18
        }
      );
    }

    if (this.marcadores) {
      for (var indice = 0; indice < this.marcadores.length; indice++) {
        this.marcadores[indice].setMap(null);
      }
    }
    // inicio el centrado para agregarle los puntos del sector
    this.bounds = new window.google.maps.LatLngBounds();
    if (this.props.puntos !== undefined) {
      // inicio las variables que contendran el marcador, y la otra
      // el id del punto de ese mismo marcador, al agregarse sus
      // elementos a la misma vez, el orden de ambos va a ser el mismo

      this.marcadores = [];
      this.idMarcadores = [];

      const { puntos, equipos } = this.props;

      // por cada punto agrego un cuadro con los datos correspondientes
      if (puntos.length > 0) {
        puntos.forEach((punto, index) => {
          var ubicacion =
            equipos[index][Object.keys(equipos[index])[0]].ubicacion;
          // de acuerdo al tipo de imagen agrego un icono u otro
          var img;
          switch (punto.tipo) {
            case "caudalimetro":
              img = caudalimetro;
              break;
            case "bomba_auto":
              img = bomba;
              break;
            case "tanque_auto":
              img = tanque;
              break;
            case "reguladora":
              img = reguladora;
              break;
            case "punto_medio":
              img = punto_medio;
              break;
            case "tanque":
              img = tanque;
              break;
            case "bomba":
              img = bomba;
              break;
            case "error":
              img = error;
              break;
            default:
              img = def;
              break;
          }

          if (
            ubicacion.substring(0, 8) === "SPYMOVIL" ||
            ubicacion.substring(0, 3) === "OSE"
          ) {
            img = box;
          }

          // agrego el marcador al array marcadores
          this.marcadores.push(
            new window.google.maps.Marker({
              position: { lat: punto.lat, lng: punto.lng },
              map: this.map,
              icon: img,
              title: ubicacion
            })
          );
          this.idMarcadores.push(
            equipos[index][Object.keys(equipos[index])[0]].dlgid
          );

          // centro el mapa de acuerdo a los puntos
          var loc = new window.google.maps.LatLng(
            this.marcadores[index].position.lat(),
            this.marcadores[index].position.lng()
          );

          if (
            ubicacion.indexOf("SPYMOVIL") === -1 &&
            (punto.lat !== 0 && punto.lng !== 0)
          ) {
            this.bounds.extend(loc);
          }

          // agrego el listenerCount, al clickear activo la function
          // getMap de redux, que llama a showPoint()
          //centrando el mapa en el punto en el que clickeo
          var self = this;
          this.marcadores[index].addListener("click", function () {
            // self.props.getMap(Object.keys(equipos[index]));
            self.props.changeStore({
              currentPoint: equipos[index][Object.keys(equipos[index])[0]].dlgid
            });
          });
        });

        // cambio el zoom y el punto central del mapa
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      } else {
        var latLng = new window.google.maps.LatLng(-32.816933, -56.15009);
        this.map.setCenter(latLng);
        this.map.setZoom(7);
      }
    }
  }

  // creacion del archivo Kml
  crearArchivo(tipo) {
    var datos = [];

    const { equipos, puntos } = this.props;
    // guardo los puntos en una variable para generar los archivos
    equipos.forEach((equipo, i) => {
      datos.push(equipo[Object.keys(equipo)[0]]);
      datos[i].tipo = puntos[i].tipo;
    });

    if (tipo === "kml") {
      this.descargarArchivo(this.generarXml(datos), "mapa.kml");
    } else {
      this.descargarArchivo(this.generarGPX(datos), "mapa.gpx");
    }
  }

  escaparXML(cadena) {
    if (typeof cadena !== "string") {
      return "";
    }
    cadena = cadena
      .replace("&", "&amp;")
      .replace("<", "&lt;")
      .replace(">", "&gt;")
      .replace('"', "&quot;");
    return cadena;
  }

  descargarArchivo(contenidoEnBlob, nombreArchivo) {
    var reader = new FileReader();
    reader.onload = function (event) {
      var save = document.createElement("a");
      save.href = event.target.result;
      save.target = "_blank";
      save.download = nombreArchivo || "archivo.dat";
      var clicEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      });
      save.dispatchEvent(clicEvent);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    };
    reader.readAsDataURL(contenidoEnBlob);
  }

  generarGPX(datos) {
    //Genera un objeto Blob con los datos en un archivo GPX
    var contenido = [];
    var etiquetas = ["name", "cmt", "desc"];
    datos.forEach(dato => {
      contenido.push(`<wpt lat="${dato.latitud}" lon="${dato.longitud}">\n`);
      contenido.push(`<ele>0.000000</ele>\n`);
      etiquetas.forEach(etiqueta => {
        contenido.push(`<${etiqueta}>`);
        contenido.push(this.escaparXML(dato.dlgid));
        contenido.push(`</${etiqueta}>\n`);
      });
      contenido.push(`</wpt>\n`);
    });

    var texto = [];
    texto.push('<?xml version="1.0" encoding="UTF-8" ?>\n');
    texto.push(
      '<gpx version="1.0" creator="SPYMOVIL SRL" xmlns="http://www.spymovil.com/">\n'
    );
    texto = [...texto, ...contenido];
    texto.push("</gpx>");

    //No olvidemos especificar el tipo MIME correcto :)

    return new Blob(texto, {
      type: "application/xml"
    });
  }

  //Genera un objeto Blob con los datos en un archivo KML
  generarXml(datos) {
    var contenido = [];
    datos.forEach(dato => {
      // selecciono el icono correcto
      const { tx_state, ubicacion } = dato;
      var icono = "#icon-1899-0F9D58";
      switch (tx_state) {
        case "WARNING":
          icono = "#icon-1899-F57C00";
          break;
        case "ERROR":
          icono = "#icon-1899-A52714";
          break;

        default:
          break;
      }

      if (
        ubicacion.substring(0, 8) === "SPYMOVIL" ||
        ubicacion.substring(0, 3) === "OSE"
      ) {
        icono = "#icon-1899-424242";
      }

      // selecciono el tipo correcto
      var tipo = "OTRO";
      switch (dato.tipo) {
        case "bomba_auto":
          tipo = "Bomba";
          break;
        case "tanque_auto":
          tipo = "Tanque"
        case "caudalimetro":
          tipo = "Caudalímetro";
          break;
        case "reguladora":
          tipo = "Reguladora";
          break;
        case "punto_medio":
          tipo = "Punto medio";
          break;
        case "tanque":
          tipo = "Tanque";
          break;
        case "bomba":
          tipo = "Bomba";
          break;
        case "error":
          tipo = "Error";
          break;
        default:
          break;
      }

      // agrego las etiquetas con la informacion
      contenido.push("<Placemark>\n");
      contenido.push("<name>");
      contenido.push(
        this.escaparXML(dato.dlgid) + " - " + this.escaparXML(ubicacion)
      );
      contenido.push("</name>\n");
      contenido.push("<description>");
      contenido.push(this.escaparXML(tipo));
      contenido.push("</description>\n");
      contenido.push("<styleUrl>");
      contenido.push(this.escaparXML(icono));
      contenido.push("</styleUrl>\n");
      contenido.push("<LookAt>\n");
      contenido.push("<longitude>");
      contenido.push(dato.longitud);
      contenido.push("</longitude>\n");
      contenido.push("<latitude>");
      contenido.push(dato.latitud);
      contenido.push("</latitude>\n");
      contenido.push("<altitud>");
      contenido.push("0");
      contenido.push("</altitud>\n");
      contenido.push("</LookAt>\n");

      contenido.push("<Point>\n");
      contenido.push("<coordinates>\n");
      contenido.push(dato.longitud + "," + dato.latitud + ",0");
      contenido.push("</coordinates>\n");
      contenido.push("</Point>\n");

      contenido.push("</Placemark>\n");
    });

    var texto = [];
    texto.push('<?xml version="1.0" encoding="UTF-8" ?>\n');
    texto.push(
      '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">\n'
    );
    texto.push("<Document>\n");

    texto.push("<name>");
    texto.push("mapa.kml");
    texto.push("</name>\n");
    texto.push(estilosmapa());
    texto.push("<Folder>\n");

    texto.push("<name>");
    texto.push("mapa.kml");
    texto.push("</name>\n");
    texto.push("<open>1</open>\n");
    texto = [...texto, ...contenido];

    texto.push("</Folder>\n");
    texto.push("</Document>");
    texto.push("</kml>");

    //No olvidemos especificar el tipo MIME correcto :)

    return new Blob(texto, {
      type: "application/xml"
    });
  }

  render() {
    const { equipos } = this.props;
    return (
      <div className="contenedorMaps">
        <div id="mapContainer" />
        <div className="botonera">
          <div className="reestablecer" onClick={this.addPoints.bind(this)}>
            Reestablecer
          </div>
          <div
            className="reestablecer descarga"
            onClick={this.crearArchivo.bind(this, "kml")}
            style={{ display: equipos.length > 0 ? "" : "none" }}
          >
            <span>Descargar </span>KML
          </div>
          <div
            className="reestablecer descarga"
            onClick={this.crearArchivo.bind(this, "gpx")}
            style={{ display: equipos.length > 0 ? "" : "none" }}
          >
            <span>Descargar </span>GPX
          </div>
        </div>
      </div>
    );
  }
}

// guardo los datos del store en props
const mapStateToProps = state => ({
  currentPoint: state.sector.currentPoint,
  currentSector: state.sector.currentSector
});

export default connect(
  mapStateToProps,
  { changeStore }
)(Map);
